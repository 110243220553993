import React from 'react'
import { Typography } from '@strise/midgard'
import { type TableRowValueFragment } from '@graphqlTypes'
import { Trans } from '@lingui/macro'
import { CCJTable } from '@components/Sidepanel/SidepanelCards/CCJReport/CCJTable'

export const CCJs = ({ value }: { value: TableRowValueFragment & { __typename: 'TableRowValueCCJs' } }) => {
  if (value.ccjs.length === 0) {
    return (
      <Typography>
        <Trans>No CCJ details found</Trans>
      </Typography>
    )
  }

  return <CCJTable size='small' judgements={value.ccjs} truncateAfter={3} />
}
