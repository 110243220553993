import { Chip, type ChipProps } from '@strise/midgard'
import * as React from 'react'
import { ellipsis, toTitleCase } from '@strise/fika'
import { t } from '@lingui/macro'

export const IdNameChip = <
  T extends {
    id: string
    name?: string | null
  }
>({
  className,
  maxLength = 30,
  titleCase,
  value,
  ...props
}: {
  maxLength?: number,
  titleCase?: boolean,
  value: T
} & Omit<ChipProps, 'value'>) => {
  const entityName = value.name ?? t`Unknown`
  const transformedEntityName = titleCase ? toTitleCase(entityName) : entityName
  const label = ellipsis(transformedEntityName, maxLength)
  return <Chip className={className} label={label} {...props} />
}
