import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { filterNullishValues, objectKeys } from '@strise/fika'
import { SetStateFn } from '@strise/react-utils'
import { useRouter } from 'next/router'
import { decode, encode } from './string'
import { isFunction, isObject } from 'lodash-es'

const ISODateRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/

export const validateBoolean = (value: string) => {
  if (value === 'true') return true
  if (value === 'false') return false
  return null
}

export const validateString = (value: string) => value
export const validateStringOrNull = (value: string | null) => {
  if (value === 'null') return null
  return value
}

export const validateArray =
  <T extends any>(validateFn: (value: string) => T | null) =>
  (values: string) => {
    return filterNullishValues(values.split(',').map(validateFn))
  }
export const validateNullableArray =
  <T extends string>(validateFn: (value: string) => T | null) =>
  (values: string) => {
    return values.split(',').map(validateFn)
  }

export const validateDate = (value: string) => {
  if (value.match(ISODateRegex)) return new Date(value)
  return null
}
export const validateNumber = (value: string) => {
  const parsed = Number.parseInt(value, 10)
  return !Number.isNaN(parsed) ? parsed : null
}

// Types below from https://github.com/microsoft/TypeScript/issues/30611#issuecomment-1295497089
type EnumValueType = string | number | symbol
type EnumType = { [key in EnumValueType]: EnumValueType }

export const validateEnum = <T,>(enumToValidate: EnumType & T) => {
  const enumValues = Object.values(enumToValidate)
  return (value: string | number): T[keyof T] | null => {
    if (enumValues.includes(value)) return value as T[keyof T]
    return null
  }
}

export const validateObject = <T extends object>(value: string): T | null => {
  try {
    return JSON.parse(decode(value))
  } catch {
    return null
  }
}

export const serializeObject = (value: object) => {
  return encode(JSON.stringify(value))
}

type Object = Record<string, any>

export const flattenPathToObject = (
  value: string,
  parentObject: Object,
  validations: Object,
  path: string[]
): unknown => {
  const pathHead = path.shift()
  if (!pathHead) return parentObject
  if (!validations[pathHead]) return parentObject

  if (isFunction(validations[pathHead]) && validations[pathHead](value) === null) return parentObject

  if (!path.length) {
    return {
      ...parentObject,
      [pathHead]: validations[pathHead](value)
    }
  } else {
    if (!validations[pathHead]) return parentObject
    return {
      ...parentObject,
      [pathHead]: flattenPathToObject(value, parentObject[pathHead] ?? {}, validations[pathHead], path)
    }
  }
}

export const objectToDotSeparatedQueryParam = <T extends object>(
  initialObject: T,
  initialValidations: Object,
  initialSerializations: Object = {},
  search: string = ''
): URLSearchParams => {
  const params = new URLSearchParams(search)
  const rootKeys = new Set(objectKeys(initialObject))

  const toRemove: string[] = []
  params.forEach((_, key) => {
    const [head] = key.split('.')
    if (rootKeys.has(head as keyof T)) toRemove.push(key)
  })
  toRemove.forEach((key) => params.delete(key))

  const recursivelyAddParam = (
    childObject: Record<string, any> | null,
    validations: Object,
    serializations?: Object,
    path: string = ''
  ) => {
    if (!childObject) return

    Object.keys(childObject).forEach((key) => {
      const value = childObject[key]
      const currentPath = !path.length ? key : path + '.' + key

      // It should be impossible to add variable to the query param if you're missing a validation function
      if (validations && !isFunction(validations) && !validations[key]) {
        // Simply skip __typename
        if (key !== '__typename') throw new Error(`Missing validation function for "${key}" of "${currentPath}"`)
        return
      }

      const serializationFn = serializations && serializations[key]

      if (!isFunction(serializationFn) && isObject(value) && !Array.isArray(value) && !(value instanceof Date)) {
        recursivelyAddParam(value, validations[key], serializations && serializations[key], currentPath)
      } else if (Array.isArray(value) && !value.length) {
        params.delete(currentPath)
      } else {
        // Undefined should not be added to query params
        if (value === undefined) return

        const valueOrString = value instanceof Date ? value.toISOString() : value
        const serializedValue = serializationFn ? serializationFn(valueOrString) : valueOrString

        // Undefined should not be added to query params
        if (serializedValue === undefined) return

        params.set(currentPath, serializedValue)
      }
    })
  }
  recursivelyAddParam(initialObject, initialValidations ?? {}, initialSerializations)
  params.sort()
  return params
}

export type RecursiveQueryParamsValidation<T> = {
  [P in Exclude<keyof T, '__typename'>]: NonNullable<T[P]> extends Array<infer U>
    ? (values: string) => U[] | null
    : NonNullable<T[P]> extends object
      ? RecursiveQueryParamsValidation<NonNullable<T[P]>> | ((value: string) => NonNullable<T[P]> | null)
      : (value: string) => T[P] | null
}

export const useRecursiveQueryParamsReactRouter = <T extends object>(
  initialValue: T,
  validations: RecursiveQueryParamsValidation<T>,
  serializations?: object,
  skip?: boolean
): [T, SetStateFn<T>] => {
  const navigate = useNavigate()
  const location = useLocation()

  const { currentValue, setValue, setState, urlStateMismatch } = useQueryParamsState<T>(
    initialValue,
    validations,
    location.search,
    serializations,
    skip
  )

  useEffect(() => {
    if (skip) return

    const { mismatch, search } = urlStateMismatch()
    if (mismatch) {
      navigate({ ...location, search }, { replace: true })
    }
  }, [currentValue])

  useEffect(() => {
    if (skip) return

    const { mismatch } = urlStateMismatch()
    if (mismatch) {
      setValue(setState)
    }
  }, [location.search])

  return [currentValue, setValue]
}

export const useRecursiveQueryParamsNextJs = <T extends object>(
  initialValue: T,
  validations: RecursiveQueryParamsValidation<T>,
  serializations?: object,
  skip?: boolean
): [T, SetStateFn<T>] => {
  const router = useRouter()

  const queryString = router.asPath.replace(/^[^?]*/, '')

  const { currentValue, setValue, setState, urlStateMismatch } = useQueryParamsState<T>(
    initialValue,
    validations,
    queryString,
    serializations,
    skip
  )

  useEffect(() => {
    if (skip) return

    const { mismatch, search } = urlStateMismatch()
    if (mismatch) {
      router.replace(router.pathname + '?' + search)
    }
  }, [currentValue])

  useEffect(() => {
    if (skip) return

    const { mismatch } = urlStateMismatch()
    if (mismatch) {
      setValue(setState)
    }
  }, [location.search])

  return [currentValue, setValue]
}

const useQueryParamsState = <T extends object>(
  initialValue: T,
  validations: RecursiveQueryParamsValidation<T>,
  queryString: string,
  serializations?: object,
  skip?: boolean
) => {
  const setState = () => {
    if (skip) return {} as T

    const rootKeys = objectKeys(validations)
    const params = new URLSearchParams(queryString)
    let nextValue = { ...initialValue }
    params.forEach((value: string, key: string) => {
      const currentRootKey = rootKeys.find((rootKey) => key.split('.')[0] === rootKey)
      if (currentRootKey) {
        const path = key.split('.')
        nextValue = flattenPathToObject(value, nextValue, validations, path) as T
      }
    })
    return nextValue
  }

  const [currentValue, setValue] = useState<T>(setState())

  const urlStateMismatch = () => {
    const search = objectToDotSeparatedQueryParam<T>(currentValue, validations, serializations, queryString).toString()

    return {
      mismatch: queryString !== '?' + search,
      search
    }
  }

  return { currentValue, setValue, setState, urlStateMismatch }
}
