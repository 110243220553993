import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagGlobalPerson = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M8.667 10.667a2 2 0 0 1 2-2h10.666a2 2 0 0 1 2 2v10.666a2 2 0 0 1-2 2H10.667a2 2 0 0 1-2-2V10.667Z'
    />
    <path fill='#FFDE2E' d='M13.333 8.667h5.334v14.666h-5.334V8.667Z' />
    <path fill='#E50726' d='M18.667 8.667h2.666a2 2 0 0 1 2 2v10.666a2 2 0 0 1-2 2h-2.666V8.667Z' />
    <path
      fill='currentColor'
      d='M8 13.29c-2.891.86-5 3.539-5 6.71v3H1v-3a9.002 9.002 0 0 1 5.357-8.232A6 6 0 1 1 15.917 8h-2.043A4 4 0 1 0 8 10.465v2.825Z'
    />
  </svg>
))
FlagGlobalPerson.displayName = 'FlagGlobalPerson'
