import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { getBrowserGlobals } from '@strise/system'
/**
 * A custom React hook that fetches and returns the current application version.
 *
 * This hook performs the following:
 * 1. Fetches the version from a URL specified by the VERSION_URL environment variable.
 * 2. Periodically checks for version updates based on the VERSION_CHECK_TIMER environment variable.
 * 3. Updates the version state if a new version is detected.
 * 4. Sets the version tag in Sentry.
 * @returns {string | undefined} The current application version, or undefined if not yet fetched.
 */
export const useAppVersion = (): string | undefined => {
  const [version, setVersion] = useState<string | undefined>()
  const versionUrl = getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.VERSION_URL

  useEffect(() => {
    if (!versionUrl) return

    /**
     * Fetches the current version from the specified URL.
     * Updates the version state if a new version is detected.
     */
    const fetchVersion = async () => {
      try {
        const response = await fetch(versionUrl)
        const data: string = await response.text()

        if (data !== version) {
          setVersion(data)
          const scope = Sentry.getCurrentScope()
          scope.setTag('version', data)
        }
      } catch (error) {
        console.error('Failed to fetch version:', error)
      }
    }

    fetchVersion()
    // Set up periodic version checks
    const timerId = setInterval(
      fetchVersion,
      Number.parseInt(getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.VERSION_CHECK_TIMER || '') || 120_000
    )

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerId)
    }
  }, [versionUrl, version])

  return version
}
