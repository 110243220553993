import * as React from 'react'
import { cn } from '../utils/className'
import { Typography } from './Typography'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  animationHover?: boolean
  to?: string
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ animationHover = false, className, to, ...props }, ref) => {
  const WrapperComponent = to ? 'a' : 'div'
  return (
    <WrapperComponent href={to} className='no-underline' target='_blank'>
      <div
        ref={ref}
        className={cn(
          'flex flex-col justify-between rounded-xl border border-gray-10 bg-white active:border-blue-90',
          animationHover && 'transition delay-100 duration-200 ease-in-out hover:border-blue-80',
          className
        )}
        {...props}
      />
    </WrapperComponent>
  )
})
Card.displayName = 'Card'

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex flex-col space-y-1.5 px-4 pt-6', className)} {...props} />
  )
)
CardHeader.displayName = 'CardHeader'

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('text-text-primary', className)} {...props} />
)
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <Typography ref={ref} variant='body1' className={cn('text-gray-60', className)} {...props} />
  )
)
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex flex-col justify-between rounded-b-xl px-4 py-6', className)} {...props} />
  )
)
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex h-12 items-center rounded-b-xl border-none p-4 transition delay-100 duration-200 ease-in-out',
        className
      )}
      {...props}
    />
  )
)
CardFooter.displayName = 'CardFooter'

const CardActionArea = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex items-center justify-between rounded-xl px-4 text-text-primary', className)}
      {...props}
    ></div>
  )
)
CardActionArea.displayName = 'CardActionArea'

const CardAction = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('flex items-center', className)} {...props} />
)
CardAction.displayName = 'CardAction'

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent, CardActionArea, CardAction }
