import { Button, IconArrowRight, IconChevronLeftSmall, Link, Logo, LogoStripe } from '@strise/midgard'
import * as React from 'react'
import { AuthError, codeToError, useLogin } from '@strise/europa'
import { getBrowserGlobals } from '@strise/system'

export const LoginView = () => {
  const login = useLogin()
  const params = new URLSearchParams(getBrowserGlobals()?.window.location.search)
  const errorCode = params.get('error')

  React.useEffect(() => {
    if (!errorCode) {
      login()
    }
  }, [])

  if (!errorCode) return null

  return (
    <>
      <Link href='https://strise.ai' variant='subtitle2' className='absolute left-40 top-40'>
        <IconChevronLeftSmall className='mr-2' /> Back to home page
      </Link>

      <div className='flex h-screen flex-col items-center justify-center text-center'>
        <div className='flex w-full flex-row items-center'>
          <div className='mr-[10%] flex w-[45%] items-center'>
            <LogoStripe className='mr-4 h-[6px] grow basis-[100px] bg-[#2d2a2b]' />
            <Logo className='w-32 fill-[#2d2a2b]' />
          </div>
          <Button
            onClick={login}
            variant='contained'
            palette='secondary'
            className='w-52 justify-between'
            endIcon={<IconArrowRight />}
            data-track=''
          >
            Log in
          </Button>
        </div>

        {errorCode && (
          <div className='py-10'>{codeToError[errorCode as AuthError] ?? codeToError[AuthError.Generic]}</div>
        )}
      </div>
    </>
  )
}
