import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagDe = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagDE_svg__a)'>
      <path fill='#FFDA44' d='m1.098 15.824 11.03-1.095 10.97 1.095V23h-22v-7.176Z' />
      <path fill='#D80027' d='m1.098 8.176 10.957-.989 11.043.989v7.648h-22V8.176Z' />
      <path fill='#333' d='M1.098 1h22v7.176h-22V1Z' />
    </g>
    <defs>
      <clipPath id='FlagDE_svg__a'>
        <rect width={22} height={22} x={1.098} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagDe.displayName = 'FlagDe'
