import { Button, cn, type ComboboxItem, IconButton, Typography, IconCross, IconFilter } from '@strise/midgard'
import { AssigneeSearchSelect } from '@components/Assignee/SelectCompanyAssignee'
import { useReassignUsersToCompanies } from '@components/Assignee/assigneeHooks'
import { useSelectCompaniesStatus } from '@components/CompanyStatus/useSelectCompaniesStatus'
import { EntitySearchInputMultiple } from '@components/Entity/EntitySearchInput'
import { ActiveFilters } from '@components/Filter/ActiveFilters'
import { AssigneeChip, EnumChip } from '@components/Filter/FilterChips'
import { filterArray, type FilterSpec } from '@components/Filter/FilterHOC'
import { SubHeaderStringFilter } from '@components/Filter/SubHeaderStringFilter'
import { useAssigneeOptions, useCompanyStatusOptions, useTagOptions } from '@components/Filter/filterHooks'
import { ContentViewContext } from '@components/Layout/ContentViewContext'
import { useEntityMetaQuery } from '@graphqlOperations'
import {
  type CompanyUserConnectionEdgeFragment,
  type UserSettingsFragment,
  type SimpleUserFragment,
  type BaseEntityLikeFragment
} from '@graphqlTypes'
import { defineMessage, t, Trans } from '@lingui/macro'
import { CompanyStatusDot, setChildState, useContext, IdNameChip } from '@strise/europa'
import { ConfirmDialog, ModalContent, Table, TableCell, TableHeadCell, TableRow } from '@strise/system'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { ApplicationSearchReturnType, CompanyStatus, TrackedActivityKind } from '@strise/types'
import { batchTrack, toMixpanelEvent } from '@utils/tracking'
import { sum } from 'lodash-es'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { reviewableEntityLocationFilters } from '@components/Search/searchUtils'
import { TestIDs } from '@utils/testIDs'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { companyStatusTitles } from '@strise/europa/src/i18n'

const AddToReviewCompanyRow = ({
  assignees,
  companyId,
  setAssignees,
  setSelectedCompanies
}: {
  assignees: CompanyUserConnectionEdgeFragment[]
  companyId: string
  setAssignees: SetStateFn<CompanyUserConnectionEdgeFragment[]>
  setSelectedCompanies: SetStateFn<BaseEntityLikeFragment[]>
}) => {
  const { data, loading } = useEntityMetaQuery({ variables: { id: companyId } })

  const handleDelete = () => {
    setSelectedCompanies((prevSelectedCompanies) => prevSelectedCompanies.filter((company) => company.id !== companyId))
  }

  const handleAssigneeChange = (items: Array<ComboboxItem<SimpleUserFragment>>) => {
    const selectedTeamUsers = items.map((item) => ({
      node: item.value,
      __typename: 'CompanyUserConnectionEdge' as const
    }))
    setAssignees(selectedTeamUsers)
  }

  const entity = data?.entity

  return (
    <TableRow>
      <TableCell>{loading ? '' : entity?.name || t`Unknown name`}</TableCell>
      <TableCell width={350}>
        <AssigneeSearchSelect assignees={assignees} loading={loading} onChange={handleAssigneeChange} />
      </TableCell>
      <TableCell textAlign='right'>
        <IconButton
          className='rounded-full p-[2px]'
          variant='ghost'
          onClick={handleDelete}
          data-track='Review / Add to Review / Remove company'
        >
          <IconCross size='md' />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const AddToReview = ({ className, ...props }: DivProps) => {
  const [dialogOpen, toggleDialogOpen, setDialogOpen] = useToggle(false)
  const [selectedCompanies, setSelectedCompanies] = React.useState<BaseEntityLikeFragment[]>([])
  const [selectedCompaniesAssignees, setSelectedCompaniesAssignees] = React.useState<
    Record<string, CompanyUserConnectionEdgeFragment[]>
  >({})
  const { loading: reassignLoading, reassignUser } = useReassignUsersToCompanies()
  const { loading: statusLoading, updateCompanyStatus } = useSelectCompaniesStatus(
    selectedCompanies.map((company) => ({ id: company.id, status: null }))
  )

  const handleClose = () => {
    setSelectedCompanies([])
    setSelectedCompaniesAssignees({})
    setDialogOpen(false)
  }

  const updateAssignees = async () => {
    await Promise.all(
      selectedCompanies.map(async (company) => {
        const companyAssignees = selectedCompaniesAssignees[company.id]?.map((a) => a.node.id)

        if (!companyAssignees) return null

        await reassignUser([company.id], companyAssignees)
      })
    )
  }

  const handleConfirm = async () => {
    await Promise.all([updateCompanyStatus(CompanyStatus.InQualification, null), updateAssignees()])

    batchTrack(
      selectedCompanies.map((company) =>
        toMixpanelEvent(TrackedActivityKind.StriseCompanyStatusChanged, {
          companyId: company.id,
          status: CompanyStatus.InQualification,
          context: 'Review'
        })
      )
    )

    handleClose()
  }

  const loading = reassignLoading || statusLoading

  return (
    <div className={className} {...props}>
      <Button
        variant='contained'
        palette='secondary'
        className='h-full w-48'
        onClick={toggleDialogOpen}
        data-track='Review / Add to Review / Open dialog'
        data-id={TestIDs.Review.AddToReview.button}
      >
        <Trans>Add to Review</Trans>
      </Button>
      {dialogOpen && (
        <ConfirmDialog
          isOpen
          confirmText={t`Add`}
          cancelText={t`Cancel`}
          onConfirm={handleConfirm}
          onCancel={handleClose}
          loading={loading}
          disabled={!selectedCompanies.length}
          confirmButtonProps={{
            palette: 'secondary',
            'data-id': TestIDs.Review.AddToReview.confirm,
            'data-track': 'Review / Add to Review / Confirm'
          }}
          cancelButtonProps={{
            'data-id': 'add-to-review-confirm-dialog-cancel-button',
            'data-track': 'Review / Add to Review / Cancel'
          }}
          contentMaxWidth={888}
          title={t`Add to Review`}
        >
          <ModalContent pb={20} data-id={TestIDs.Review.AddToReview.dialog}>
            <EntitySearchInputMultiple
              variant='outlined'
              placeholder={t`Start searching by company name or ID`}
              entityKindFilter={ApplicationSearchReturnType.Company}
              entityLocationFilters={reviewableEntityLocationFilters}
              selectedEntities={selectedCompanies}
              onChange={setSelectedCompanies}
              dataTrack='Review / Add to Review / Company select'
              autoFocus
              itemsWrapperProps={{
                'data-id': 'add-to-review-select-company-options-container'
              }}
            />

            {!!selectedCompanies.length && (
              <>
                <Table my={5}>
                  <thead>
                    <TableRow>
                      <TableHeadCell>
                        <Trans>Company</Trans>
                      </TableHeadCell>
                      <TableHeadCell>
                        <Trans>Assign to</Trans>
                      </TableHeadCell>
                      <TableHeadCell />
                    </TableRow>
                  </thead>
                  <tbody>
                    {selectedCompanies.map((company) => {
                      const assignees = selectedCompaniesAssignees[company.id] ?? []

                      return (
                        <AddToReviewCompanyRow
                          key={company.id}
                          companyId={company.id}
                          setSelectedCompanies={setSelectedCompanies}
                          assignees={assignees}
                          setAssignees={setChildState(setSelectedCompaniesAssignees, company.id)}
                        />
                      )
                    })}
                  </tbody>
                </Table>
                <div className='flex items-center'>
                  <Typography>
                    <Trans>Company status will change to</Trans>
                  </Typography>
                  <CompanyStatusDot status={CompanyStatus.InQualification} />
                  <Typography className='font-medium'>
                    <Trans>In qualification</Trans>
                  </Typography>
                </div>
              </>
            )}
          </ModalContent>
        </ConfirmDialog>
      )}
    </div>
  )
}

const filters: Record<string, FilterSpec<UserSettingsFragment>> = {
  statuses: filterArray({
    path: 'review.statuses',
    ChipComponent: EnumChip,
    titleMap: companyStatusTitles,
    useOptions: useCompanyStatusOptions,
    dataTrack: 'Review / Filter / Company Status / Changed',
    editLabel: defineMessage({ message: 'Status' })
  }),
  assignees: filterArray({
    path: 'review.assignees',
    ChipComponent: AssigneeChip,
    useOptions: useAssigneeOptions,
    dataTrack: 'Review / Filter / Assignees / Changed',
    editLabel: defineMessage({ message: 'Assignees' }),
    enableSearch: true
  }),
  tags: filterArray({
    path: 'review.tags',
    ChipComponent: IdNameChip,
    useOptions: useTagOptions,
    dataTrack: 'Review / Filter / Tags / Changed',
    editLabel: defineMessage({ message: 'Tags' }),
    enableSearch: true
  })
}

interface ReviewFilterProps extends DivProps {
  companyNameFilter: string
  filterOpen: boolean
  setCompanyNameFilter: SetStateFn<string>
  toggleFilterOpen: () => void
}

export const ReviewFilter = React.forwardRef<HTMLDivElement, ReviewFilterProps>(
  ({ companyNameFilter, filterOpen, setCompanyNameFilter, toggleFilterOpen }, ref) => {
    const { isSmallScreen } = useContext(ContentViewContext)
    const { saveSettings, settings } = useContext(CurrentUserSettingsContext)

    const filteredFilters = Object.values(filters).filter((values) => !!values.totalSelected(settings))
    const totalActiveFiltersCount = sum(filteredFilters.map((filter) => filter.totalSelected(settings)))

    return (
      <div
        className={cn(
          'sticky top-0 z-[11] flex min-h-[theme(height.sub-header)] items-stretch border-x-0 border-b border-solid',
          filterOpen
            ? 'border-secondary-shade-30 bg-secondary-shade-90 text-secondary-contrastText'
            : 'border-divider bg-background-default text-text-primary'
        )}
        ref={ref}
      >
        <div className='flex'>
          <Button
            className={`min-h-full shrink self-start border-tertiary-main px-3 ${
              filterOpen
                ? 'mr-0 border-none bg-secondary-shade-70 text-white hover:bg-secondary-shade-70'
                : 'mr-4 border-r bg-secondary-shade-5 text-secondary-main hover:bg-secondary-shade-5'
            }`}
            variant='contained'
            palette='secondary'
            onClick={toggleFilterOpen}
            startIcon={
              filterOpen ? <IconCross className='mr-2 text-secondary-shade-90' /> : <IconFilter className='mr-2' />
            }
            data-track={filterOpen ? 'Review / Filter / Close' : 'Review / Filter / Open'}
          >
            <Trans>Filter</Trans>
          </Button>
          {isSmallScreen && !filterOpen && !!totalActiveFiltersCount && (
            <div className='absolute m-1 size-5 rounded-full bg-primary-main text-center text-background-paper'>
              <Typography variant='body2' className='leading-5'>
                {totalActiveFiltersCount}
              </Typography>
            </div>
          )}
        </div>

        {!filterOpen && !isSmallScreen && (
          <div className='flex flex-wrap items-center'>
            <ActiveFilters
              filters={filteredFilters}
              value={settings}
              setValue={saveSettings}
              toggleEdit={toggleFilterOpen}
            />
          </div>
        )}

        {filterOpen && (
          <div className='flex h-full grow flex-wrap items-center'>
            {Object.values(filters).map(({ EditComponent }, index) => (
              <div className='max-w-[250px] grow' key={index}>
                <EditComponent value={settings} setValue={saveSettings} />
              </div>
            ))}
          </div>
        )}

        {!filterOpen && (
          <div className='ml-auto flex'>
            <SubHeaderStringFilter
              filter={companyNameFilter}
              setFilter={setCompanyNameFilter}
              placeholder={t`Filter companies in Review`}
              dataTrackPrefix='Review'
              inputClassName='w-[400px]'
            />

            <AddToReview />
          </div>
        )}
      </div>
    )
  }
)
