import { Button, IconPlusSmall, Typography } from '@strise/midgard'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { Event } from '@components/Events/Event'
import { OwnerDetail } from '@components/OwnerDetail'
import { SanctionsEntityContent } from '@components/PepAndSanctions/SanctionsEntityContent'
import { Register } from '@components/Register'
import { CreditScore } from '@utils/apiTable/CreditScore'
import { Flag } from '@utils/apiTable/Flag'
import { PepsWithEntity } from '@utils/apiTable/PepsWithEntity'
import { Role } from '@utils/apiTable/Role'
import { RoleWithDetailedEntity } from '@utils/apiTable/RoleWithDetailedEntity'
import { RoleWithEntity } from '@utils/apiTable/RoleWithEntity'
import React from 'react'
import { type TableLabelContentProps, type TableValueContentProps } from './apiTableUtils'
import { CCJs } from './CCJs'
import { Trans } from '@lingui/macro'
import { RoleWithEntityForPerson } from '@utils/apiTable/RoleWithEntityForPerson'

export const TableLabelContent = ({ editMode, handleEdit, label }: TableLabelContentProps): React.ReactNode | null => {
  if (label.__typename === 'TableLabelEntity') {
    return (
      <div className='flex items-center'>
        <EntityLink entity={label.entity} transparent={!label.isActive} />
        {label.description && <Typography className='ml-1'>({label.description})</Typography>}
      </div>
    )
  }

  if (label.__typename === 'TableLabelEntityRole') {
    return (
      <div className='grid items-center'>
        <EntityLink entity={label.entity} transparent={!label.isActive} />
        {editMode && handleEdit && (
          <Button
            data-track='Sidepanel / Roles / Open Add Role'
            palette='primary'
            className='w-fit pl-0'
            startIcon={<IconPlusSmall />}
            onClick={() => {
              handleEdit(label.entity, {
                existingRole: true,
                type: 'EditRole'
              })
            }}
          >
            <Typography>
              <Trans>Add</Trans>
            </Typography>
          </Button>
        )}
      </div>
    )
  }

  if (label.__typename === 'TableLabelRole') {
    return (
      <div className='flex items-center gap-2'>
        <Typography className='mr-1'>{label.name}</Typography>
        {editMode && handleEdit && (
          <Button
            data-track='Sidepanel / Roles / Open Add Role'
            palette='primary'
            className='h-[24px] w-fit p-0'
            onClick={() => {
              handleEdit(null, {
                existingRole: false,
                type: 'EditRole',
                roleTitleId: label.roleTitleId
              })
            }}
          >
            <IconPlusSmall />
          </Button>
        )}
      </div>
    )
  }

  // Keeping this in case we introduce more types l8r
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (label.__typename === 'TableLabelString') {
    return <span className={label.isActive ? 'text-inherit' : 'text-text-secondary'}>{label.content}</span>
  }

  return null
}

export const TableValueContent = ({
  editMode,
  entity,
  handleEdit,
  reviewState,
  sectionEntity,
  setReviewState,
  value
}: TableValueContentProps) => {
  switch (value.__typename) {
    case 'TableRowValueString': {
      return value.content
    }
    case 'TableRowValueLabeledString': {
      return value.content
    }
    case 'TableRowValueRelatedEntity': {
      return <EntityLink entity={value.entity} />
    }
    case 'TableRowValueOwnership': {
      return <OwnerDetail owner={value} />
    }
    case 'TableRowValueRoleWithEntity': {
      return <RoleWithEntity value={value} handleEdit={handleEdit} editMode={editMode} />
    }
    case 'TableRowValueRole': {
      return <Role value={value} handleEdit={handleEdit} editMode={editMode} />
    }
    case 'TableRowValueFlag': {
      return <Flag entityId={entity.id} value={value} />
    }
    case 'TableRowValueRegisterV2': {
      return <Register entityId={entity.id} register={value.register} />
    }
    case 'TableRowValueRoleForPerson': {
      return <RoleWithEntityForPerson value={value} handleEdit={handleEdit} editMode={editMode} />
    }
    case 'TableRowValueEvent': {
      return (
        <Event
          event={value.event}
          entityId={sectionEntity?.id || entity.id}
          reviewState={reviewState}
          setReviewState={setReviewState}
          isReview
          hideImage
          hidePublisherImage
          topics={value.topics}
          hideSummary
        />
      )
    }
    case 'TableRowValueCreditReport': {
      return <CreditScore entity={entity} value={value} />
    }
    case 'TableRowValueCCJs': {
      return <CCJs value={value} />
    }
    case 'TableRowValuePepsWithEntity': {
      return <PepsWithEntity value={value} />
    }
    case 'TableRowValueSanctionsWithEntity': {
      return (
        <SanctionsEntityContent
          entity={value.maybeEntity}
          entityName={value.entityName}
          sanctionInfo={value.sanctions}
        />
      )
    }
    case 'TableRowValueRoleWithDetailedEntity': {
      return <RoleWithDetailedEntity value={value} />
    }
    case 'TableRowValueSanctions': {
      return <SanctionsEntityContent entity={entity} entityName={entity.name} sanctionInfo={value.sanctions} noLink />
    }
    default: {
      return null
    }
  }
}
