import { IconSanction, Link, Tooltip, Typography } from '@strise/midgard'
import { type SanctionInfoFragment } from '@graphqlTypes'
import { t } from '@lingui/macro'
import React from 'react'
import { type DivProps } from '@strise/react-utils'
import { useEntitySanctionInfo } from '@utils/sanctions'

const extractSanctionedTooltipContent = (sanctionInfo: SanctionInfoFragment | null | undefined) => {
  if (!sanctionInfo) return t`Sanctions not checked`
  if (!sanctionInfo.sanctionSources.length) return t`Not sanctioned`

  return t`Is sanctioned`
}

const extractTooltipContent = (sanctionInfo: SanctionInfoFragment | null | undefined) => {
  const title = extractSanctionedTooltipContent(sanctionInfo)

  return <Typography variant='body2'>{title}</Typography>
}

export const SanctionIcon = ({
  className,
  entityId,
  sanctionsLink,
  ...props
}: { entityId: string; sanctionsLink?: boolean } & DivProps) => {
  const { sanctionInfo } = useEntitySanctionInfo(entityId)

  const tooltipContent = extractTooltipContent(sanctionInfo)

  const Icon = sanctionInfo?.sanctionSources.length ? IconSanction : null
  const iconClassName = 'h-4 w-4 text-semantic-danger-main rounded-full'

  if (!Icon) return null

  return (
    <Tooltip content={tooltipContent}>
      <div className={className} {...props}>
        {sanctionsLink ? (
          <Link href='#sanctions'>
            <Icon className={iconClassName} />
          </Link>
        ) : (
          <Icon className={iconClassName} />
        )}
      </div>
    </Tooltip>
  )
}
