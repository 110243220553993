import React from 'react'
import { IconSanction, Link, Typography, cn } from '@strise/midgard'
import { type RiskSummaryRisk, RiskSummaryRiskLevel, RiskSummaryRiskKind } from '@strise/types'

const riskLevelBackground: Partial<Record<RiskSummaryRiskLevel, string>> = {
  [RiskSummaryRiskLevel.High]: 'bg-semantic-danger-shade-5 hover:bg-semantic-danger-shade-10',
  [RiskSummaryRiskLevel.Low]: 'bg-semantic-notice-shade-5 hover:bg-semantic-notice-shade-10',
  [RiskSummaryRiskLevel.None]: 'bg-background-default'
}

const kindLink: Record<RiskSummaryRiskKind, string> = {
  [RiskSummaryRiskKind.Pep]: 'ownership',
  [RiskSummaryRiskKind.Ubo]: 'ownership',
  [RiskSummaryRiskKind.Industry]: 'ownership',
  [RiskSummaryRiskKind.Sanction]: 'sanctions',
  [RiskSummaryRiskKind.Jurisdiction]: 'ownership'
}

export const RiskCard: React.FC<{ risk: RiskSummaryRisk }> = ({ risk }) => {
  const { kind, label, level, reason } = risk
  const background = riskLevelBackground[level]
  const icon = level === RiskSummaryRiskLevel.High && (
    <IconSanction className='shrink-0 self-center text-semantic-danger-main' size='xl' />
  )
  const textHover = { 'group-hover:underline': level !== RiskSummaryRiskLevel.None }
  const cursorHover = { 'cursor-pointer': level !== RiskSummaryRiskLevel.None }
  const linkHref = level === RiskSummaryRiskLevel.None ? undefined : kindLink[kind]
  const Container = linkHref ? Link : 'div'

  return (
    <Container
      href={`#${linkHref}`}
      className={cn('group flex justify-stretch gap-3 rounded p-4', background, cursorHover)}
    >
      <div className='flex grow flex-col justify-start gap-2'>
        <Typography className='text-gray-40' variant='aLabelSmall'>
          {label}
        </Typography>
        <Typography className={cn(textHover)} variant='aLabel'>
          {reason}
        </Typography>
      </div>
      {icon}
    </Container>
  )
}
