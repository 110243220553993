import { Button, cn, Typography } from '@strise/midgard'
import { type MutationUpdaterFn } from '@apollo/client/index.js'
import { updateCompanyStatusCache } from '@components/CompanyStatus/companyStatusUtils'
import { ExportOwnerships } from '@components/Ownerships/ExportOwnerships'
import { type ReviewCompany } from '@components/Review/ReviewCompanyCard'
import { ReviewTable } from '@components/Review/ReviewTable'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useCreateReviewMutation } from '@graphqlOperations'
import { type CreateReviewMutation, type TeamReviewSettingsV2Fragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { getElementAsEncodedBase64, setChildState, toast } from '@strise/europa'
import { objectEntries } from '@strise/fika'
import { ConfirmDialog } from '@strise/system'
import { type SetStateFn } from '@strise/react-utils'
import {
  AuditRisk,
  CompanyStatus,
  type ReviewAmsEventFeedbackInput,
  ReviewSettingKind,
  ReviewStatus,
  TableRowKind,
  TrackedActivityKind
} from '@strise/types'
import { useTransformedTables } from '@utils/apiTable/apiTableValueBaseUtils'
import {
  type CheckedState,
  defaultCheckedState,
  extractEntityAmsHits,
  ReviewCardContentView,
  type ReviewState
} from '@utils/reviewUtils'
import { track } from '@utils/tracking'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { ReviewAuditRiskSection } from './ReviewAuditRiskSection'
import { ReviewCompanyStatusSection } from './ReviewCompanyStatusSection'
import { ReviewSelectAllCheckboxSection } from './ReviewSelectAllCheckboxSection'
import { TestIDs } from '@utils/testIDs'
import { partition } from 'lodash-es'
import { ConflictAlert } from '../Conflicts/ConflictAlert'
import { ConflictAlertType } from '@components/Conflicts/ConflictAlertType'
import { useUnresolvedConflicts } from '@components/Conflicts/useHasUnresolvedConflicts'
import { ReviewSummarySection } from '@components/Review/ReviewSummarySection'

interface ReviewCompanyCardContentProps {
  cardAnchorRef: React.RefObject<HTMLDivElement>
  company: ReviewCompany
  resetReviewState: (companyId: string) => void
  reviewState: ReviewState | undefined
  setCardView: SetStateFn<ReviewCardContentView>
  setIsReviewFinished: SetStateFn<boolean>
  setReviewState: SetStateFn<ReviewState>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

export const ReviewCompanyCardContent = ({
  cardAnchorRef,
  company,
  resetReviewState,
  reviewState,
  setCardView,
  setIsReviewFinished,
  setReviewState,
  teamReviewSettings
}: ReviewCompanyCardContentProps) => {
  const [createReviewLoading, setCreateReviewLoading] = React.useState(false)
  const features = useCurrentUserFeatures()

  const checkedState: CheckedState = reviewState?.checkedV2 ?? defaultCheckedState
  const comment = reviewState?.comment ?? ''
  const inlineComments = reviewState?.inlineComments ?? {}
  const setChecked = setChildState(setReviewState, 'checkedV2')
  const setComment = setChildState(setReviewState, 'comment')
  const setInlineComments = setChildState(setReviewState, 'inlineComments')

  const existingReviews = company.reviews.edges
  const existingReview = existingReviews.length ? existingReviews[0]?.node : null

  const [companyStatus, setCompanyStatus] = React.useState<CompanyStatus | null>(company.statusV2?.status || null)
  const [errors, setErrors] = React.useState(false)
  const [submittedWithErrors, setSubmittedWithErrors] = React.useState(false)

  const [auditRisk, setAuditRisk] = React.useState(existingReview?.risk || AuditRisk.None)

  const ownershipsRef = React.useRef<HTMLDivElement | null>(null)
  const reviewCardRef = React.useRef<HTMLDivElement | null>(null)

  const [resetDialogOpen, toggleResetDialogOpen, setResetDialogOpen] = useToggle(false)

  const hasLoadingSections = company.reviewTables.some((table) => 'loadingRowCount' in table)

  const { conflictsLoading, hasConflicts } = useUnresolvedConflicts(company)

  const handleCompleted = () => {
    toast.success(features.EMAIL_REVIEW ? t`Review created. The report is sent to your email.` : t`Review created`)
    resetReviewState(company.id)
    setCardView(ReviewCardContentView.AFTER_REVIEW)
    // Since the opened card is long, we scroll to top of card after collapsing the card
    // to avoid the user ending up in the middle of the page
    cardAnchorRef.current?.scrollIntoView(true)
  }

  const handleReset = () => {
    setChecked(defaultCheckedState)
    setComment('')
    setResetDialogOpen(false)
    setCardView(ReviewCardContentView.BEFORE_REVIEW)
    resetReviewState(company.id)
  }

  const handleClose = () => {
    setResetDialogOpen(false)
  }

  const updateCache: MutationUpdaterFn<CreateReviewMutation> = (cache, { data: mutationData }) => {
    const { id, statusV2 } = mutationData?.createReviewV2.company ?? {}
    if (!id || statusV2?.status === undefined) return
    cache.modify(
      updateCompanyStatusCache({
        __typename: 'SimpleCompany',
        id,
        statusV2: { status: statusV2.status }
      })
    )
  }

  const [create, { loading: mutationLoading }] = useCreateReviewMutation({
    onCompleted: handleCompleted,
    update: updateCache
  })

  React.useEffect(() => {
    if (mutationLoading) return
    setCreateReviewLoading(false)
  }, [mutationLoading])

  React.useEffect(() => {
    if (submittedWithErrors) validateHasErrors()
  }, [checkedState, companyStatus])

  // TODO:
  //  We NEED to refactor the review state to use React Context instead of passing it down through props.
  //  Doing it like this now as it was not trivial to refactor it quickly, and without risking too many side effects
  const tables = useTransformedTables(company, company.reviewTables, reviewState, setReviewState)

  const checkedCount =
    Object.values(checkedState.checkedMap).filter(Boolean).length +
    Object.values(checkedState.customCheckedMap).filter(Boolean).length

  const allRows = tables.flatMap((table) => table.rows)

  const [customRows, regularRows] = partition(allRows, (row) => row.kind === TableRowKind.ReviewCustomCheckbox)

  const allRowKeys = regularRows.flatMap((row) => row.kind)
  const allCustomRowKeys = customRows.map((row) => row.customCheckboxName || 'Unknown')

  const validateHasErrors = () => {
    if (companyStatus === CompanyStatus.InQualification) {
      setErrors(true)
      return true
    }

    const totalRows = allRowKeys.length + allCustomRowKeys.length
    if (checkedCount !== totalRows) {
      setErrors(true)
      return true
    }

    setErrors(false)
    return false
  }

  const handleDoneClicked = () => {
    const hasErrors = validateHasErrors()
    if (hasErrors) {
      setSubmittedWithErrors(true)
      return
    }
    setCreateReviewLoading(true)
    setIsReviewFinished(true)
  }

  const createReview = async (hasCustomOwners: boolean) => {
    const organizationOverviewImage = await getElementAsEncodedBase64(ownershipsRef.current, {
      name: 'Review ownership',
      id: company.id
    })
    const reviewCardImage = await getElementAsEncodedBase64(reviewCardRef.current, {
      name: 'Review card',
      id: company.id
    })
    const reviewStatus = companyStatus === CompanyStatus.Disqualified ? ReviewStatus.Disapproved : ReviewStatus.Approved

    const inlineCommentsInput = objectEntries(inlineComments).flatMap(([key, value]) => {
      return (
        value?.map(({ createdAt, createdBy, customCheckboxName, message }) => {
          return {
            message,
            fieldKind: key,
            createdBy: createdBy.id,
            createdAt,
            customCheckboxName
          }
        }) ?? []
      )
    })

    const entityAmsHits = extractEntityAmsHits(company)

    const reviewAmsFeedback: ReviewAmsEventFeedbackInput = {
      feedback: reviewState?.amsEventFeedback ?? [],
      hits: Object.entries(entityAmsHits).map(([entity, hits]) => ({ entity, hits }))
    }

    await create({
      variables: {
        entity: company.id,
        input: {
          companyStatus,
          reviewStatus,
          comment,
          auditRisk,
          organizationOverviewImage,
          reviewCardImage,
          inlineCommentsV2: inlineCommentsInput,
          amsEventFeedback: features.AMS_EVENT_FEEDBACK ? reviewAmsFeedback : undefined
        }
      }
    })

    track(TrackedActivityKind.StriseReviewCreated, {
      companyId: company.id,
      legalForm: company.legalForm,
      auditRisk,
      reviewStatus,
      companyStatus,
      comment,
      hasCustomOwnership: hasCustomOwners,
      origin: 'REVIEW'
    })

    resetReviewState(company.id)
  }

  const updateCheckedState = (tableRowKind: TableRowKind, customCheckboxName?: string) => {
    setChecked((prevChecked) => {
      if (tableRowKind === TableRowKind.ReviewCustomCheckbox) {
        const customName = customCheckboxName ?? 'Unknown'
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!prevChecked)
          return {
            ...defaultCheckedState,
            customCheckedMap: {
              [customName]: true
            }
          }

        return {
          ...prevChecked,
          customCheckedMap: {
            ...prevChecked.customCheckedMap,
            [customName]: !prevChecked.customCheckedMap[customName]
          }
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!prevChecked)
        return {
          ...defaultCheckedState,
          checkedMap: {
            [tableRowKind]: true
          }
        }

      return {
        ...prevChecked,
        checkedMap: {
          ...prevChecked.checkedMap,
          [tableRowKind]: !prevChecked.checkedMap[tableRowKind]
        }
      }
    })
  }

  const checkAllIsEnabled = teamReviewSettings.settings.find(
    (setting) => setting.kind === ReviewSettingKind.EnableCheckAll
  )?.enabled

  return (
    <div ref={reviewCardRef} className={checkAllIsEnabled ? undefined : '-mt-4'}>
      <ConflictAlert type={ConflictAlertType.REVIEW} entity={company} className='-mt-4 mb-8' />
      <div className={cn(hasConflicts && 'pointer-events-none select-none opacity-40')}>
        {checkAllIsEnabled && (
          <ReviewSelectAllCheckboxSection
            className='pb-0 pl-4'
            checkedMap={checkedState}
            setChecked={setChecked}
            allRowKeys={allRowKeys}
            allCustomRowKeys={allCustomRowKeys}
            companyId={company.id}
            disabled={createReviewLoading || hasConflicts}
            loading={hasLoadingSections || conflictsLoading}
          />
        )}

        {tables.map((table, index) => {
          return (
            <ReviewTable
              key={index}
              table={table}
              checkedState={checkedState}
              companyId={company.id}
              updateCheckedState={updateCheckedState}
              loading={createReviewLoading || conflictsLoading}
              errors={errors}
              teamReviewSettings={teamReviewSettings}
              inlineComments={inlineComments}
              setInlineComments={setInlineComments}
              disabled={hasConflicts}
            />
          )
        })}
        <div data-id='Review / Comment and Done'>
          <div className='px-4'>
            {checkAllIsEnabled && (
              <ReviewSelectAllCheckboxSection
                className='mt-2'
                checkedMap={checkedState}
                setChecked={setChecked}
                allRowKeys={allRowKeys}
                allCustomRowKeys={allCustomRowKeys}
                companyId={company.id}
                disabled={createReviewLoading || hasConflicts}
                loading={hasLoadingSections || conflictsLoading}
                errors={errors}
              />
            )}

            <ReviewAuditRiskSection
              auditRisk={auditRisk}
              setAuditRisk={setAuditRisk}
              disabled={createReviewLoading}
              teamReviewSettings={teamReviewSettings}
            />

            <ReviewCompanyStatusSection
              companyId={company.id}
              companyStatus={companyStatus}
              noDivider
              setCompanyStatus={setCompanyStatus}
              errors={errors}
              disabled={createReviewLoading}
            />
            {teamReviewSettings.settings.find((setting) => setting.kind === ReviewSettingKind.EnableComment)
              ?.enabled && (
              <ReviewSummarySection
                containerRef={reviewCardRef}
                loading={hasLoadingSections}
                onChange={setComment}
                value={comment}
              />
            )}
          </div>
        </div>
      </div>

      <div className='mt-4 flex items-center px-4'>
        <div className='flex-1'>
          {mutationLoading && (
            <Typography className='mr-4 flex-1'>
              {features.EMAIL_REVIEW ? (
                <Trans>
                  This may take some time. You can safely continue using the app, and you will receive an email when the
                  report is finished.
                </Trans>
              ) : (
                <Trans>This may take some time. You can safely continue using the app.</Trans>
              )}
            </Typography>
          )}
          {errors && (
            <Typography data-id={TestIDs.Review.Card.errorMessage} className='text-semantic-danger-main'>
              <Trans>Please correct the required inputs above</Trans>
            </Typography>
          )}
        </div>

        <Button
          variant='ghost'
          palette='secondary'
          disabled={mutationLoading}
          onClick={toggleResetDialogOpen}
          data-track='false'
          className='mr-1'
        >
          {t`Reset`}
        </Button>
        <Button
          className='px-10'
          variant='contained'
          palette='primary'
          loading={createReviewLoading || conflictsLoading}
          onClick={handleDoneClicked}
          data-track='false'
          disabled={errors || hasConflicts}
          data-id={TestIDs.Review.Card.submitReviewButton}
        >
          {t`Done`}
        </Button>
        {resetDialogOpen && (
          <ConfirmDialog
            isOpen
            confirmText={t`Confirm`}
            cancelText={t`Cancel`}
            onConfirm={handleReset}
            onCancel={handleClose}
            confirmButtonProps={{ palette: 'danger' }}
            title={t`Reset`}
            contentMaxWidth={888}
          >
            <Typography>
              <Trans>You are about to reset the comment, inline comments and checkboxes</Trans>
            </Typography>
          </ConfirmDialog>
        )}
      </div>
      {createReviewLoading && (
        <ExportOwnerships
          entity={company}
          ref={ownershipsRef}
          onLoaded={createReview}
          includeFlags
          includeSubsidiaryCount
        />
      )}
    </div>
  )
}
