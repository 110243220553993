import React, { useState } from 'react'
import { GrowLocationAreaSelection } from './GrowLocationAreaSelection'
import { GrowLocationSearch } from './GrowLocationSearch'
import { defineMessage, t } from '@lingui/macro'
import { ButtonGroup } from '@strise/system'
import { setNestedState, useContext } from '@strise/europa'
import { GrowLocationRestrictionEnum } from './GrowLocationRestrictionEnum'
import { type MessageDescriptor } from '@lingui/core'
import { enumOptions } from '@utils/enum'
import { type UserGrowSettingsFragment } from '@graphqlTypes'
import { GrowSettingsSections } from '@views/Grow/components/GrowSettingsPanel'
import { IconRadio, IconRadioSelected } from '@strise/midgard'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

const tabTranslations: {
  [key in GrowLocationRestrictionEnum]?: MessageDescriptor
} = {
  [GrowLocationRestrictionEnum.NONE]: defineMessage({ message: 'None' }),
  [GrowLocationRestrictionEnum.LOCATION]: defineMessage({ message: 'By location' }),
  [GrowLocationRestrictionEnum.AREA]: defineMessage({ message: 'By area' })
}

const extractRestriction = (growSettings: UserGrowSettingsFragment) => {
  const locations = growSettings.locations
  const polygon = growSettings.coordinates

  if (locations.length) return GrowLocationRestrictionEnum.LOCATION
  if (polygon.length) return GrowLocationRestrictionEnum.AREA
  return GrowLocationRestrictionEnum.NONE
}

export const GrowLocationRestriction = () => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const growSettings = settings.grow
  const saveGrowSettings = setNestedState(saveSettings, (s) => s.grow)

  const defaultRestriction = extractRestriction(settings.grow)
  const [restrictState, setRestrict] = useState<GrowLocationRestrictionEnum>(defaultRestriction)

  const handleRestrictionChange = (value: GrowLocationRestrictionEnum) => {
    setRestrict(value)

    if (value === GrowLocationRestrictionEnum.LOCATION) {
      saveGrowSettings({
        ...growSettings,
        coordinates: []
      })
    } else if (value === GrowLocationRestrictionEnum.AREA) {
      saveGrowSettings({
        ...growSettings,
        locations: []
      })
    } else {
      saveGrowSettings({
        ...growSettings,
        locations: [],
        coordinates: []
      })
    }
  }

  const restrictOptions = enumOptions(Object.values(GrowLocationRestrictionEnum), tabTranslations, (option) => ({
    buttonProps: {
      startIcon:
        restrictState === option ? (
          <IconRadioSelected size='md' className='mr-2 shrink-0' />
        ) : (
          <IconRadio size='md' className='mr-2 shrink-0' />
        )
    }
  }))

  return (
    <GrowSettingsSections
      title={t`Location restriction`}
      description={t`Search location or click on the map to limit the area manually.`}
    >
      <ButtonGroup<GrowLocationRestrictionEnum>
        palette='secondary'
        width={1}
        mb={4}
        onClick={handleRestrictionChange}
        value={restrictState}
        options={restrictOptions.map((option) => ({
          ...option,
          text: option.label
        }))}
      />

      {restrictState === GrowLocationRestrictionEnum.LOCATION && (
        <GrowLocationSearch
          locations={growSettings.locations}
          setLocations={setNestedState(saveGrowSettings, (s) => s.locations)}
        />
      )}

      {restrictState === GrowLocationRestrictionEnum.AREA && (
        <div className='max-w-[600px]'>
          <GrowLocationAreaSelection
            polygon={growSettings.coordinates}
            setPolygon={setNestedState(saveGrowSettings, (s) => s.coordinates)}
          />
        </div>
      )}
    </GrowSettingsSections>
  )
}
