import * as React from 'react'
import { i18n, type MessageDescriptor } from '@lingui/core'
import { companyStatuses, flagSeverityToTitle, useSortedAssignees } from '@strise/europa'
import { companyStatusTitles } from '@strise/europa/src/i18n'
import { objectEntries, toTitleCase } from '@strise/fika'
import { type CompanyStatus, type ContentLanguage, EventType, type FlagSeverity, type Primitive } from '@strise/types'
import { useSearchLocationsQuery, useTopicsQuery } from '@graphqlOperations'
import { useCountryOptions, countryLabels } from '@utils/country'
import { enumOptions, getTitle } from '@utils/enum'
import { useSortTags, useTeamTags } from '../Tags/tagUtils'
import { useTeamUsers, defaultTeamUsersPageVariables } from '@utils/teamUsers'
import { useYou } from '../Assignee/assigneeHooks'
import { defineMessage, t } from '@lingui/macro'
import { type ComboboxItem, type ComboboxPaginationProps } from '@strise/midgard'
import {
  type CompanyTagFragment,
  type TopicFragment,
  type SimpleUserFragment,
  type RegionFragment
} from '@graphqlTypes'
import { User } from '@components/Assignee/User'

export type FilterOptionsHook<T extends Primitive | object> = ({
  currentValues,
  searchValue
}: {
  currentValues: T[]
  searchValue: string | null | undefined
}) => {
  disableBackendSearch?: boolean
  hasNextPage?: boolean
  items: Array<ComboboxItem<T>>
  loading: boolean
  paginationProps?: ComboboxPaginationProps
  value: Array<ComboboxItem<T>>
}

export const useLoadingCountryOptions: FilterOptionsHook<ContentLanguage> = ({ currentValues }) => {
  const options = useCountryOptions()
  const value = enumOptions(currentValues, countryLabels)
  return { value, items: options, loading: false }
}

export const useTagOptions: FilterOptionsHook<CompanyTagFragment> = ({ currentValues }) => {
  const { loading, tags: teamTags } = useTeamTags()
  const tagEdges = useSortTags(teamTags)
  const options = tagEdges.map(({ node }) => ({
    label: node.name,
    id: node.id,
    value: node
  }))
  const value = currentValues.map((tag) => ({
    label: tag.name,
    id: tag.id,
    value: tag
  }))

  return { value, items: options, loading }
}

export const useCompanyStatusOptions: FilterOptionsHook<CompanyStatus> = ({ currentValues }) => {
  return {
    value: enumOptions(currentValues, companyStatusTitles),
    items: enumOptions(companyStatuses, companyStatusTitles),
    loading: false
  }
}

export const useSeverityOptions: FilterOptionsHook<FlagSeverity> = ({ currentValues }) => {
  const options = objectEntries(flagSeverityToTitle).map(([severity, severityTranslation]) => ({
    label: i18n._(severityTranslation),
    id: severity as string,
    value: severity
  }))
  const value = enumOptions(currentValues, flagSeverityToTitle)
  return { value, items: options, loading: false }
}

export const useTopicsOptions: FilterOptionsHook<TopicFragment> = ({ currentValues }) => {
  const { data, loading } = useTopicsQuery()

  const options =
    data?.topics.edges.map(({ node }) => ({
      label: node.name ? toTitleCase(node.name) : t`Unknown`,
      value: node,
      id: node.id
    })) ?? []

  const value = currentValues.map((topic) => ({
    label: topic.name ? toTitleCase(topic.name) : t`Unknown`,
    value: topic,
    id: topic.id
  }))

  return { value, items: options, loading }
}

export const useLocationOptions: FilterOptionsHook<RegionFragment> = ({ currentValues, searchValue }) => {
  const { data, loading } = useSearchLocationsQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { q: searchValue! },
    skip: !searchValue
  })

  const options =
    data?.locationsV2.edges.map((location) => ({
      label: location.node.name ?? t`Unknown`,
      value: location.node,
      id: location.node.id
    })) ?? []

  const value = currentValues.map((location) => ({
    label: location.name ?? t`Unknown`,
    value: location,
    id: location.id
  }))

  return { value, items: options, loading }
}

export const useAssigneeOptions: FilterOptionsHook<SimpleUserFragment | null> = ({ currentValues, searchValue }) => {
  const [maxTotalCount, setMaxTotalCount] = React.useState<number | null>(null)
  const pageLimit = 100

  // TODO use currentValues to filter out already selected users
  const { fetchMore, hasNextPage, loading, teamUsers } = useTeamUsers({
    variables: {
      q: searchValue,
      page: {
        limit: 100,
        offset: 0
      }
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const tCount = data.team.users.totalCount
      if (maxTotalCount === null && tCount && searchValue === '') {
        // Setting maxTotalCount initially to know the maximum amount with an empty `q` search string
        setMaxTotalCount(tCount)
      }
    }
  })

  const handleNextPage = () => {
    fetchMore({
      variables: {
        q: searchValue,
        page: {
          ...defaultTeamUsersPageVariables,
          limit: pageLimit,
          // Use previously cached length as offset
          offset: teamUsers.length
        }
      }
    })
  }

  const nonNullCurrentValues = currentValues.filter((val) => val !== null)

  // TODO: Create a common utils function for useAssigneeOptions, GrowAssigneeSelect and AssigneeSearchSelect for this assignee merging logic

  // TODO: this can probably be handled in apollo client with merging somehow
  // As we now use search and pagination backend, we need to merge assignees and teamUsers
  // Create a map of assignee ids for more efficient lookup
  const assigneeIds = new Set(nonNullCurrentValues.map((assignee) => assignee.id))
  // Filter out assignees from teamUsers
  const filteredTeamUsers = teamUsers.filter((teamUser) => !assigneeIds.has(teamUser.node.id))
  // Merge assignees and teamUsers
  const assigneeEnrichedTeamUsers: Array<{ node: SimpleUserFragment }> = [
    ...nonNullCurrentValues.map((user) => ({ node: user })),
    ...filteredTeamUsers
  ].flat()
  // If maxTotalCount is set, and we have fetched the equal amount of users, we can disable backend search - which will rarely be needed for most teams.
  const disableBackendSearch = !!maxTotalCount && maxTotalCount <= assigneeEnrichedTeamUsers.length

  const youUserEdges = useYou(assigneeEnrichedTeamUsers)
  const sortedUserEdges = useSortedAssignees(youUserEdges)
  const options: Array<ComboboxItem<SimpleUserFragment | null>> = [
    {
      id: String(null),
      label: t`No assignee`,
      value: null
    },
    ...sortedUserEdges.map(({ node }) => {
      return {
        id: node.id,
        label: node.name,
        value: node,
        renderNode: <User user={node} />
      }
    })
  ]
  const value = currentValues.map((user) => {
    return {
      id: user?.id ?? String(null),
      label: user?.name ?? t`No assignee`,
      value: user
    }
  })

  const paginationProps: ComboboxPaginationProps = {
    disabled: loading,
    loading,
    loadMoreText: t`Fetch more`,
    hasNextPage,
    onLoadMore: handleNextPage
  }

  return { value, items: options, loading, paginationProps, disableBackendSearch }
}

const filterableEventTypes = [
  EventType.Announcements,
  EventType.Correspondence,
  EventType.Courts,
  EventType.FlaggedEvents,
  EventType.Media
] as const

export const eventTypeTitles: {
  [key in EventType]?: MessageDescriptor
} = {
  [EventType.FlaggedEvents]: defineMessage({ message: 'Flagged events' }),
  [EventType.Announcements]: defineMessage({ message: 'Announcements' }),
  [EventType.Courts]: defineMessage({ message: 'Courts' }),
  [EventType.Correspondence]: defineMessage({ message: 'Correspondence' }),
  [EventType.Media]: defineMessage({ message: 'Media' })
}

export const useEventTypesOptions: FilterOptionsHook<EventType> = ({ currentValues }) => {
  const options = filterableEventTypes.map((eventType) => {
    const title = getTitle(eventTypeTitles[eventType])

    return { id: eventType as string, label: title, value: eventType }
  })

  const value = currentValues.map((eventType) => {
    const title = getTitle(eventTypeTitles[eventType])

    return { id: eventType as string, label: title, value: eventType }
  })

  return { value, items: options, loading: false }
}
