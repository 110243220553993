import * as React from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { createContext } from '@strise/europa'

interface SidepanelNavHistoryContextProps {
  currentIndex: number
  setCurrentIndex: SetStateFn<number>
  setSidepanelHistory: SetStateFn<string[]>
  sidepanelHistory: string[]
}

export const SidepanelNavHistoryContext = createContext<SidepanelNavHistoryContextProps>()

export const SidepanelNavHistoryContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [sidepanelHistory, setSidepanelHistory] = React.useState<string[]>([])
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)

  const sidepanelNavHistoryContextValue = React.useMemo(
    () => ({
      sidepanelHistory,
      setSidepanelHistory,
      currentIndex,
      setCurrentIndex
    }),
    [sidepanelHistory, currentIndex]
  )

  return (
    <SidepanelNavHistoryContext.Provider value={sidepanelNavHistoryContextValue}>
      {children}
    </SidepanelNavHistoryContext.Provider>
  )
}
