import { IconPlus, Typography } from '@strise/midgard'
import * as React from 'react'
import { type CompanyStatus } from '@strise/types'
import { defineMessage } from '@lingui/macro'
import { type I18n } from '@lingui/core'
import { CompanyStatusDot } from './CompanyStatusDot'
import { companyStatusTitles } from './companyStatusUtils'

export const CompanyStatusSelectButton: React.FC<{
  i18n: I18n
  value?: CompanyStatus | null
}> = ({ i18n, value }) => {
  const title = value ? companyStatusTitles[value] : null

  const label = title ?? defineMessage({ message: 'Add to portfolio' })

  return (
    <div className='flex w-full items-center'>
      {value && <CompanyStatusDot status={value} className='mr-2' />}
      {!value && <IconPlus size='md' className='mr-2 shrink-0' />}
      <Typography component='span' variant='aLabel' className='truncate'>
        {i18n._(label)}
      </Typography>
    </div>
  )
}
