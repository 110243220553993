import { type CreditReportFragment } from '@graphqlTypes'
import { i18n, type MessageDescriptor } from '@lingui/core'
import { defineMessage, t, Trans } from '@lingui/macro'
import { objectEntries } from '@strise/fika'
import { Typography } from '@strise/midgard'
import { type StyleProps, Table, TableCell, type TableCellProps, TableRow, type TableRowProps } from '@strise/system'
import { type IncomeConnectionEdge, type Maybe } from '@strise/types'
import * as React from 'react'
import { Empty } from '@strise/europa'
import { isNumber } from 'lodash-es'

interface TransposedIncomes {
  grossIncome: Array<number | null>
  incomeYear: number[]
  municipalityCode: Array<string | null>
  netIncome: Array<number | null>
  netWealth: Array<number | null>
  sumTax: Array<number | null>
  taxClass: Array<string | null>
}

const columnToRow: Record<
  keyof Omit<TransposedIncomes, 'incomeYear'>,
  { borderBottom?: string; cellProps?: TableCellProps; title: MessageDescriptor }
> = {
  municipalityCode: {
    title: defineMessage({ message: 'Municipality code' }),
    cellProps: { pt: 8, color: 'secondary.50' }
  },
  grossIncome: {
    title: defineMessage({ message: 'Gross income' })
  },
  taxClass: {
    title: defineMessage({ message: 'Tax class' }),
    cellProps: { pt: 8, color: 'secondary.50' },
    borderBottom: 'none'
  },
  sumTax: {
    title: defineMessage({ message: 'Taxes paid' }),
    cellProps: { color: 'secondary.50' }
  },
  netIncome: {
    title: defineMessage({ message: 'Net income' })
  },
  netWealth: {
    title: defineMessage({ message: 'Net wealth' })
  }
}

const numberFormat = new Intl.NumberFormat('no-NO', {
  maximumFractionDigits: 0
})

const CreditReportIncomeRow: React.FC<
  {
    cellProps?: StyleProps
    title: string
    values: Array<number | null> | Array<string | null>
  } & TableRowProps
> = ({ cellProps, className, title, values, ...props }) => {
  return (
    <TableRow className={className} borderColor='black' borderBottom='1px solid' {...props}>
      <TableCell pt={4} {...cellProps}>
        <Typography>{title}</Typography>
      </TableCell>
      {values.map((value, index) => (
        <TableCell key={index} textAlign='right' {...cellProps}>
          <Typography>
            {value && (isNumber(value) ? numberFormat.format(value) : value)}
            {!value && '-'}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  )
}

const toNearestThousand = (value: Maybe<number> | undefined) => {
  return value ? Math.round(value / 1000) : null
}

const transposeIncome = (incomes: IncomeConnectionEdge[]): TransposedIncomes => {
  return incomes
    .map((e) => e.node)
    .reduce<TransposedIncomes>(
      (acc, income) => {
        return {
          incomeYear: income.incomeYear ? [...acc.incomeYear, income.incomeYear] : acc.incomeYear,
          netIncome: [...acc.netIncome, toNearestThousand(income.netIncome)],
          grossIncome: [...acc.grossIncome, toNearestThousand(income.grossIncome)],
          netWealth: [...acc.netWealth, toNearestThousand(income.netWealth)],
          sumTax: [...acc.sumTax, toNearestThousand(income.sumTax)],
          municipalityCode: [...acc.municipalityCode, income.municipalityCode ?? null],
          taxClass: [...acc.taxClass, income.taxClass ?? null]
        }
      },
      {
        incomeYear: [],
        netIncome: [],
        grossIncome: [],
        netWealth: [],
        sumTax: [],
        municipalityCode: [],
        taxClass: []
      }
    )
}

export const SidepanelCreditReportIncome = ({ creditReport }: { creditReport?: CreditReportFragment | null }) => {
  if (!creditReport?.incomes?.edges.length) {
    return (
      <Empty title={t`No registered incomes found`}>
        <Trans>We couldn't find any registered incomes on this company</Trans>
      </Empty>
    )
  }

  const transposedData = transposeIncome(creditReport.incomes.edges)

  return (
    <Table my={4}>
      <thead>
        <TableRow borderColor='black'>
          <TableCell>
            <Trans>in NOK ‘000</Trans>
          </TableCell>
          {transposedData.incomeYear.map((year) => {
            return (
              <TableCell key={year} textAlign='right'>
                <Typography>{year}</Typography>
              </TableCell>
            )
          })}
        </TableRow>
      </thead>
      <tbody>
        {objectEntries(columnToRow).map(([key, row]) => {
          return (
            <CreditReportIncomeRow
              key={key}
              title={i18n._(row.title)}
              values={transposedData[key]}
              cellProps={row.cellProps}
              borderBottom={row.borderBottom}
            />
          )
        })}
      </tbody>
    </Table>
  )
}
