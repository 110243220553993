import { IconNotificationAll, IconNotificationLess } from '@strise/midgard'
import * as React from 'react'
import { type SidepanelCompanyFragment, type SidepanelGlobalCompanyFragment } from '@graphqlTypes'
import { DropdownMenu } from '../DropdownMenu'
import { t } from '@lingui/macro'
import { useUserId } from '@strise/europa'
import { NotificationSettingStatus } from '@strise/types'
import { useChangeCompanyNotificationSetting } from '@views/Settings/UserSettings/Notifications/notificationHook'

type SidepanelNotificationEntity = SidepanelCompanyFragment | SidepanelGlobalCompanyFragment

export const SidepanelNotificationSettings: React.FC<{
  notificationEntity: SidepanelNotificationEntity
}> = ({ notificationEntity }) => {
  const userId = useUserId()
  const isCompany = notificationEntity.__typename === 'Company'

  const isAssignedToUser = isCompany
    ? !!notificationEntity.assignee.edges.some(({ node: assignedUser }) => assignedUser.id === userId)
    : []

  const { loading, update } = useChangeCompanyNotificationSetting()

  const notificationSettingStatus = notificationEntity.notificationSetting?.status ?? NotificationSettingStatus.Enabled
  const isEnabled = notificationSettingStatus === NotificationSettingStatus.Enabled

  const handleTurnOff = async () =>
    await update({
      variables: {
        entity: notificationEntity.id,
        status: NotificationSettingStatus.Disabled
      }
    })
  const handleTurnOn = async () =>
    await update({
      variables: {
        entity: notificationEntity.id,
        status: NotificationSettingStatus.Enabled
      }
    })

  const menuItems = [
    {
      startIcon: <IconNotificationLess />,
      title: t`Turn off for ${notificationEntity.name}`,
      onClick: handleTurnOff,
      hide: !isEnabled
    },
    {
      startIcon: <IconNotificationAll />,
      title: t`Turn on for ${notificationEntity.name}`,
      onClick: handleTurnOn,
      hide: isEnabled
    }
  ]

  return (
    <DropdownMenu
      menuItems={menuItems}
      icon={isEnabled && isAssignedToUser ? <IconNotificationAll /> : <IconNotificationLess />}
      buttonProps={{
        className: 'p-2 ml-2',
        variant: 'ghost',
        palette: 'tertiary',
        loading,
        disabled: !isAssignedToUser
      }}
      disabledText={t`You have to be assigned to edit notification settings`}
      paperProps={{ className: 'min-w-[300px]' }}
      dataTrack='Sidepanel / Toggle company notifications dropdown'
    />
  )
}
