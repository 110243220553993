import { createContext } from '@strise/europa'

export type RecentlyVisitedEntitiesMap = Record<string, { visitedAt: number }>

interface RecentlyVisitedEntitiesContextProps {
  addRecentlyVisitedEntity: (id: string) => void
  extractIsEntityRecentlyVisited: (id: string) => boolean
  recentlyVisitedEntitiesMap: RecentlyVisitedEntitiesMap
  removeRecentlyVisitedEntity: (id: string) => void
}

export const RecentlyVisitedEntitiesContext = createContext<RecentlyVisitedEntitiesContextProps>()
