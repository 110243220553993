import * as React from 'react'
import { toast } from '@strise/europa'
import { t, Trans } from '@lingui/macro'
import { ConfirmDialog } from '@strise/system'
import { type SetStateFn } from '@strise/react-utils'
import { type TeamActivityFragment } from '@graphqlTypes'
import { refreshCompanyRemindersState } from '@state'
import { type TeamActivityConnection, type TeamActivityPageInfoInput } from '@strise/types'
import { useDeleteTeamActivityMutation } from '@graphqlOperations'

export const DeleteTeamActivity: React.FC<{
  companyId: string
  pageInfo: TeamActivityPageInfoInput
  setOpen: SetStateFn<false | string>
  teamActivity: TeamActivityFragment
}> = ({ companyId, pageInfo, setOpen, teamActivity }) => {
  const handleCompleted = () => {
    refreshCompanyRemindersState(refreshCompanyRemindersState() + 1)
    toast.success(t`Team activity deleted`)
    setOpen(false)
  }

  const [deleteTeamActivity] = useDeleteTeamActivityMutation({
    variables: { teamActivity: teamActivity.id, companyId, page: pageInfo },
    onCompleted: handleCompleted,
    update: (cache) => {
      cache.evict({ id: `TeamActivity:${teamActivity.id}` })
      cache.gc()

      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          // @ts-expect-error
          teamActivities(curr: TeamActivityConnection | undefined, { readField }) {
            return curr?.edges.filter((teamActivityRef) => teamActivity.id !== readField('id', teamActivityRef))
          }
        }
      })
    }
  })

  const handleConfirm = async () => await deleteTeamActivity()

  const handleCancel = () => setOpen(false)

  return (
    <ConfirmDialog
      isOpen
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmText={t`Delete`}
      cancelText={t`Cancel`}
      title={t`Delete team activity`}
      danger
    >
      <Trans>Are you sure you want to delete the team activity?</Trans>
    </ConfirmDialog>
  )
}
