import * as React from 'react'
import { useMemo } from 'react'
import { decamelize } from '@strise/fika'
import { AuthError, isRateLimitError, toast, useLogout } from '@strise/europa'
import { trackError } from '@utils/errorTracking'
import { getMainDefinition } from '@apollo/client/utilities/index.js'
import { onError } from '@apollo/client/link/error/index.js'
import { spoofUser } from '@state'
import { spoof } from '@utils/spoof'
import { t, Trans } from '@lingui/macro'
import { OpenChatLink } from '@components/ContactStrise'
import { Kind, OperationTypeNode } from 'graphql/language'

const severityFromStatusCode = (statusCode: number | false | null | undefined) => {
  switch (statusCode) {
    case 401: {
      return 'warning'
    }
    case 404: {
      return 'warning'
    }
    default: {
      return 'error'
    }
  }
}

export const logNetworkError = (statusCode: number | false | null | undefined, message: string) => {
  trackError.network(statusCode || 'Unknown status code', message, severityFromStatusCode(statusCode))
}

export const useErrorLink = () => {
  const logout = useLogout()
  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError, operation }) => {
        const statusCode = networkError && 'statusCode' in networkError && networkError.statusCode
        const operationContext = operation.getContext()

        const disableLogging =
          statusCode &&
          Array.isArray(operationContext.disableErrorLogging) &&
          operationContext.disableErrorLogging.includes(statusCode)

        if (spoofUser()) {
          spoof.stop()
        } else if (statusCode === 401) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const hasToken = Boolean(operationContext.headers.authorization)
          const errorString = `${operation.operationName} with token: ${String(hasToken)}`
          logNetworkError(statusCode, errorString)
          logout(AuthError.AuthenticationFailed, '401')
        } else if (graphQLErrors && !disableLogging) {
          const definition = getMainDefinition(operation.query)
          const operationType = definition.kind === Kind.OPERATION_DEFINITION && definition.operation
          const operationName = decamelize(operation.operationName, ' ')

          const showErrorToast = graphQLErrors.every((error) => {
            const errorString = `${operation.operationName}: ${error.message}`
            logNetworkError(statusCode, errorString)

            return !isRateLimitError(error)
          })

          if (statusCode === 429) {
            toast.error(
              <>
                Rate limit has been exceeded. Please{' '}
                <OpenChatLink className='inline text-accent-blue-shade-20' msg={t`I want to increase the rate limit.`}>
                  <Trans>contact Strise</Trans>
                </OpenChatLink>{' '}
                to increase this limit.
              </>
            )
          } else if (statusCode === 408) {
            toast.error(
              <>
                <Trans>
                  Looks like the server is taking to long to respond ({operationName}). Please try again later or
                </Trans>{' '}
                <OpenChatLink
                  className='inline text-accent-blue-shade-20'
                  msg={t`The server is taking long to respond, can you help me?`}
                >
                  <Trans>contact Strise</Trans>
                </OpenChatLink>{' '}
                <Trans>if the issue persists.</Trans>
              </>
            )
          } else if (showErrorToast) {
            if (operationType === OperationTypeNode.QUERY && !operation.getContext().hideErrorToast) {
              toast.error(`Error occurred when fetching ${operationName}`)
            } else if (operationType === OperationTypeNode.MUTATION && !operation.getContext().hideErrorToast) {
              toast.error(`Error occurred when trying to ${operationName}`)
            }
          }
        }
      }),
    [logout]
  )
  return errorLink
}
