import * as React from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle, Typography } from '@strise/midgard'
import { t } from '@lingui/macro'

interface HomeGreetingCardProps {
  team: string
  userName: string
}

export const HomeGreetingCard = ({ team, userName }: HomeGreetingCardProps) => {
  const getGreeting = () => {
    const currentHour = new Date().getHours()

    if (currentHour < 12) {
      return t`Good morning`
    }
    if (currentHour < 18 && currentHour >= 12) {
      return t`Good afternoon`
    }
    return t`Good evening`
  }
  return (
    <Card className='border-none bg-transparent'>
      <CardHeader>
        <CardTitle>
          <Typography variant='h3'>
            {getGreeting()}, {userName}
          </Typography>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription>
          <Typography>Team</Typography>
        </CardDescription>
        <CardTitle>
          <Typography>{team}</Typography>
        </CardTitle>
      </CardContent>
    </Card>
  )
}
