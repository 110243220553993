import React from 'react'
import { useIntersectionRef, useIsMobile } from '@utils/hooks'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import { useContext } from '@strise/europa'
import { EntityKeyMetaItems } from '@components/Ownerships/EntityKeyMetaItems'
import { cn } from '@strise/midgard'
import { type DivProps } from '@strise/react-utils'
import { SidepanelContext } from '@components/Sidepanel/SidepanelContext/SidepanelContext'

export const SidepanelEntityKeyMetaDetails: React.FC<
  {
    entity: EntityLikeMetaFragment
  } & DivProps
> = ({ className, entity, ...props }) => {
  const { setShowHeaderEntity } = useContext(SidepanelContext)

  const intersectionRef = useIntersectionRef({ threshold: 1 }, (entries) => {
    entries.forEach((entry) => {
      setShowHeaderEntity(entry.intersectionRatio !== 1)
    })
  })

  const mobile = useIsMobile()

  return (
    <EntityKeyMetaItems
      className={cn(
        'grid',
        mobile ? 'grid-cols-1 items-start' : 'grid-cols-[repeat(auto-fill,_minmax(136px,_1fr))] items-center gap-1',
        className
      )}
      entity={entity}
      ref={intersectionRef}
      itemProps={{
        className: mobile ? 'mt-2' : undefined
      }}
      {...props}
    />
  )
}
