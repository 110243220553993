import { cn, Divider, IconButton, Typography, IconCross, IconFilter } from '@strise/midgard'
import { defineMessage } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { useIsMobile } from '@utils/hooks'
import { sum } from 'lodash-es'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { ActiveFilters } from '../../Filter/ActiveFilters'
import { EnumChip } from '../../Filter/FilterChips'
import { filterArray, type FilterSpec } from '../../Filter/FilterHOC'
import { useEventTypesOptions, useTopicsOptions, eventTypeTitles } from '../../Filter/filterHooks'
import { type UserSettingsFragment } from '@graphqlTypes'
import { IdNameChip } from '@strise/europa'

const filters: Record<string, FilterSpec<UserSettingsFragment>> = {
  sources: filterArray({
    path: 'sidepanel.sources',
    ChipComponent: EnumChip,
    titleMap: eventTypeTitles,
    useOptions: useEventTypesOptions,
    dataTrack: 'Sidepanel Events / Filter / Types / Changed',
    editLabel: defineMessage({ message: 'Types' }),
    enableSearch: true
  }),
  topics: filterArray({
    path: 'sidepanel.topics',
    ChipComponent: IdNameChip,
    useOptions: useTopicsOptions,
    dataTrack: 'Sidepanel Events / Filter / Topics / Changed',
    editLabel: defineMessage({ message: 'Topic' }),
    enableSearch: true
  })
}

export const EventsFilter: React.FC<{
  saveSettings: SetStateFn<UserSettingsFragment>
  settingsState: UserSettingsFragment
}> = ({ saveSettings, settingsState }) => {
  const [edit, toggleEdit] = useToggle(false)

  const filteredFilters = Object.values(filters).filter((value) => !!value.totalSelected(settingsState))
  const isMobile = useIsMobile()
  const totalActiveFiltersCount = sum(filteredFilters.map((filter) => filter.totalSelected(settingsState)))

  return (
    <div
      className={cn(
        'flex min-h-[theme(height.sub-header)] items-center border-y border-solid border-divider',
        edit ? 'bg-secondary-shade-90 text-secondary-contrastText' : 'bg-none text-text-primary'
      )}
      data-id='Sidepanel / Event Filter'
    >
      <div className='flex'>
        <IconButton
          className={cn(
            'h-sub-header w-[theme(height.sub-header)] shrink-0 self-start',
            edit ? 'bg-secondary-shade-70' : ''
          )}
          variant={edit ? 'contained' : 'ghost'}
          palette={edit ? 'secondary' : 'tertiary'}
          onClick={toggleEdit}
          data-track={edit ? 'Sidepanel Events / Filter / Close' : 'Sidepanel Events / Filter / Open'}
        >
          {edit ? <IconCross className='text-secondary-shade-90' /> : <IconFilter />}
        </IconButton>
        {isMobile && !edit && !!totalActiveFiltersCount && (
          <div className='absolute m-1 size-5 rounded-full bg-primary-main text-center text-background-paper'>
            <Typography variant='body2' className='leading-5'>
              {totalActiveFiltersCount}
            </Typography>
          </div>
        )}
      </div>
      {!edit && <Divider className='mr-4 self-stretch' orientation='vertical' />}

      {!edit && !isMobile && (
        <div className='flex min-h-[theme(height.sub-header)] flex-wrap items-center'>
          <ActiveFilters
            toggleEdit={toggleEdit}
            filters={filteredFilters}
            setValue={saveSettings}
            value={settingsState}
          />
        </div>
      )}

      {edit && (
        <div className='flex min-h-[theme(height.sub-header)] grow flex-wrap items-center'>
          {Object.values(filters).map(({ EditComponent }, index) => (
            <div className='max-w-[250px] grow' key={index}>
              <EditComponent value={settingsState} setValue={saveSettings} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
