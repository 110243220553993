import * as React from 'react'
import { DatePicker as SystemDatePicker, type DatePickerProps, registerDatePickerLocale } from '@strise/system'
import { nb, sv, da } from 'date-fns/locale'
import { languageToLocaleStr, useDisplayLanguage } from '@contexts/DisplayLanguageContext/displayLanguageContextUtils'

registerDatePickerLocale('nb', nb)
registerDatePickerLocale('sv', sv)
registerDatePickerLocale('da', da)

export const DatePicker = (props: DatePickerProps) => {
  const displayLanguage = useDisplayLanguage()

  return <SystemDatePicker locale={languageToLocaleStr[displayLanguage]} {...props} />
}
