import { Button, cn, Typography, type TypographyProps } from '@strise/midgard'
import { Trans } from '@lingui/macro'
import { extractCreditNotRated } from '@utils/creditScore'
import * as React from 'react'
import { Score } from '../Score/Score'
import { scoreToColor } from '../Score/scoreUtils'
import { type DivProps } from '@strise/react-utils'
import { OrderCreditReport, type OrderCreditReportProps } from '../OrderCreditReport'
import { TestIDs } from '@utils/testIDs'

type SidepanelCreditScoreIndicatorSize = 'small' | 'medium'

const sizeStyles: Record<
  SidepanelCreditScoreIndicatorSize,
  {
    scoreRadius: number
    scoreTypographyVariant: TypographyProps['variant']
    scoreWidth: number
  }
> = {
  small: {
    scoreWidth: 8,
    scoreRadius: 40,
    scoreTypographyVariant: 'aLabel'
  },
  medium: {
    scoreWidth: 15,
    scoreRadius: 70,
    scoreTypographyVariant: 'h2'
  }
}

export const CreditScoreIndicator = ({
  className,
  disableAnimation,
  isFetched,
  mixedColors,
  orderCreditReportProps,
  score,
  scoreCharacter,
  size = 'medium',
  toggleHelpModalOpen,
  ...props
}: {
  disableAnimation?: boolean
  isFetched?: React.ReactNode
  mixedColors?: boolean
  orderCreditReportProps?: OrderCreditReportProps
  score?: number | null
  scoreCharacter?: string
  size: 'small' | 'medium'
  toggleHelpModalOpen?: () => void
} & DivProps) => {
  const { scoreRadius, scoreTypographyVariant, scoreWidth } = sizeStyles[size]
  const scoreColor = score ? scoreToColor(score, 100) : 'text.primary'
  const notRated = extractCreditNotRated(score, scoreCharacter)
  const containerSize = scoreWidth + scoreRadius * 2
  const buttonClassName = 'h-fit text-sm leading-4 hover:bg-transparent active:bg-transparent hover:underline'
  return (
    <div className={cn('flex justify-center', className)} {...props}>
      <div className='relative' style={{ width: containerSize, height: containerSize }}>
        <Score
          score={score}
          radius={scoreRadius}
          mixedColors={mixedColors}
          disableAnimation={disableAnimation}
          width={scoreWidth}
        />
        <div
          className='absolute top-0 flex size-full flex-col justify-center text-center'
          data-id='credit-score-indicator'
        >
          {isFetched &&
            (notRated ? (
              <>
                <Typography className='mb-2 w-full' variant='subtitle2' component='span'>
                  <Trans>No score</Trans>
                </Typography>
                <Button
                  variant='ghost'
                  palette='primary'
                  onClick={toggleHelpModalOpen}
                  data-track='Credit score / Not rated / Help modal / Open'
                  className={cn(buttonClassName, size === 'small' && 'px-0')}
                >
                  <Trans>Why some entities don't get a score</Trans>
                </Button>
              </>
            ) : (
              <Typography
                variant={scoreTypographyVariant}
                component='span'
                style={{ color: scoreColor }}
                data-id={TestIDs.Common.CreditScore.score}
              >
                {score || '-'} {scoreCharacter || '-'}
              </Typography>
            ))}
          {!isFetched && orderCreditReportProps && (
            <OrderCreditReport
              {...orderCreditReportProps}
              fetchCreditScoreButtonProps={{
                variant: 'ghost',
                palette: 'primary',
                className: buttonClassName,
                children: <Trans>Fetch score to see rating</Trans>
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
