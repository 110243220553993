import * as React from 'react'
import { type DivPropsWithChildren } from '@strise/react-utils'
import { Typography, type TypographyProps } from './Typography'
import { type IconComponent } from '../utils/iconUtils'
import { IconWarningFill } from '../icons/general'
import { cn } from '../utils/className'

export type AlertVariant = 'info' | 'warning' | 'danger' | 'neutral'
export type AlertSize = 'sm' | 'md'

// TODO - refactor variants to use `cva` like `Button` etc (see `components/variants` directory).
const variantStyles: {
  [key in AlertVariant]: { bgColorClass: string; colorClass: string; iconColorClass: string }
} = {
  info: {
    bgColorClass: 'bg-semantic-info-shade-5',
    colorClass: 'text-semantic-info-shade-100',
    iconColorClass: 'text-semantic-info-shade-50'
  },
  warning: {
    bgColorClass: 'bg-semantic-notice-shade-5',
    colorClass: 'text-semantic-notice-shade-100',
    iconColorClass: 'text-semantic-notice-shade-50'
  },
  danger: {
    bgColorClass: 'bg-semantic-danger-shade-5',
    colorClass: 'text-semantic-danger-shade-50',
    iconColorClass: 'text-semantic-danger-shade-50'
  },
  neutral: {
    bgColorClass: 'bg-transparent',
    colorClass: 'text-text-secondary',
    iconColorClass: 'text-text-secondary'
  }
}

const sizeStyles: {
  [key in AlertSize]: {
    className: string
    textVariant: TypographyProps['variant']
  }
} = {
  sm: {
    className: 'p-1',
    textVariant: 'body2'
  },
  md: {
    className: 'p-2',
    textVariant: 'body1'
  }
}

export interface AlertProps extends DivPropsWithChildren {
  icon?: IconComponent
  size?: 'sm' | 'md'
  textProps?: TypographyProps
  variant: AlertVariant
}

export const Alert: React.FC<AlertProps> = ({
  children,
  className,
  icon: customIcon,
  size,
  textProps,
  variant,
  ...props
}) => {
  const { bgColorClass, colorClass, iconColorClass } = variantStyles[variant]
  const { className: sizeClassName, textVariant } = sizeStyles[size ?? 'md']

  const Icon = customIcon ?? IconWarningFill

  return (
    <div className={cn('flex w-fit rounded align-middle', sizeClassName, className, bgColorClass)} {...props}>
      <Icon className={cn('mr-2 shrink-0', iconColorClass)} size={size} />
      <Typography component='div' variant={textVariant} {...textProps} className={cn(textProps?.className, colorClass)}>
        {children}
      </Typography>
    </div>
  )
}
