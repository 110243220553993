import { cn, Typography, type TypographyProps } from '@strise/midgard'
import * as React from 'react'
import { Box, type BoxProps } from './Box'

export interface TableProps extends BoxProps {
  children?: React.ReactNode
}

export const Table = React.forwardRef(({ className, ...props }: TableProps, ref) => {
  return <Box className={cn('w-full border-collapse', className)} ref={ref} component='table' {...props} />
})

export interface TableRowProps extends BoxProps {
  children?: React.ReactNode
  index?: number
  isActive?: boolean
  striped?: boolean
}

const extractBackgroundClass = (
  isActive: boolean | undefined,
  striped: boolean | undefined,
  index: number | undefined
) => {
  if (isActive) return 'bg-secondary-shade-20'
  if (striped && index && index % 2) return 'bg-tertiary-main'
  return 'bg-transparent'
}

export const TableRow = ({ className, index, isActive, striped, ...props }: TableRowProps) => {
  const background = extractBackgroundClass(isActive, striped, index)

  return (
    <Box className={cn('border-divider', { 'border-b': !striped }, background, className)} component='tr' {...props} />
  )
}

const tableCellSizeClasses = {
  xSmall: 'text-sm py-0',
  small: 'text-sm py-1',
  medium: 'text-md py-2',
  large: 'text-md py-4'
}

export type TableSize = keyof typeof tableCellSizeClasses

export interface TableCellProps extends Omit<BoxProps, 'size'>, Pick<React.HTMLProps<HTMLTableCellElement>, 'colSpan'> {
  children?: React.ReactNode
  size?: TableSize
}

export const TableCell = ({ children, className, size = 'medium', ...props }: TableCellProps) => {
  return (
    <Box className={cn('px-4', tableCellSizeClasses[size], className)} component='td' {...props}>
      {children}
    </Box>
  )
}

export interface TableHeadCellProps extends Omit<BoxProps, 'size'> {
  Icon?: React.ReactNode
  children?: React.ReactNode
  size?: TableSize
  textProps?: TypographyProps
}

export const TableHeadCell = ({
  Icon = null,
  children,
  className,
  palette = 'secondary.80',
  size,
  textProps,
  ...props
}: TableHeadCellProps) => {
  return (
    <TableCell className={className} component='th' bgcolor={palette} colorContrast={palette} size={size} {...props}>
      <div className='flex w-full items-center text-left'>
        <Typography className='w-full whitespace-nowrap' component='div' variant='body1' {...textProps}>
          {children}
        </Typography>
        {Icon}
      </div>
    </TableCell>
  )
}
