import { type DotPathToType, getFromPath, setChildStateFromPath } from '@strise/europa'
import { i18n, type MessageDescriptor } from '@lingui/core'
import * as React from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { type ChipComponentType } from './FilterChips'
import { EditFilterArray } from './EditFilterArray'
import { type FromToLike } from './filterFromToUtils'
import { type FilterOptionsHook } from './filterHooks'
import { inputToNumberOrNull } from './filterUtils'
import { useDebounceValue } from 'usehooks-ts'
import { FilterFromToEdit } from '@components/Filter/FilterFromToEdit'
import { FilterFromToActive } from '@components/Filter/FilterFromToActive'
import { isObject } from 'lodash-es'
import { type Primitive } from '@strise/types'

export interface FilterSpec<T> {
  Component: React.ComponentType<{ setValue: SetStateFn<T>; value: T }>
  EditComponent: React.ComponentType<{ setValue: SetStateFn<T>; value: T }>
  path: string
  totalSelected: (value: T) => number
}

const itemToId = (item: Primitive | object): string => {
  if (!isObject(item)) return String(item)
  if ('id' in item) return String(item.id)
  return JSON.stringify(item)
}

// TODO - this composition is not very intuitive, should consider refactoring
export const filterArray = <I extends Primitive | object, T extends object>({
  ChipComponent,
  dataTrack,
  editLabel,
  enableSearch,
  path,
  titleMap,
  useOptions,
  ...props
}: {
  ChipComponent: ChipComponentType<I>
  dataTrack: string
  editLabel: MessageDescriptor
  enableSearch?: boolean
  path: DotPathToType<T, I[]>
  titleMap?: Record<string, MessageDescriptor>
  useOptions: FilterOptionsHook<I>
}): FilterSpec<T> => {
  return {
    Component: ({ setValue, value, ...componentProps }) => {
      const setSubValue = setChildStateFromPath(setValue, path)
      const handleDelete = (toDelete: I) => {
        const idToDelete = itemToId(toDelete)

        setSubValue((prev) => {
          return prev.filter((item) => {
            const itemId = itemToId(item)
            return itemId !== idToDelete
          })
        })
      }
      const subValue = getFromPath(value, path)
      if (!subValue) return null

      return (
        <>
          {subValue.map((v) => (
            <ChipComponent
              key={itemToId(v)}
              value={v}
              onDelete={() => handleDelete(v)}
              titleMap={titleMap}
              {...componentProps}
            />
          ))}
        </>
      )
    },
    EditComponent: ({ setValue, value }) => {
      const [inputValue, setInputValue] = React.useState('')
      const [debouncedInputValue] = useDebounceValue(inputValue, 500)

      const subValue = getFromPath(value, path) ?? []
      const {
        disableBackendSearch,
        items,
        loading,
        paginationProps,
        value: filterValue
      } = useOptions({ searchValue: debouncedInputValue, currentValues: subValue })

      return (
        <EditFilterArray
          value={filterValue}
          setValue={setChildStateFromPath(setValue, path)}
          inputValue={inputValue}
          setInputValue={setInputValue}
          options={items}
          loading={loading}
          dataTrack={dataTrack}
          editLabel={i18n._(editLabel)}
          search={enableSearch}
          paginationProps={paginationProps}
          disableBackendSearch={disableBackendSearch}
          {...props}
        />
      )
    },
    totalSelected: (value: T) => getFromPath(value, path)?.length ?? 0,
    path
  }
}

// TODO - this composition is not very intuitive, should consider refactoring
export const filterFromTo = <Typename extends string, T extends object>({
  dataTrack,
  editLabel,
  header,
  path,
  suffix
}: {
  dataTrack: string
  editLabel: MessageDescriptor
  header?: MessageDescriptor
  path: DotPathToType<T, FromToLike<Typename> | null | undefined>
  suffix: MessageDescriptor
}): FilterSpec<T> => {
  return {
    Component: ({ setValue, value, ...props }) => {
      const setSubValue = setChildStateFromPath(setValue, path)
      const handleDelete = () => {
        setSubValue((prevValue) => ({ from: null, to: null, __typename: prevValue?.__typename ?? ('' as Typename) }))
      }
      return <FilterFromToActive filter={getFromPath(value, path)} onDelete={handleDelete} suffix={suffix} {...props} />
    },
    EditComponent: ({ setValue, value }) => {
      const setSubValue = setChildStateFromPath(setValue, path)
      const saveHandler = (from: string, to: string) => {
        setSubValue((prevValue) => ({
          from: inputToNumberOrNull(from),
          to: inputToNumberOrNull(to),
          __typename: prevValue?.__typename ?? ('' as Typename)
        }))
      }

      const resetHandler = () => {
        setSubValue((prevValue) => ({ from: null, to: null, __typename: prevValue?.__typename ?? ('' as Typename) }))
      }
      return (
        <FilterFromToEdit
          filter={getFromPath(value, path)}
          onReset={resetHandler}
          onSave={saveHandler}
          dataTrack={dataTrack}
          title={i18n._(editLabel)}
          header={header ? i18n._(header) : undefined}
        />
      )
    },
    totalSelected: (value: T) => (getFromPath(value, path)?.to || getFromPath(value, path)?.from ? 1 : 0),
    path
  }
}
