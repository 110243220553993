import { cn, Divider, IconHeartFill, Typography, type TypographyProps } from '@strise/midgard'
import { useReactiveVar } from '@apollo/client/index.js'
import { type FinancialsMetaFragment, type FinancialsRowFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { financialFieldsMap } from '@state'
import { Dropdown, Table, TableCell, type TableCellProps, TableRow } from '@strise/system'
import { AdverseFlag, Empty } from '@strise/europa'
import { FinancialFieldRenderKind, FlagSeverity } from '@strise/types'
import { toReadableFigure, useHighlightedFinancials } from '@utils/financials'
import * as React from 'react'
import { DataSourceTooltip } from '../../../DataSourceTooltip'

const getBorderColorClass = (renderKind: FinancialFieldRenderKind) => {
  if (renderKind === FinancialFieldRenderKind.Sum) return 'border-tertiary-main'
  if (renderKind === FinancialFieldRenderKind.Calculation) return 'border-tertiary-shade-70'
  return 'border-transparent'
}

const getCellColorClass = (value: number | null, renderKind: FinancialFieldRenderKind) => {
  if (renderKind === FinancialFieldRenderKind.Default) return 'text-secondary-shade-70'
  if (renderKind === FinancialFieldRenderKind.Calculation && (value ?? 0) < 0) return 'text-accent-pink-shade-80'
  if (renderKind === FinancialFieldRenderKind.Calculation && (value ?? 0) > 0) return 'text-accent-green-shade-80'
  return 'text-text-primary'
}

export const FinancialTableRow = ({
  cellClassName,
  labels,
  row,
  rowIndex
}: {
  cellClassName?: string
  labels?: boolean
  row: FinancialsRowFragment
  rowIndex: number
}) => {
  const [highlighted] = useHighlightedFinancials('sidepanel')
  const financialFieldsMapState = useReactiveVar(financialFieldsMap)
  const highlightedFields = React.useMemo(() => highlighted.map(({ id }) => id), [highlighted])

  const fieldInfo = financialFieldsMapState[row.fieldInfo.id]

  if (!fieldInfo) return null

  const tableCellClasses = cn(
    'border-b px-0 pb-1',
    row.afterUnderline || rowIndex === 0 ? 'pt-3' : 'pt-1',
    getBorderColorClass(fieldInfo.renderKind)
  )

  const tableTextProps: Omit<TypographyProps, 'children'> = {
    variant: fieldInfo.renderKind === FinancialFieldRenderKind.Calculation ? 'subtitle2' : 'body2',
    className: 'text-sm py-0 leading-4'
  }

  const matchingInternationalFieldIds = fieldInfo.matchingInternationalFields.map((f) => f.id)
  const fieldIds = [row.fieldInfo.id, ...matchingInternationalFieldIds]
  const isFavorite = fieldIds.some((id) => highlightedFields.includes(id))

  return (
    <TableRow>
      {labels && (
        <TableCell
          className={cn('w-[300px]', tableCellClasses, getCellColorClass(null, fieldInfo.renderKind), cellClassName)}
        >
          <div className='flex items-center'>
            {isFavorite && (
              <DataSourceTooltip content={t`Favorite`} className='mx-auto'>
                <div>
                  <IconHeartFill className='mr-1 size-2.5 shrink-0 text-text-primary' />
                </div>
              </DataSourceTooltip>
            )}

            <Typography {...tableTextProps}>{fieldInfo.name}</Typography>
          </div>
        </TableCell>
      )}

      {row.values.map((value, cellIndex) => {
        const { className, ...props } = tableTextProps
        return (
          <TableCell
            className={cn('w-[72px] text-right', tableCellClasses, cellClassName)}
            key={`${rowIndex}-${cellIndex}`}
            numeric
          >
            <Typography {...props} className={cn(className, getCellColorClass(value, fieldInfo.renderKind))}>
              {toReadableFigure(value, fieldInfo)}
            </Typography>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export const FinancialTableHeader: React.FC<{
  meta: FinancialsMetaFragment[]
}> = ({ meta }) => {
  const tableHeadCellProps: Omit<TableCellProps, 'children'> = {
    component: 'th',
    className: 'p-0'
  }

  const tableHeadTextProps: Omit<TypographyProps, 'children'> = {
    variant: 'body2',
    className: 'font-regular'
  }

  return (
    <thead>
      <TableRow className='border-secondary-shade-70'>
        <TableCell {...tableHeadCellProps} className={cn('text-left', tableHeadCellProps.className)}>
          <Typography {...tableHeadTextProps}>
            <Trans>In {meta[0]?.currency ?? ''} ‘000</Trans>
          </Typography>
        </TableCell>
        {meta.map((m, index) => {
          const hasNotes = !!m.notes.length
          const notesContent = hasNotes && (
            <div className='max-w-[400px]'>
              <Typography className='mb-4' variant='subtitle1'>
                <Trans>Auditor notes</Trans>
              </Typography>
              <Typography className='mb-4 list-inside p-0' component='ul' variant='body2'>
                {m.notes.map((note, noteIndex) => (
                  <li key={noteIndex}>{note}</li>
                ))}
              </Typography>
              {(m.auditorComment || m.auditorClarification) && <Divider className='my-4' />}
              {m.auditorComment && (
                <Typography className='mb-2' variant='body2'>
                  {m.auditorComment}
                </Typography>
              )}

              {m.auditorClarification && <Typography variant='body2'>{m.auditorClarification}</Typography>}
            </div>
          )

          const yearText = (
            <div className='flex'>
              {notesContent && <AdverseFlag severity={FlagSeverity.Medium} className='mr-1 size-4' />}
              <Typography {...tableHeadTextProps} className='tabular-nums'>
                {m.periodTitle}
              </Typography>
            </div>
          )

          return (
            <TableCell key={index} {...tableHeadCellProps}>
              <Dropdown
                ToggleComponent={yearText}
                className='flex items-center justify-end text-left'
                trigger='hover'
                paperProps={{ className: 'p-4' }}
                popperProps={{ placement: 'bottom' }}
              >
                {notesContent}
              </Dropdown>
            </TableCell>
          )
        })}
      </TableRow>
    </thead>
  )
}

export const FinancialTableBody: React.FC<{
  financialRows: FinancialsRowFragment[]
}> = ({ financialRows }) => {
  return (
    <tbody>
      {financialRows.map((row, rowIndex) => {
        return <FinancialTableRow key={rowIndex} rowIndex={rowIndex} row={row} labels />
      })}
    </tbody>
  )
}

export const SidepanelFinancialsTable: React.FC<{
  financialRows: FinancialsRowFragment[]
  meta: FinancialsMetaFragment[]
}> = ({ financialRows, meta, ...props }) => {
  if (!financialRows.length || !meta.length) {
    return (
      <Empty className='h-[200px] items-center justify-center text-text-secondary' title={t`Financial data missing`} />
    )
  }

  return (
    <div className={cn('relative flex items-center p-1')} {...props}>
      <div className='shrink-0 grow'>
        <Table>
          <FinancialTableHeader meta={meta} />
          <FinancialTableBody financialRows={financialRows} />
        </Table>
      </div>
      {meta.length < 5 && (
        <div className='min-w-[100px] shrink px-4 text-center'>
          <Typography className='text-text-secondary' variant='body3'>
            <Trans>No financials recorded further back in time</Trans>
          </Typography>
        </div>
      )}
    </div>
  )
}
