import * as React from 'react'
import { Checkbox, cn, Tag, Typography, type TypographyProps } from '@strise/midgard'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { type FeatureFragment } from '../../graphqlTypes'
import { type FeaturesMap } from '../../utils/features'
import { AccordionV2 } from '@strise/system'
import { useBoolean } from 'usehooks-ts'
import { objectKeys } from '@strise/fika'
import { BorderedRow } from '../BorderedRow'
import { Trans } from '@lingui/macro'

export const FeatureCategory: React.FC<
  {
    DisabledFeatureComponent?: React.FC<{ feature: FeatureFragment }>
    categoryFeatures: FeatureFragment[]
    featuresState: FeaturesMap
    ignoreDisabled?: boolean
    selectAll?: boolean
    setFeaturesState: SetStateFn<FeaturesMap>
    showDescription?: boolean
    textProps?: TypographyProps
    title: string | null
  } & Omit<DivProps, 'title'>
> = ({
  DisabledFeatureComponent,
  categoryFeatures,
  className,
  featuresState,
  ignoreDisabled,
  selectAll,
  setFeaturesState,
  showDescription,
  textProps,
  title,
  ...props
}) => {
  const allSelected = categoryFeatures.every((feature) => featuresState[feature.kind])
  const { setValue: setOpen, toggle, value: open } = useBoolean()

  React.useEffect(() => {
    setOpen(!allSelected)
  }, [allSelected])

  const toggleCategory = (checked: boolean) => {
    setFeaturesState((prevFeatures) => ({
      ...Object.fromEntries(
        objectKeys(prevFeatures).map((feature) => [
          feature,
          categoryFeatures.some((f) => f.kind === feature) ? checked : prevFeatures[feature]
        ])
      )
    }))
    setOpen(!checked)
  }

  const getCheckboxTitle = (feature: FeatureFragment) => {
    const checkboxTitle = feature.requiresIntegration ? `${feature.name} (requires integration)` : feature.name

    if (feature.isBeta) {
      return (
        <div className='flex items-center gap-4'>
          {checkboxTitle}
          <Tag palette='pink'>
            <Trans>Beta</Trans>
          </Tag>
        </div>
      )
    }

    if (feature.isAdminOnly) {
      return (
        <div className='flex items-center gap-4'>
          {checkboxTitle}
          <Tag palette='orange'>
            <Trans>Admin Only</Trans>
          </Tag>
        </div>
      )
    }

    return checkboxTitle
  }

  const getCategoryCheckboxes = () =>
    categoryFeatures.map((feature, index) => {
      const isChecked = Boolean(featuresState[feature.kind])

      const disabled = !ignoreDisabled && !feature.isEditable

      const handleCheckboxChange = (checked: boolean) => {
        setFeaturesState((prevFeatures) => ({
          ...prevFeatures,
          [feature.kind]: checked
        }))
      }

      const disabledElement = DisabledFeatureComponent ? <DisabledFeatureComponent feature={feature} /> : undefined

      return (
        <BorderedRow key={index}>
          <Checkbox
            label={getCheckboxTitle(feature)}
            onCheckedChange={handleCheckboxChange}
            checked={isChecked}
            disabled={disabled}
            id={feature.kind}
            description={showDescription ? feature.description : null}
            data-id={feature.kind}
          />
          {disabled && disabledElement}
        </BorderedRow>
      )
    })

  return (
    <div className={cn('mb-4', className)} {...props}>
      {selectAll ? (
        <AccordionV2
          label={
            <Typography variant='aLabelBold' {...textProps}>
              {title}
            </Typography>
          }
          open={open}
          toggle={toggle}
          toggleLabel={<Checkbox id={title || 'no-title'} checked={allSelected} onCheckedChange={toggleCategory} />}
          toggleLabelProps={{ className: 'flex items-center gap-4' }}
          className={cn(open && 'pb-4')}
        >
          {getCategoryCheckboxes()}
        </AccordionV2>
      ) : (
        <div className='pb-4'>
          {title && (
            <Typography variant='aLabelBold' {...textProps}>
              {title}
            </Typography>
          )}
          {getCategoryCheckboxes()}
        </div>
      )}
    </div>
  )
}
