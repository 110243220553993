import { t } from '@lingui/macro'
import { type PepInfoFragment, type SanctionInfoFragment } from '@graphqlTypes'

export const extractPepInfoTitle = (pepInfo: PepInfoFragment | null | undefined, hasData: boolean) => {
  if (!hasData) return null

  if (!pepInfo?.pep && !pepInfo?.rca) return t`is not a PEP`

  if (pepInfo.pep) return t`is PEP due to roles:`

  if (pepInfo.rca) return t`is RCA due to relationships:`
}

export const extractSanctionInfoTitle = (sanctionInfo: SanctionInfoFragment | null | undefined) => {
  if (!sanctionInfo) return t`has unknown sanction status`

  if (!sanctionInfo.sanctionSources.length) return t`is not sanctioned`

  return t`is sanctioned`
}
