import { Checkbox, Chip, cn } from '@strise/midgard'
import * as React from 'react'
import { TableCell, type TableCellProps, TableRow } from '@strise/system'
import { type ColumnField, type CompaniesTableColumn, type EnabledColumns } from '../utils/companiesTableColumns'
import { renderTableRow } from '../utils/companiesTableRows'
import { type CompaniesListViewQuery, type ListViewCompanyFragment } from '@graphqlTypes'
import { t } from '@lingui/macro'
import { newCompaniesState } from '@state'
import { EntityNationFlag } from '@strise/europa'
import { NewChip } from '@components/NewChip'
import { objectKeys } from '@strise/fika'
import { companyInPortfolio } from '@components/CompanyStatus/companyStatusUtils'

const cellProps: { [key in ColumnField]?: TableCellProps } = {
  tags: {
    p: 0
  },
  assignees: {
    p: 0
  },
  status: {
    p: 0
  }
}

interface CompaniesTableRowProps {
  columns: CompaniesTableColumn[]
  company: ListViewCompanyFragment
  enabledColumns: EnabledColumns
  hasSelected: boolean
  isSelected: boolean
  newCompanies: string[]
  onSelect: () => void
}

const companiesTableRowIsEqual = (
  { company: prevCompany, hasSelected: prevHasSelected, isSelected: prevIsSelected }: CompaniesTableRowProps,
  { company: nextCompany, hasSelected: nextHasSelected, isSelected: nextIsSelected }: CompaniesTableRowProps
) =>
  prevIsSelected === nextIsSelected &&
  JSON.stringify(prevCompany) === JSON.stringify(nextCompany) &&
  prevHasSelected === nextHasSelected

const CompaniesTableRow = React.memo(
  ({ columns, company, enabledColumns, hasSelected, isSelected, newCompanies, onSelect }: CompaniesTableRowProps) => {
    const showCheckbox = isSelected || hasSelected
    const render = renderTableRow(company, enabledColumns)
    const removedCompany = !companyInPortfolio(company.statusV2.status)
    const newCompany = !removedCompany && newCompanies.includes(company.id)

    return (
      <TableRow
        className={`group/portfolio-row h-full ${
          // !Important to override TableRow bgcolor
          newCompany
            ? '!bg-accent-green-shade-5 hover:!bg-accent-green-shade-10'
            : '!bg-transparent hover:!bg-secondary-shade-5'
        }`}
      >
        <TableCell height={52} pr={0}>
          <div>
            {!showCheckbox && (
              <EntityNationFlag entity={company} className='mt-[6px] size-[22px] group-hover/portfolio-row:hidden' />
            )}
            <Checkbox
              className={`${showCheckbox ? 'block' : 'hidden'} group-hover/portfolio-row:block`}
              id={company.id}
              onCheckedChange={onSelect}
              checked={isSelected}
              data-track='Portfolio / Select company'
            />
          </div>
        </TableCell>

        {columns.map(({ field }, index) => {
          return (
            <TableCell key={index} position='relative' {...cellProps[field]}>
              {field === 'name' && (
                <div className={cn('flex min-w-0 items-center', { 'text-text-secondary': removedCompany })}>
                  {newCompany && <NewChip className='-ml-6 mr-2 inline-block' />}
                  {removedCompany && (
                    <Chip
                      className='-ml-6 mr-2 inline-block'
                      variant='contained'
                      palette='tertiary'
                      label={t`Removed`}
                    />
                  )}

                  {render[field]?.renderValue}
                </div>
              )}

              {field !== 'name' && render[field]?.renderValue}
            </TableCell>
          )
        })}
      </TableRow>
    )
  },
  companiesTableRowIsEqual
)

export const CompaniesTableBody: React.FC<{
  columns: CompaniesTableColumn[]
  companies: CompaniesListViewQuery['listView']['edges']
  enabledColumns: EnabledColumns
  onSelect: (id: string) => () => void
  selected: Record<string, boolean>
}> = ({ columns, companies, enabledColumns, onSelect, selected }) => {
  const newCompanies = newCompaniesState()

  return (
    <tbody className='bg-white' id='portfolio-view-table-body'>
      {companies.map(({ node: company }, index) => {
        const isSelected = selected[company.id] ?? false
        const hasSelected = objectKeys(selected).length > 0

        return (
          <CompaniesTableRow
            key={`${company.id}-${index}`}
            enabledColumns={enabledColumns}
            columns={columns}
            company={company}
            newCompanies={newCompanies}
            isSelected={isSelected}
            hasSelected={hasSelected}
            onSelect={onSelect(company.id)}
          />
        )
      })}
    </tbody>
  )
}
