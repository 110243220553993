import {
  Button,
  cn,
  Divider,
  IconButton,
  IconChevronLeftSmall,
  IconChevronRightSmall,
  IconSquare,
  Select,
  Typography,
  Combobox,
  type ComboboxItem
} from '@strise/midgard'
import { useCreateTeamActivityV2Mutation, useUpdateTeamActivityV2Mutation } from '@graphqlOperations'
import { type SimpleUserFragment, type BaseEntityLikeFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { refreshActivityViewState, refreshCompanyRemindersState } from '@state'
import { formatDate, RichTextEditor, toast, UserAvatar, useSortedAssignees } from '@strise/europa'
import { ButtonDropdown, type ButtonDropdownProps } from '@strise/system'
import {
  ApplicationSearchReturnType,
  ReminderStatus,
  TeamActivityKind,
  type TeamActivityPageInfoInput
} from '@strise/types'
import { type SetStateFn } from '@strise/react-utils'
import { type AssigneeEdge } from '@utils/assigneeUtils'
import { useTeamUsers } from '@utils/teamUsers'
import { parseISO, set as setDate } from 'date-fns'
import * as React from 'react'
import { useAssigneeMutations, useIsCompanyAssignee } from '../Assignee/assigneeHooks'
import { DatePicker } from '../DatePicker'
import { EntitySearchInput } from '../Entity/EntitySearchInput'
import { TeamActivityCompleteButton } from './TeamActivityCompleteButton'
import {
  useTeamActivityFormCompleteButtonStyle,
  useTeamActivityFormDateStyle,
  useTeamActivityKindPrefix
} from './teamActivityHooks'
import { getTeamActivityKindText, getTeamActivityNoteColors, type TeamActivityFormData } from './teamActivityUtils'
import { isDate } from 'lodash-es'
import { TestIDs } from '@utils/testIDs'
import { User } from '@components/Assignee/User'

const baseInputProps = {
  variant: 'contained' as const,
  palette: 'tertiary' as const,
  className: 'bg-secondary-shade-5'
}

const DateHeader: React.FC<{
  date: Date
  decreaseMonth: () => void
  handleDateNow: () => void
  increaseMonth: () => void
}> = ({ date, decreaseMonth, handleDateNow, increaseMonth }) => {
  const formattedDate = formatDate(date, { format: 'MMMM yyyy' })

  return (
    <div>
      <div className='flex items-center justify-between px-4 py-3 text-common-white'>
        <Typography variant='aLabelBold' data-id='activity-form-datepicker-header-text'>
          {formattedDate}
        </Typography>
        <div className='flex items-center'>
          <IconButton
            className='size-8 rounded-full p-0'
            variant='ghost'
            type='button'
            onClick={decreaseMonth}
            palette='tertiary'
            data-track='Team activities / Date / Decrease month'
            data-id='activity-form-datepicker-decrease-button'
          >
            <IconChevronLeftSmall size='xl' />
          </IconButton>
          <Button
            variant='outlined'
            color='common.white'
            type='button'
            className='border border-secondary-shade-80 text-secondary-contrastText hover:bg-secondary-shade-70'
            onClick={handleDateNow}
            data-track='Team activities / Date / Now'
          >
            <Trans>Now</Trans>
          </Button>
          <IconButton
            className='size-8 rounded-full p-0'
            variant='ghost'
            type='button'
            onClick={increaseMonth}
            palette='tertiary'
            data-track='Team activities / Date / Increase month'
            data-id='activity-form-datepicker-increase-button'
          >
            <IconChevronRightSmall size='xl' />
          </IconButton>
        </div>
      </div>
      <Divider className='bg-secondary-shade-80' />
    </div>
  )
}

interface DateButtonProps extends ButtonDropdownProps {
  isActivityCompleted: boolean
  onClick?: () => void
  value?: string
}

const DateButton = React.forwardRef<HTMLButtonElement, DateButtonProps>(
  ({ isActivityCompleted, onClick, value }, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { ReminderIcon, colorClassName, iconProps, text } = useTeamActivityFormDateStyle(value, isActivityCompleted)

    return (
      <ButtonDropdown
        data-id='activity-form-datepicker-dropdown-button'
        asChild
        ref={ref}
        onClick={onClick}
        indicator='hover'
        {...baseInputProps}
        className={cn(baseInputProps.className, 'h-12 w-full')}
      >
        <div>
          <Typography className={cn('flex items-center', colorClassName)} component='div' variant='aLabel'>
            {ReminderIcon && <ReminderIcon {...iconProps} className={cn('mr-1', iconProps.className)} />}
            {value ? text : t`Now`}
          </Typography>
        </div>
      </ButtonDropdown>
    )
  }
)

export const AssigneeSelectButtonText: React.FC<{
  assignee: SimpleUserFragment | undefined | null
}> = ({ assignee }) => {
  if (!assignee) return null

  return (
    <div className='flex size-full items-center'>
      <div className='flex items-center'>
        <UserAvatar user={assignee} className='size-8' />
      </div>
      <Typography className='ml-2 truncate' component='div' variant='aLabel'>
        {assignee.name}
      </Typography>
    </div>
  )
}

interface TeamActivityFormProps extends React.HTMLAttributes<HTMLFormElement> {
  assignees: AssigneeEdge[]
  autoFocus?: boolean
  company: BaseEntityLikeFragment | null | undefined
  formData: TeamActivityFormData
  isActivityModal?: boolean
  pageInfo: TeamActivityPageInfoInput
  resetForm: () => void
  setFormData: SetStateFn<TeamActivityFormData>
}

const TeamActivityKindOption = ({ kind }: { kind: TeamActivityKind }) => {
  return (
    <div className='flex w-full items-center'>
      <IconSquare className={cn('mr-2 size-4', getTeamActivityNoteColors(kind).typeColor)} />
      {getTeamActivityKindText(kind)}
    </div>
  )
}

export const TeamActivityForm = React.forwardRef<HTMLFormElement, TeamActivityFormProps>(
  (
    {
      assignees,
      autoFocus = false,
      className,
      company,
      formData,
      isActivityModal,
      pageInfo,
      resetForm,
      setFormData,
      ...props
    },
    ref: React.ForwardedRef<HTMLFormElement>
  ) => {
    const { teamUsers } = useTeamUsers()

    const [selectedCompanyFromSearch, setSelectedCompanyFromSearch] = React.useState<BaseEntityLikeFragment | null>(
      null
    )

    const selectedCompany = selectedCompanyFromSearch ?? company ?? null

    const isUpdate = !!formData.id

    const isActivityCompleted = formData.reminderStatus === ReminderStatus.Inactive

    const { assign, assignLoading } = useAssigneeMutations()
    const isCompanyAssignee = useIsCompanyAssignee(assignees, formData.assignee?.id)
    const assignee = formData.assignee

    const { backgroundColor, noteColor } = getTeamActivityNoteColors(formData.kind)
    const { titlePrefix } = useTeamActivityKindPrefix(formData.kind, formData.timestamp || new Date())
    const noteTitle = getTeamActivityKindText(formData.kind).toLowerCase()
    const {
      buttonText,
      className: activityClassName,
      isOverdue,
      palette,
      trackId
    } = useTeamActivityFormCompleteButtonStyle(formData.timestamp || new Date(), isActivityCompleted)

    React.useEffect(() => {
      if (formData.id) return
    }, [formData.id])

    React.useEffect(() => {
      if (!formData.timestamp || isUpdate) return

      setFormData((prevFormData) => ({
        ...prevFormData,
        reminderStatus: isOverdue ? ReminderStatus.Inactive : ReminderStatus.Active
      }))
    }, [formData.timestamp])

    React.useEffect(() => {
      // return if selectedCompanyFromSearch is not set, meaning we are in edit-mode
      if (!selectedCompany || !selectedCompanyFromSearch) return

      // reset contact person when company is changed
      setFormData((prevFormData) => ({
        ...prevFormData,
        company: selectedCompany
      }))
    }, [selectedCompany])

    const handleUpdateCompleted = () => {
      !isActivityCompleted && refreshActivityViewState(refreshActivityViewState() + 1)
      if (isCompanyAssignee) {
        toast.success(t`Team activity updated`)
      } else {
        toast.success(t`Updated activity and assigned ${assignee?.name} to the company`)
      }
      resetForm()
      refreshCompanyRemindersState(refreshCompanyRemindersState() + 1)
    }

    const handleCreateCompleted = () => {
      !isActivityCompleted && refreshActivityViewState(refreshActivityViewState() + 1)

      if (isCompanyAssignee) {
        toast.success(t`Team activity created`)
      } else {
        toast.success(t`Created activity and assigned ${assignee?.name} to the company`)
      }
      resetForm()
      refreshCompanyRemindersState(refreshCompanyRemindersState() + 1)
    }

    const [update, { loading: updateLoading }] = useUpdateTeamActivityV2Mutation({ onCompleted: handleUpdateCompleted })
    const [create, { loading: createLoading }] = useCreateTeamActivityV2Mutation({ onCompleted: handleCreateCompleted })
    const loading = createLoading || updateLoading || assignLoading

    const kindOptions = Object.values(TeamActivityKind).map((kind) => ({
      children: <TeamActivityKindOption kind={kind} />,
      value: kind
    }))

    const sortedAssignees = useSortedAssignees(teamUsers)
    const assigneeOptions = sortedAssignees.map(({ node }) => ({
      id: node.id,
      label: node.name,
      value: node,
      searchText: `${node.name} ${node.email ?? ''}`,
      renderNode: <User user={node} />
    }))

    const handleSelectChange =
      <T extends string | null>(key: string) =>
      (value: T | T[]) =>
        setFormData((prevFormData) => ({ ...prevFormData, [key]: value }))
    const handleInputChange = (value: string) => setFormData((prevFormData) => ({ ...prevFormData, note: value }))
    const handleDateChange = (date: Date) => {
      setFormData((prevFormData) => {
        const prevTimestamp = prevFormData.timestamp ? new Date(prevFormData.timestamp) : null

        // Set default hour to 9 if previous date is not selected
        const newTimestamp = prevTimestamp ? date : setDate(date, { hours: 9 })

        return { ...prevFormData, timestamp: newTimestamp.toISOString() }
      })
    }

    const handleAssigneeChange = (value: Array<ComboboxItem<SimpleUserFragment | null>>) => {
      setFormData((prevFormData) => ({ ...prevFormData, assignee: value[0]?.value ?? null }))
    }
    const handleReminderStatusChange = () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        reminderStatus:
          prevFormData.reminderStatus === ReminderStatus.Inactive ? ReminderStatus.Active : ReminderStatus.Inactive
      }))
    }

    const handleDateNow = () =>
      setFormData((prevFormData) => ({
        ...prevFormData,
        timestamp: null,
        reminderStatus: ReminderStatus.Active
      }))

    const handleCancel = (): void => resetForm()

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()

      if (!selectedCompany) return

      const input = {
        note: formData.note,
        kind: formData.kind,
        timestamp: formData.timestamp || new Date().toISOString(),
        assignee: formData.assignee?.id,
        reminderStatus: formData.reminderStatus
      }

      formData.assignee &&
        !isCompanyAssignee &&
        assign({ users: [formData.assignee.id], companies: [selectedCompany.id], assignees })

      await (isUpdate && formData.id
        ? update({ variables: { teamActivity: formData.id, input } })
        : create({ variables: { companyId: selectedCompany.id, input, page: pageInfo } }))
    }

    const user = assignee ?? formData.user

    return (
      <form
        className={cn('relative mb-4 border border-secondary-main', className)}
        ref={ref}
        onSubmit={handleSubmit}
        {...props}
      >
        <div className='rounded-md bg-secondary-shade-5'>
          <div className={cn('flex items-center px-3 py-2', backgroundColor)}>
            {user && <UserAvatar user={user} className='mr-3 size-8 bg-divider' />}
            <div className='flex'>
              <Typography>
                {`${assignee?.name ?? formData.user?.name ?? t`Unknown`} ${titlePrefix} `}
                <Typography className={cn('px-1', noteColor)} component='span'>
                  {noteTitle}
                </Typography>
              </Typography>
            </div>
          </div>
          <div className='relative flex items-center pl-3'>
            <Typography className='w-2/5 text-text-secondary' component='div' variant='aLabel'>
              <Trans>Assign to</Trans>
            </Typography>
            <Combobox
              variant='ghost'
              className='h-12 w-full'
              closeOnSelect
              singleSelect
              indicatorVariant='hover'
              data-id='activity-form-select-assignee-input-button'
              value={
                formData.assignee
                  ? [{ id: formData.assignee.id, label: formData.assignee.name, value: formData.assignee }]
                  : []
              }
              items={assigneeOptions}
              onChange={handleAssigneeChange}
              loading={loading}
              inputProps={{
                'data-id': 'activity-form-select-assignee-input-field'
              }}
              itemsWrapperProps={{
                'data-id': 'activity-form-select-assignee-options-container'
              }}
              data-track='Team activities / Assign to select'
              customSelectedItemsRenderer={<AssigneeSelectButtonText assignee={assignee} />}
            >
              <AssigneeSelectButtonText assignee={assignee} />
            </Combobox>
          </div>
          <div className='flex items-center pl-3'>
            <Typography className='w-2/5 text-text-secondary' component='div' variant='aLabel'>
              <Trans>Type</Trans>
            </Typography>
            <Select
              variant='ghost'
              value={formData.kind}
              options={kindOptions}
              onValueChange={handleSelectChange<TeamActivityKind>('kind')}
              triggerIconVisibility='hover'
              contentProps={{
                'data-id': 'activity-form-select-activity-kind-input-options-container'
              }}
              data-id='activity-form-select-activity-kind-input-button'
              data-track='Team activities / TeamActivityKind select'
              className={cn('h-12 w-full justify-between')}
            />
          </div>
          {isActivityModal && (
            <div className='flex items-center pl-3'>
              <Typography className='w-2/5 text-text-secondary' component='div' variant='aLabel'>
                <Trans>Company</Trans>{' '}
                <Typography className='text-semantic-danger-main' component='span'>
                  *
                </Typography>
              </Typography>
              <div className='w-full'>
                <EntitySearchInput
                  variant='ghost'
                  className='h-12 w-full'
                  inputProps={{
                    'data-id': TestIDs.Activities.searchEntityInput
                  }}
                  itemsWrapperProps={{
                    'data-id': TestIDs.Activities.searchEntityResults
                  }}
                  entityKindFilter={ApplicationSearchReturnType.Company}
                  selectedEntity={selectedCompanyFromSearch ?? null}
                  setSelectedEntity={setSelectedCompanyFromSearch}
                  dataTrack='Team activities / Company select'
                  data-id={TestIDs.Activities.searchEntityTrigger}
                />
              </div>
            </div>
          )}

          <div className='flex items-center pl-3'>
            <Typography className='w-2/5 text-text-secondary' component='div' variant='aLabel'>
              <Trans>Date</Trans>
            </Typography>
            <div className='flex w-full items-center justify-between'>
              <DatePicker
                selected={formData.timestamp ? parseISO(formData.timestamp) : null}
                onChange={(date: Date) => {
                  if (isDate(date)) {
                    handleDateChange(date)
                  }
                }}
                showTimeSelect
                shouldCloseOnSelect={false}
                dateFormat='yyyy-MM-dd HH:mm'
                wrapperClassName='w-full'
                customInput={<DateButton isActivityCompleted={isActivityCompleted} />}
                renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                  <DateHeader
                    date={date}
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    handleDateNow={handleDateNow}
                  />
                )}
              />

              <TeamActivityCompleteButton
                type='button'
                className={cn(className, 'mx-2 min-w-[120px] shrink-0', activityClassName)}
                trackId={trackId}
                palette={palette}
                onClick={handleReminderStatusChange}
                data-id='activity-form-reminder-button'
              >
                {buttonText}
              </TeamActivityCompleteButton>
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <RichTextEditor
            initValue={formData.note}
            placeholder={t`Note`}
            onChange={handleInputChange}
            autoFocus={autoFocus}
            data-id={TestIDs.Activities.activityInput}
          >
            {!isActivityModal && (
              <div className='ml-auto'>
                <Button
                  type='button'
                  variant='contained'
                  palette='tertiary'
                  className='mx-3'
                  onClick={handleCancel}
                  data-track='Team activities / Cancel'
                  data-id='activity-form-rich-text-editor-cancel-button'
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  palette='primary'
                  data-track={isUpdate ? 'Team activities / Update' : 'Team activities / Create'}
                  data-id='activity-form-rich-text-editor-create-button'
                  loading={loading}
                >
                  {isUpdate ? <Trans>Save</Trans> : <Trans>Create</Trans>}
                </Button>
              </div>
            )}
          </RichTextEditor>
        </div>
      </form>
    )
  }
)
