import { type BaseEntityLikeFragment } from '@graphqlTypes'
import { getBrowserGlobals } from '@strise/system'
import { useActiveContentView, useHomeView } from '@utils/viewsHooks'
import { type LinkProps } from '@strise/europa'
import { useLocation, useNavigate } from 'react-router-dom'
import { type SidepanelTab, entityPath } from '@strise/strise-utils'
import { type DivProps } from '@strise/react-utils'
import { type EntityIconProps } from '@components/EntityIcon'

export type EntityLinkProps = Omit<LinkProps, 'to'> & {
  disableOnNoAccess?: boolean
  iconWrapperProps?: Omit<EntityIconProps, 'entity'>
  maxLength?: number
  noFlags?: boolean
  noLifeStatus?: boolean
  noLink?: boolean
  noTooltip?: boolean
  sanctionsLink?: boolean
  sidepanelTab?: SidepanelTab
  transparent?: boolean
  withIcon?: boolean
  wrapperProps?: DivProps
}

export const useEntityUrl = (entity: BaseEntityLikeFragment) => {
  const location = useLocation()
  const activeContentView = useActiveContentView()
  const homeView = useHomeView()
  return entityPath(activeContentView ?? homeView, entity.id, location.search)
}

export const useNavigateEntity = (sidepanelTab?: SidepanelTab) => {
  const navigate = useNavigate()
  const homeView = useHomeView()
  const activeContentView = useActiveContentView()
  const location = useLocation()

  return (entityId: string, openInNewTab?: boolean) => {
    const entityUrl = entityPath(activeContentView ?? homeView, entityId, location.search, sidepanelTab)

    if (openInNewTab) {
      getBrowserGlobals()?.window.open(entityUrl, '_blank')
    } else {
      navigate(entityUrl)
    }
  }
}
