import { makeVar } from '@apollo/client/index.js'

import { useTokenMeta } from '@strise/europa'
import { track } from '@utils/tracking'
import { TrackedActivityKind } from '@strise/types'
import { getBrowserGlobals } from '@strise/system'
import * as React from 'react'

export const chatLoaded = makeVar<boolean>(false)
export const showFallback = makeVar<boolean>(false)
export const chatUnreadCount = makeVar<number>(0)

export const openChat = (msg?: string) => {
  if (chatLoaded()) {
    if (msg) {
      getBrowserGlobals()?.window.Intercom('showNewMessage', msg)
    } else {
      getBrowserGlobals()?.window.Intercom('showMessages')
    }
  } else {
    showFallback(true)
  }
}

export const useLoadChat = () => {
  const token = useTokenMeta()

  React.useEffect(() => {
    if (
      !getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.INTERCOM_APP_ID ||
      !getBrowserGlobals()?.window.Intercom ||
      !token
    )
      return

    getBrowserGlobals()?.window.Intercom('boot', {
      app_id: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.INTERCOM_APP_ID,
      api_base: 'https://api-iam.eu.intercom.io/',
      user_id: token.sub,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      user_hash: token['https://api.mito.ai/intercom'],
      hide_default_launcher: true
    })
    track(TrackedActivityKind.StriseInteraction, { action: 'Chat Loaded' })
    chatLoaded(true)

    getBrowserGlobals()?.window.Intercom('onUnreadCountChange', (unreadCount) => {
      chatUnreadCount(unreadCount)
    })
    getBrowserGlobals()?.window.Intercom('onShow', () => {
      chatUnreadCount(0)
    })
  }, [])
}
