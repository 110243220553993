import { t } from '@lingui/macro'
import { ellipsis } from '@strise/fika'
import { type IndustryFragment } from '@graphqlTypes'

export const extractNaceName = (industry: IndustryFragment, limit?: number) => {
  const name = `${(industry.name ?? t`Unknown`).replace('NACE ', '')}: ${industry.description ?? t`Unknown`}`

  const nameWithLimit = limit ? ellipsis(name, limit) : name

  return nameWithLimit
}
