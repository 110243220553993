import { useEffect, useState } from 'react'
import { useApolloClient, useReactiveVar } from '@apollo/client/index.js'
import COMPANIES_LIST_VIEW from '../../../../graphql/listView/queries/listView.graphql'
import COMPANY_LIST_VIEW_FRAGMENT from '../../../../graphql/listView/fragments/ListViewCompanyFragment.graphql'
import { toast, useDependencyState, useDownloadXLSX } from '@strise/europa'
import { type CompaniesListViewQuery, type CompaniesListViewQueryVariables } from '@graphqlTypes'
import { lastAddedCompanyState, newCompaniesState } from '@state'
import { type SetStateFn } from '@strise/react-utils'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { type CompaniesTableColumn, type EnabledColumns } from './companiesTableColumns'
import { renderTableRow } from './companiesTableRows'
import { t } from '@lingui/macro'
import { type SimpleCompanyConnection } from '@strise/types'
import { isFunction } from 'lodash-es'

export const useFetchNewlyAdded = ({
  setOffset,
  variables
}: {
  setOffset: SetStateFn<number>
  variables: CompaniesListViewQueryVariables
}) => {
  const client = useApolloClient()
  const lastAddedCompany = useReactiveVar(lastAddedCompanyState)
  const [companyToFetch, setCompanyToFetch] = useDependencyState<string | null>(lastAddedCompany, [lastAddedCompany])

  useEffect(() => {
    if (!companyToFetch) return

    const listViewCache = client.cache.readQuery<CompaniesListViewQuery>({
      query: COMPANIES_LIST_VIEW,
      variables
    })
    const listViewCacheEdges = listViewCache?.listView.edges ?? []
    const companyExistsInCache = listViewCacheEdges.find(({ node }) => node.id === companyToFetch)
    if (companyExistsInCache) return

    const fetchCompany = async () => {
      const { data } = await client.query<CompaniesListViewQuery, CompaniesListViewQueryVariables>({
        query: COMPANIES_LIST_VIEW,
        variables: {
          ...variables,
          page: undefined,
          settings: { companies: { companies: [companyToFetch] } }
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache'
      })
      const newCompany = data.listView.edges[0] ?? null
      if (!newCompany) return

      const companyCacheRef = client.cache.writeFragment({
        id: `SimpleCompany:${newCompany.node.id}`,
        fragment: COMPANY_LIST_VIEW_FRAGMENT,
        fragmentName: 'ListViewCompany',
        variables,
        data: newCompany.node
      })

      client.cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          // @ts-expect-error
          listView(curr: SimpleCompanyConnection | undefined) {
            if (!curr) return curr
            return {
              ...curr,
              edges: [
                {
                  node: companyCacheRef,
                  __typename: 'SimpleCompanyConnectionEdge'
                },
                ...curr.edges
              ]
            }
          }
        }
      })

      const newCompanies = newCompaniesState()
      newCompaniesState([newCompany.node.id, ...newCompanies])
      setOffset(0)
    }

    fetchCompany()
    setCompanyToFetch(null)
  }, [companyToFetch, variables])
}

export const useExportCSV = ({
  columns,
  enabledColumns,
  variables
}: {
  columns: CompaniesTableColumn[]
  enabledColumns: EnabledColumns
  variables: CompaniesListViewQueryVariables
}): [() => void, boolean] => {
  const downloadXLSX = useDownloadXLSX()
  const team = useTeam()
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)

  const exportFileToXLSX = (rows: object[]) => {
    try {
      downloadXLSX(rows, team.name)
    } catch (e) {
      console.error(e)
      toast.error(t`Error occured when downloading team portfolio`)
    }
  }

  const fetchCompanies = async () => {
    setLoading(true)
    const { data } = await client.query<CompaniesListViewQuery, CompaniesListViewQueryVariables>({
      query: COMPANIES_LIST_VIEW,
      variables: {
        ...variables,
        page: {
          limit: 50_000,
          offset: 0
        }
      },
      fetchPolicy: 'no-cache'
    })
    const companies = data.listView.edges
    if (!companies.length) return

    const csvRows = companies.map(({ node: company }) => {
      const row = renderTableRow(company, enabledColumns)

      const csvColumns = {
        organizationNumber: company.organizationNumber
      }

      return columns.reduce((obj, { field }) => {
        const rowField = row[field]
        if (rowField) {
          const { plainValue } = rowField
          return {
            ...obj,
            [field]: isFunction(plainValue) ? plainValue() : plainValue
          }
        }

        return obj
      }, csvColumns)
    })

    exportFileToXLSX(csvRows)
    setLoading(false)
  }

  const exportCompanies = () => {
    try {
      fetchCompanies()
    } catch (e) {}
  }

  return [exportCompanies, loading]
}
