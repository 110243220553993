import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useRef } from 'react'
import { copyTextToClipboard, toast, useIsSupervisor, usePersistentState } from '@strise/europa'
import { t } from '@lingui/macro'
import { omniEntityUrl } from '@strise/fika'
import { breakpoints } from '../theme'
import { useMediaQuery } from 'usehooks-ts'
import { STORAGE_KEYS } from '@constants'
import { getBrowserGlobals } from '@strise/system'
import { conflictIdQueryParamKey } from '@strise/strise-utils'

export const useIsMobile = () => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.md}px)`)
  return isMobile
}

export const useLocalStorageVersion = (version: string) => {
  const [currentVersion, setCurrentVersion] = usePersistentState<string>(STORAGE_KEYS.localStorageVersion, version)

  React.useEffect(() => {
    if (!currentVersion) {
      setCurrentVersion(version)
    } else if (currentVersion !== version) {
      getBrowserGlobals()?.window.localStorage.clear()
      getBrowserGlobals()?.window.location.reload()
    }
  }, [])
}

export const useIntersectionRef = (options: IntersectionObserverInit, cb: IntersectionObserverCallback) => {
  const observer = useRef<IntersectionObserver | null>(null)

  return useCallback(
    (node: Element | null) => {
      if (!getBrowserGlobals()?.window.IntersectionObserver) return

      if (!node) {
        if (observer.current) {
          observer.current.disconnect()
        }
        return
      }

      const browserGlobals = getBrowserGlobals()

      if (browserGlobals) {
        observer.current = new browserGlobals.window.IntersectionObserver(cb, options)
        observer.current.observe(node)
      }
    },
    [cb, options]
  )
}

interface UseCopyStringOpts {
  successMsg?: string
  trimSpaces?: boolean
}

export const copyString = (
  str: string,
  { successMsg = t`Copied to clipboard`, trimSpaces = false }: UseCopyStringOpts = {}
) => {
  return (event?: React.MouseEvent) => {
    if (event) event.preventDefault()

    const trimmed = trimSpaces ? str.replaceAll(/\s/g, '') : str

    copyTextToClipboard(trimmed)

    if (successMsg) {
      toast.success(successMsg)
    }
  }
}

export const useAltClickToOmni = (id: string) => {
  const isSupervisor = useIsSupervisor()
  return useCallback(
    (e: React.MouseEvent) => {
      if (isSupervisor && e.altKey) {
        e.preventDefault()
        getBrowserGlobals()?.window.open(omniEntityUrl(id), '_blank')
      }
    },
    [id]
  )
}

export const useConfirmationBeforeUnload = () => {
  React.useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      return (event.returnValue = 'Are you sure you want to exit?')
    }

    getBrowserGlobals()?.window.addEventListener('beforeunload', handler)
    return () => getBrowserGlobals()?.window.removeEventListener('beforeunload', handler)
  }, [])
}

export const useTriggerConflictModal = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (entityId: string, teamId?: string) => {
    const newParams = new URLSearchParams(searchParams)
    if (teamId) {
      newParams.set('team', teamId)
    }
    newParams.set(conflictIdQueryParamKey, entityId)
    setSearchParams(newParams)
    // We need to do a full reload when changing teams
    if (teamId) {
      getBrowserGlobals()?.window.location.reload()
    }
  }
}
