import { IconButton, LoaderRound, IconPin } from '@strise/midgard'
import * as React from 'react'
import { extractPlumbContainer, usePlumb } from '@lib/plumb'
import { createGlobalStyle } from 'styled-components'
import { OwnershipEntity } from './OwnershipEntity'
import dagre from '@dagrejs/dagre'
import { type BrowserJsPlumbInstance } from '@jsplumb/browser-ui'
import { BezierConnector } from '@jsplumb/connector-bezier'
import { FlowchartConnector } from '@jsplumb/connector-flowchart'
import { DotEndpoint, LabelOverlay, StraightConnector } from '@jsplumb/core'
import { AnchorLocations } from '@jsplumb/common'
import { useGraph } from '@lib/graph'
import { findOwnerRecursive, type TransformedOwner } from './ownershipChartUtils'
import { type CompanyBaseFragment, type GlobalCompanyBaseFragment } from '@graphqlTypes'
import { OWNERSHIP_CONNECTOR_Z_INDEX, OWNERSHIP_NODE_HEIGHT, OWNERSHIP_NODE_WIDTH } from './ownershipUtils'
import { useContext } from '@strise/europa'
import { OwnershipsContext } from '@components/Ownerships/OwnershipsContext/OwnershipsContext'
import { TestIDs } from '@utils/testIDs'

const OWNERSHIPS_HIGHLIGHTED_CLASS = 'ownerships__owner-highlighted'
const OWNERSHIPS_HIGHLIGHTED_PINNED_CLASS = 'ownerships__owner-highlighted--pinned'

const setGraphNodes = (
  graph: dagre.graphlib.Graph,
  rootEntity: CompanyBaseFragment | GlobalCompanyBaseFragment,
  entity: Pick<TransformedOwner, 'id' | 'owners'>,
  isExport: boolean
) => {
  if (!graph.hasNode(entity.id)) {
    graph.setNode(entity.id, {
      width: isExport ? OWNERSHIP_NODE_WIDTH.wide : OWNERSHIP_NODE_WIDTH.default,
      height: OWNERSHIP_NODE_HEIGHT
    })
  }

  const entityIsRoot = entity.id === rootEntity.id
  entity.owners.forEach((ownerEntity) => {
    const ownerEntityIsRoot = ownerEntity.id === rootEntity.id
    const selfOwnership = entity.id === ownerEntity.id
    // Don't draw a circular ownership line for owners, only for the root company. (if a company owns itself)
    if (!entityIsRoot && selfOwnership) return
    // Don't draw a line FROM the root company to one of its owners. (if the root company owns one of its owners)
    if (!entityIsRoot && ownerEntityIsRoot) return

    setGraphNodes(graph, rootEntity, ownerEntity, isExport)

    graph.setEdge(ownerEntity.id, entity.id, { label: ownerEntity.share })
  })
}

const LabelStyle = createGlobalStyle`
  #ownerships .jtk-overlay {
    background: #fff;
    z-index: 5;
    pointer-events: none;
    color: #5D5D5D;
    font-size: 10px;
    padding: 0 2px;
  }

  #ownerships .jtk-endpoint {
    z-index: ${OWNERSHIP_CONNECTOR_Z_INDEX};
    pointer-events: none;
  }

  #ownerships .jtk-connector path {
    pointer-events: none;
  }

  #ownerships .${OWNERSHIPS_HIGHLIGHTED_CLASS} {
    outline: 2px solid #2D62FA;
    border-color: transparent;
  }
`

const STRAIGHT_CONNECTOR = StraightConnector.type
const CURVED_CONNECTOR = {
  type: BezierConnector.type,
  options: { curviness: 30 }
}
const CIRCULAR_CONNECTOR = {
  type: FlowchartConnector.type,
  options: {
    cornerRadius: 100,
    stub: 15
  }
}

const ENDPOINT_STYLE = {
  fill: '#fff',
  strokeWidth: 1,
  stroke: '#A2A2A2'
}
const ROOT_ENDPOINT_STYLE = {
  fill: '#161717',
  strokeWidth: 1,
  stroke: '#161717'
}

const extractConnector = (sourceIsRoot: boolean, targetIsRoot: boolean, source: HTMLElement, target: HTMLElement) => {
  if (sourceIsRoot && targetIsRoot) return CIRCULAR_CONNECTOR
  if (source.dataset.x === target.dataset.x) return STRAIGHT_CONNECTOR
  return CURVED_CONNECTOR
}

const OwnerNodes: React.FC<{
  companyEl: HTMLElement
  entity: CompanyBaseFragment | GlobalCompanyBaseFragment
  graph: dagre.graphlib.Graph
  loaded: () => void
  plumb: BrowserJsPlumbInstance
}> = ({ companyEl, entity: rootEntity, graph, loaded, plumb }) => {
  const [, setNonce] = React.useState(0)
  const {
    editMode,
    isExport,
    owners: { hasData, loading, recursiveOwners, transformedNodes }
  } = useContext(OwnershipsContext)

  React.useEffect(() => {
    if (loading) return
    loaded()
  }, [loading])

  React.useEffect(() => {
    if (!recursiveOwners.length) {
      // delete plumb connections when there are no owners
      plumb.deleteEveryConnection()
      return
    }

    graph.nodes().forEach((node) => graph.removeNode(node))
    graph.edges().forEach((edge) => graph.removeEdge(edge.v, edge.w))
    setGraphNodes(
      graph,
      rootEntity,
      {
        id: rootEntity.id,
        owners: recursiveOwners
      },
      isExport
    )
    dagre.layout(graph)

    // Need to have a state update here to force a rerender
    setNonce((prev) => prev + 1)
  }, [recursiveOwners])

  const connectNodes = (el: HTMLDivElement | null) => {
    if (!el || loading) return

    plumb.deleteEveryConnection()

    graph.edges().forEach((edgeId) => {
      const sourceIsRoot = edgeId.v === rootEntity.id
      const targetIsRoot = edgeId.w === rootEntity.id

      const isCustomOwner =
        recursiveOwners.find((owner) => owner.id === edgeId.v && edgeId.w === rootEntity.id)?.customMeta
          ?.lastModifiedAt ?? false

      const edge = graph.edge(edgeId)
      const source = sourceIsRoot ? companyEl : el.querySelector<HTMLDivElement>(`[data-node-id="${edgeId.v}"]`)
      const target = targetIsRoot ? companyEl : el.querySelector<HTMLDivElement>(`[data-node-id="${edgeId.w}"]`)

      if (!source || !target) return

      plumb.connect({
        source,
        target,
        connector: extractConnector(sourceIsRoot, targetIsRoot, source, target),
        anchors: [sourceIsRoot ? AnchorLocations.Left : AnchorLocations.Bottom, AnchorLocations.Top],
        endpoint: {
          type: DotEndpoint.type,
          options: { radius: 2 }
        },
        endpointStyles: [
          sourceIsRoot ? ROOT_ENDPOINT_STYLE : ENDPOINT_STYLE,
          targetIsRoot ? ROOT_ENDPOINT_STYLE : ENDPOINT_STYLE
        ],

        overlays: [
          {
            type: LabelOverlay.type,
            options: {
              label: edge.label as string,
              location: 0.3
            }
          }
        ],

        paintStyle: isCustomOwner ? { stroke: '#A2A2A2', strokeWidth: 1, dashstyle: '2 4' } : undefined
      })
    })
  }

  const isPinnedOwner = (el: Element) => {
    return !editMode && el.classList.contains(OWNERSHIPS_HIGHLIGHTED_PINNED_CLASS)
  }

  const hasPinnedOwner = (): boolean => {
    return !editMode && Boolean(extractPlumbContainer(plumb)?.querySelector(`.${OWNERSHIPS_HIGHLIGHTED_PINNED_CLASS}`))
  }

  const unhighlightOwners = () => {
    plumb.select().setHover(false)
    extractPlumbContainer(plumb)
      ?.querySelectorAll(`.${OWNERSHIPS_HIGHLIGHTED_CLASS}`)
      .forEach((el: Element) => {
        el.classList.remove(OWNERSHIPS_HIGHLIGHTED_CLASS, OWNERSHIPS_HIGHLIGHTED_PINNED_CLASS)
      })
  }

  const highlightOwner = (ownerEl: Element, pin: boolean) => {
    const highlightLines = (source: Element, prevSource?: Element) => {
      plumb
        .select({ source })
        .each((connection) => {
          // Prevent infinite looping if companies are connected
          // to eachother for whatever reason.
          if (connection.target !== prevSource) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            highlightLines(connection.target, source)
          }
        })
        .setHover(true)
    }

    highlightLines(ownerEl)
    ownerEl.classList.add(OWNERSHIPS_HIGHLIGHTED_CLASS)
    if (pin) {
      ownerEl.classList.add(OWNERSHIPS_HIGHLIGHTED_PINNED_CLASS)
    }
  }

  const handleHover = (event: React.MouseEvent) => {
    const ownerEl = event.currentTarget

    if (event.type === 'mouseenter' && !hasPinnedOwner()) {
      highlightOwner(ownerEl, false)
    } else if (event.type === 'mouseout') {
      // eslint-disable-next-line functional/no-let
      let relatedTarget = event.relatedTarget as (Node & ParentNode) | null
      if (!relatedTarget) return
      while (relatedTarget) {
        if (relatedTarget === ownerEl) return
        relatedTarget = relatedTarget.parentNode
      }

      if (!hasPinnedOwner()) {
        unhighlightOwners()
      }
    }
  }

  const handlePin = (event: React.MouseEvent<HTMLButtonElement>) => {
    const btnEl = event.currentTarget
    btnEl.blur()

    const parentEl = btnEl.parentElement?.parentElement
    if (!parentEl) return

    if (isPinnedOwner(parentEl)) {
      unhighlightOwners()
    } else {
      if (hasPinnedOwner()) unhighlightOwners()
      highlightOwner(parentEl, true)
    }
  }

  if (loading && !hasData) {
    return (
      <div className='mb-4'>
        <LoaderRound size='sm' data-id={TestIDs.SidePanel.Ownerships.loader} />
      </div>
    )
  }

  if (!transformedNodes.length) {
    return null
  }

  const graphMeta = graph.graph()

  return (
    <>
      <LabelStyle />
      <div
        className='relative flex flex-col'
        ref={connectNodes}
        style={{ width: graphMeta.width, height: graphMeta.height }}
      >
        {transformedNodes.map((node, index) => {
          if (node.id === rootEntity.id) return null

          const graphNode = graph.node(node.id) as dagre.Node | undefined

          if (!graphNode) return null

          const nodeX = graphNode.x - graphNode.width / 2
          const nodeY = graphNode.y - graphNode.height / 2

          const matchingOwner = findOwnerRecursive(recursiveOwners, node.id)

          return (
            <OwnershipEntity
              key={`${node.id}-${index}`}
              entity={node.entity}
              fallbackName={node.name}
              ownership={node.indirectShare}
              uncertain={node.isUncertain}
              meta={node.beneficialOwnerMeta?.meta}
              isCustom={!!matchingOwner?.customMeta}
              customMeta={matchingOwner?.customMeta}
              subsidiariesCount
              position='absolute'
              top={nodeY}
              left={nodeX}
              data-x={nodeX}
              data-node-id={node.id}
              onMouseEnter={handleHover}
              onMouseOut={handleHover}
              sx={{
                '&:hover': {
                  '[data-pin]': {
                    display: 'flex'
                  }
                },
                [`&.${OWNERSHIPS_HIGHLIGHTED_PINNED_CLASS} [data-pin]`]: {
                  display: 'flex',
                  borderColor: 'transparent',
                  boxShadow: '0 0 0 2px #2D62FA'
                }
              }}
              data-id={TestIDs.SidePanel.Ownerships.node(node.name)}
            >
              {!editMode && (
                <IconButton
                  className='absolute right-[-12px] top-[-12px] hidden rounded-full border border-solid border-secondary-shade-10 bg-white'
                  variant='outlined'
                  palette='tertiary'
                  onClick={handlePin}
                  data-pin
                  data-track='Ownerships / Owner / Pin'
                >
                  <IconPin size='md' />
                </IconButton>
              )}
            </OwnershipEntity>
          )
        })}
      </div>
    </>
  )
}

export const Owners: React.FC<{
  companyEl: HTMLElement
  containerEl: HTMLElement
  entity: CompanyBaseFragment | GlobalCompanyBaseFragment
  loaded: () => void
}> = React.memo(({ companyEl, containerEl, entity, loaded }) => {
  const graph = useGraph({
    align: 'UL',
    ranksep: 80,
    nodesep: 25,
    marginx: 16
  })
  const plumb = usePlumb({
    container: containerEl,
    paintStyle: {
      strokeWidth: 1,
      stroke: '#A2A2A2'
    },
    hoverPaintStyle: {
      strokeWidth: 2,
      stroke: '#2D62FA'
    },
    connectionsDetachable: false,
    elementsDraggable: false
  })

  return (
    <>
      {plumb && graph && (
        <OwnerNodes entity={entity} plumb={plumb} graph={graph} companyEl={companyEl} loaded={loaded} />
      )}
    </>
  )
})
