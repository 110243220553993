import * as React from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { SortableTableBase } from './SortableTableBase'
import {
  type SortableTableCommonProps,
  type SortableTableRow,
  type SortableTableRowColumn,
  useTableFilter,
  useTablePagination,
  useTableSort
} from './sortableTableUtils'
import { type SortDirection } from '@strise/fika'

interface SortableTableProps<K extends string> extends SortableTableCommonProps<K> {
  fetchMore?: () => void
  initialSortDirection?: SortDirection
  initialSortField?: K
  multiSort?: boolean
  pageSize?: number
  resetPaginationNonce?: number
  selected?: Record<string, boolean>
  setSelected?: SetStateFn<Record<string, boolean>>
  totalRows?: number
}

export const SortableTable = <K extends string>({
  // Data
  className,
  columns,
  extraRows,

  // Initial state
  fetchMore,
  initialSortDirection = 'asc',
  initialSortField,

  // State
  loading,
  multiSort,

  // Dynamic shit
  pageSize: propsPageSize = 50,
  resetPaginationNonce,
  rows,
  totalRows: propsTotalRows,

  ...props
}: SortableTableProps<K>) => {
  const { filterString, filteredRows, setFilterString } = useTableFilter<SortableTableRow<K>, SortableTableRowColumn>(
    rows,
    'sortValue'
  )
  const handleFilterStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterString(e.target.value)
  }

  const { sortFields, sortedRows, updateSortField } = useTableSort<K>(
    filteredRows,
    'sortValue',
    initialSortField ? [{ field: initialSortField, direction: initialSortDirection }] : undefined,
    multiSort
  )

  const { currentPageRows, pageIndex, pageSize, setPageIndex, setPageSize } = useTablePagination<SortableTableRow<K>>(
    sortedRows,
    propsPageSize,
    [resetPaginationNonce, propsPageSize, filterString]
  )

  React.useEffect(() => {
    if (fetchMore && !loading && !currentPageRows.length) fetchMore()
  }, [currentPageRows.length])

  const totalRows = propsTotalRows ?? sortedRows.length

  return (
    <SortableTableBase
      className={className}
      rows={currentPageRows}
      columns={columns}
      totalRows={totalRows}
      extraRows={extraRows}
      loading={loading}
      pageIndex={pageIndex}
      pageSize={pageSize}
      filterString={filterString}
      setPageIndex={setPageIndex}
      setPageSize={setPageSize}
      handleFilterStringChange={handleFilterStringChange}
      sortFields={sortFields}
      updateSortField={updateSortField}
      {...props}
    />
  )
}
