import * as React from 'react'
import { Checkbox, type CheckboxProps } from '@strise/midgard'

const extractChecked = (selectedCount: number, totalCount: number) => {
  if (!selectedCount) return false
  if (selectedCount !== totalCount) return 'indeterminate' as const
  return true
}

export const SelectAllCheckbox = ({
  className,
  selectedCount,
  totalCount,
  ...props
}: { selectedCount: number; totalCount: number } & Omit<CheckboxProps, 'checked'>) => {
  const checked = extractChecked(selectedCount, totalCount)
  return <Checkbox className={className} checked={checked} {...props} />
}
