import * as React from 'react'
import { useState } from 'react'
import { EventsCard } from '../SidepanelCards/SidepanelEventsCard'
import { SidepanelReviewCard } from '../SidepanelCards/SidepanelReviewCard'
import { SidepanelGrow } from '../SidepanelGrow'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { SidepanelCompanyMetaCard } from '../SidepanelCards/SidepanelCompanyMetaCard'
import { SidepanelCompanyDetailsCard } from '../SidepanelCards/SidepanelCompanyDetailsCard'
import { Helmet } from 'react-helmet'
import { SidepanelCompanyRegistersCard } from '../SidepanelCards/SidepanelRegistersCard'
import { type TabRefs } from '../SidepanelTabs/sidepanelTabsUtils'
import { SidepanelCompanyCreditReportCard } from '../SidepanelCards/CreditReport/SidepanelCompanyCreditReportCard'
import { SidepanelSanctionsCard } from '../SidepanelCards/SidepanelSanctionsCard'
import { SidepanelRiskSummaryCard } from '../SidepanelCards/SidepanelRiskSummaryCard'
import { SidepanelOwnershipsCard } from '../SidepanelCards/SidepanelOwnershipsCard'
import { SidepanelFinancialsCard } from '../SidepanelCards/FinancialsCard/SidepanelFinancialsCard'
import { SidepanelBranchesCard } from '../SidepanelCards/SidepanelCompanyBranchesCard'
import { SidepanelCompanyOwnersCard } from '../SidepanelCards/SidepanelCompanyOwnersCard'
import { SidepanelCompanyHeaderCard } from '../SidepanelCards/SidepanelCompanyHeaderCard'
import { SidepanelRolesCard } from '../SidepanelCards/SidepanelRolesCard'
import { SidepanelSimilarEntities } from '../SidepanelCards/SidepanelSimilarEntities'
import { SidepanelCompanyCollateralCard } from '../SidepanelCards/SidepanelCompanyCollateralCard'
import { SidepanelRealEstateCard } from '@components/Sidepanel/SidepanelCards/CreditReport/SidepanelRealEstateCard'
import { SidepanelCCJCard } from '../SidepanelCards/CCJReport/SidepanelCCJCard'
import { type SidepanelCompanyFragment } from '@graphqlTypes'
import { SidepanelTab } from '@strise/strise-utils'
import { hasEntityAccess, isEntityActive } from '@utils/entity'
import { SidepanelCounterpartiesCard } from '../SidepanelCards/SidepanelCounterpartiesCard'

export const SidepanelCompanyLayout: React.FC<{
  company: SidepanelCompanyFragment
  tabRefs: TabRefs
}> = ({ company, tabRefs }) => {
  const features = useCurrentUserFeatures()
  const isActive = isEntityActive(company)
  const [showOriginal, setShowOriginal] = useState(false)
  const ownershipsRef = React.useRef<HTMLDivElement | null>(null)

  const hasCreditReport = !!company.legalForm?.hasCreditReport
  const hasCCJReport = hasCreditReport && company.primaryCountry?.isoAlpha2Code === 'GB' && features.CREDIT_DATA

  return (
    <>
      <Helmet>
        <title>{company.name}</title>
      </Helmet>
      <div className={isActive ? undefined : 'text-text-secondary'}>
        <SidepanelCompanyHeaderCard company={company} ref={tabRefs[SidepanelTab.Company]} />
        {!company.isGlobalCompany && <SidepanelSimilarEntities />}
        {!company.isGlobalCompany && <SidepanelCompanyMetaCard company={company} ref={tabRefs[SidepanelTab.Company]} />}
      </div>

      {hasEntityAccess(company) && (
        <>
          {company.riskSummary && (
            <SidepanelRiskSummaryCard ref={tabRefs[SidepanelTab.RiskSummary]} riskSummary={company.riskSummary} />
          )}
          <SidepanelCompanyDetailsCard company={company} />
          {!!company.branches.edges.length && <SidepanelBranchesCard company={company} />}

          <SidepanelSanctionsCard entity={company} />
          {!company.isGlobalCompany && <SidepanelCompanyRegistersCard company={company} />}
          {features.REVIEW && !company.isGlobalCompany && <SidepanelReviewCard ownershipsElement={ownershipsRef} />}
          <SidepanelRolesCard entity={company} ref={tabRefs[SidepanelTab.People]} sidepanelTab={SidepanelTab.People} />
          {features.COUNTERPARTIES && (
            <SidepanelCounterpartiesCard
              entity={company}
              ref={tabRefs[SidepanelTab.Counterparties]}
              sidepanelTab={SidepanelTab.Counterparties}
            />
          )}

          {company.showOwnerChart && <SidepanelCompanyOwnersCard company={company} showOriginal={showOriginal} />}

          <SidepanelOwnershipsCard
            ref={tabRefs[SidepanelTab.Ownership]}
            sidepanelTab={SidepanelTab.Ownership}
            chartRef={ownershipsRef}
            entity={company}
            showOriginal={showOriginal}
            setShowOriginal={setShowOriginal}
          />

          {features.COLLATERAL_DATA_LOSOREREGISTERET && !features.CREDIT_DATA && (
            <SidepanelCompanyCollateralCard company={company} />
          )}

          {hasCreditReport && <SidepanelCompanyCreditReportCard company={company} ref={tabRefs[SidepanelTab.Credit]} />}
          {hasCCJReport && <SidepanelCCJCard company={company} ref={tabRefs[SidepanelTab.CCJs]} />}
          {features.REAL_ESTATE_DATA_KARTVERKET && <SidepanelRealEstateCard company={company} />}
          {!company.isGlobalCompany && (
            <SidepanelFinancialsCard company={company} ref={tabRefs[SidepanelTab.Financials]} />
          )}

          {!company.isGlobalCompany && <EventsCard ref={tabRefs[SidepanelTab.Events]} />}
          {!company.isGlobalCompany && <SidepanelGrow ref={tabRefs[SidepanelTab.Grow]} />}
        </>
      )}
    </>
  )
}
