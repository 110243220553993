import * as React from 'react'
import type { GlobalPersonBaseFragment, PersonBaseFragment } from '@graphqlTypes'
import { NationFlag, formatDate } from '@strise/europa'
import { IconBirthdayCake, Typography } from '@strise/midgard'
import { extractAddress } from '@utils/address'

interface PersonReviewInfoProps {
  person: PersonBaseFragment | GlobalPersonBaseFragment
}

export const PersonReviewInfo = ({ person }: PersonReviewInfoProps) => {
  return (
    <Typography variant='aLabelSmall' className='flex items-center gap-2 text-text-secondary'>
      {person.address && (
        <span className='flex items-center gap-2'>
          <NationFlag className='size-4' countryIsoCode={person.primaryCountry?.isoAlpha2Code} />
          {extractAddress(person.address)}
        </span>
      )}
      {person.birthDate && (
        <span className='flex items-center gap-2 whitespace-nowrap'>
          <IconBirthdayCake size='md' className='shrink-0' />
          {formatDate(person.birthDate)}
        </span>
      )}
    </Typography>
  )
}
