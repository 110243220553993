import * as React from 'react'
import { useDisplayLanguage } from '@contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { EntityIdLink } from '@components/EntityLink/EntityIdLink'
import { EventLink } from '@components/EventLink'
import { EuropaContextProvider } from '@strise/europa'

export const InitEuropa = ({ children }: { children: React.ReactNode }) => {
  const language = useDisplayLanguage()

  const value = React.useMemo(
    () => ({
      EntityLink,
      EntityIdLink,
      language,
      EventLink
    }),
    [language]
  )

  return <EuropaContextProvider value={value}>{children}</EuropaContextProvider>
}
