import * as React from 'react'
import { type TableCellProps, type TableHeadCellProps, type TableProps, type TableSize } from '@strise/system'
import { filterNullishValues, objectEntries, objectKeys, reduceFlatten, type SortDirection } from '@strise/fika'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { type Primitive } from '@strise/types'
import { omit, orderBy } from 'lodash-es'
import { Checkbox } from '@strise/midgard'
import { SelectAllCheckbox } from './SelectAllCheckbox'

type RowValue = string | number | null | undefined

export type MultiSelectTableRow = Record<string, { sortValue: RowValue }>

export interface SortableTableColumn<K> {
  cellProps?: TableCellProps
  field: K
  headCellProps?: TableHeadCellProps
  hide?: boolean
  sortField?: string
  sortable: boolean
  title?: React.ReactNode
}

export type RenderValue = React.ReactElement | string | number | null | undefined
export type SortValue = string | number | null | undefined

export interface SortableTableRowColumn {
  renderValue: RenderValue
  sortValue?: SortValue
}

export type SortableTableRow<K extends string> = Record<K, SortableTableRowColumn> & { key?: string }

export interface SortableTableCommonProps<K extends string> extends Omit<TableProps, 'title'> {
  checkActive?: (row: SortableTableRow<K>) => boolean
  columns: Array<SortableTableColumn<K>>
  extraRows?: Record<string, RenderValue>
  filter?: boolean
  filterString?: string
  getRowId: (index: number) => string | undefined
  handleFilterStringChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  headerPalette?: string
  headerProps?: DivProps
  inputPlaceholder?: string
  loading?: boolean
  mutationLoading?: boolean
  rows: Array<SortableTableRow<K>>
  selected?: Record<string, boolean>
  setPageSize?: (ps: number) => void
  setSelected?: SetStateFn<Record<string, boolean>>
  size?: TableSize
  striped?: boolean
  tableHeadProps?: Omit<DivProps, 'size'>
  wrapperProps?: DivProps
}

export const filterColumns = <K extends string>(
  columns: Array<SortableTableColumn<K>>
): Array<SortableTableColumn<K>> => {
  const filteredColumns = columns.filter((column) => !column.hide)
  return filteredColumns
}

export const enrichColumnsWithCheckbox = <K extends string>(
  columns: Array<SortableTableColumn<K>>,
  handleSelectAll: (checked: boolean) => void,
  totalRows: number,
  selected?: Record<string, boolean>
): Array<SortableTableColumn<K>> => {
  if (!selected) return columns

  const selectedCount = objectKeys(selected).length
  return [
    {
      title: (
        <SelectAllCheckbox
          id='select-all'
          palette='tertiary'
          onCheckedChange={handleSelectAll}
          selectedCount={selectedCount}
          totalCount={totalRows}
        />
      ),

      field: 'checkbox' as K,
      sortable: false,
      headCellProps: {
        width: 58
      }
    },
    ...columns.filter((column) => column.field !== 'checkbox')
  ]
}

export const enrichRowsWithCheckbox = <K extends string>(
  rows: Array<SortableTableRow<K>>,
  handleSelect: (id: string) => () => void,
  selected?: Record<string, boolean>
): Array<SortableTableRow<K>> => {
  if (!selected) return rows

  return rows.map((row) => {
    const id = row['id' as K].sortValue as string
    return [
      [
        'checkbox',
        {
          renderValue: <Checkbox id={`${id}-checkbox`} onCheckedChange={handleSelect(id)} checked={selected[id]} />
        }
      ],
      ...objectEntries(row)
    ]
      .map(([key, value]) => ({ [key as K]: value }))
      .reduce(reduceFlatten, {}) as SortableTableRow<K>
  })
}

export const useHandleSelect = (setSelected?: SetStateFn<Record<string, boolean>>) => {
  return React.useCallback(
    (id: string) => () => {
      if (!setSelected) {
        console.error('setSelected prop missing in SortableTable')
        return
      }

      setSelected((prevSelected) => {
        if (prevSelected[id]) {
          return omit(prevSelected, id)
        }

        const newSelected = { ...prevSelected, [id]: true }
        return newSelected
      })
    },
    [setSelected]
  )
}

export const useSelectAll = (
  rows: MultiSelectTableRow[],
  selected?: Record<string, boolean>,
  setSelected?: SetStateFn<Record<string, boolean>>
) => {
  return React.useCallback(() => {
    if (!selected) {
      console.error('selected prop missing')
      return
    }

    if (!setSelected) {
      console.error('setSelected prop missing')
      return
    }

    const selectedPageLength = rows.filter((row) => {
      if (!row.id?.sortValue) return false

      return selected[row.id.sortValue]
    }).length

    setSelected((prevSelected) => {
      if (selectedPageLength !== rows.length) {
        const newSelected = rows
          .map((row) => {
            if (!row.id?.sortValue) return {}

            return { [row.id.sortValue]: true }
          })
          .reduce(reduceFlatten, {})

        return { ...prevSelected, ...newSelected }
      }

      const keysToDelete = filterNullishValues(rows.map((row) => row.id?.sortValue?.toString()))

      return omit(prevSelected, keysToDelete)
    })
  }, [rows, selected, setSelected])
}

export const useSelectTableRowsWithState = <K extends string>(
  rows: Array<SortableTableRow<K>>,
  selected?: Record<string, boolean>,
  setSelected?: SetStateFn<Record<string, boolean>>
) => {
  const handleSelect = useHandleSelect(setSelected)
  const selectAll = useSelectAll(rows as MultiSelectTableRow[], selected, setSelected)
  return { handleSelect, selectAll }
}
export const useSelectTableRows = (rows: MultiSelectTableRow[], filter?: object) => {
  const [selected, setSelected] = React.useState<Record<string, boolean>>({})
  const handleSelect = useHandleSelect(setSelected)
  const selectAll = useSelectAll(rows, selected, setSelected)
  const unselectAll = React.useCallback(() => setSelected({}), [])

  React.useEffect(() => {
    unselectAll()
  }, [JSON.stringify(filter)])

  return { selected, setSelected, handleSelect, selectAll, unselectAll }
}

export const useTablePagination = <T extends object>(
  rows: T[],
  initPageSize: number,
  resetDependencies: Primitive[]
) => {
  const [pageSize, setPageSize] = React.useState(initPageSize)
  const [pageIndex, setPageIndex] = React.useState(0)

  React.useEffect(() => {
    setPageIndex(0)
    setPageSize(initPageSize)
  }, resetDependencies)

  React.useEffect(() => {
    setPageIndex(0)
  }, [pageSize])

  const currentPageRows = React.useMemo(() => {
    return rows.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
  }, [rows, pageIndex, pageSize])

  const hasNextPage = rows.length > (pageIndex + 1) * pageSize
  const hasPrevPage = pageIndex > 0

  return {
    currentPageRows,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    hasNextPage,
    hasPrevPage
  }
}

export const useTableFilter = <T extends object, K extends object>(rows: T[], filterKey: keyof K) => {
  const [filterString, setFilterString] = React.useState('')

  const filteredRows = React.useMemo(() => {
    if (!filterString) return rows
    const loweredFilterString = filterString.toLowerCase()

    return rows.filter((row) => {
      // @ts-expect-error
      return Object.values<K>(row)
        .map((column) => {
          return String(column[filterKey]).toLowerCase().includes(loweredFilterString)
        })
        .reduce((acc, cur) => acc || cur, false)
    })
  }, [rows, filterString])

  return { filteredRows, filterString, setFilterString }
}

export interface SortField<T extends string> {
  direction: SortDirection
  field: T
}

const extractDirection = <T extends string>(
  currentSort: Array<SortField<T>>,
  existingIndex: number,
  lastIndex: number
): SortDirection => {
  if (existingIndex === -1 || existingIndex !== lastIndex) {
    return 'asc'
  }

  if (currentSort[existingIndex]?.direction === 'asc') {
    return 'desc'
  }

  return false
}

export const useTableSortState = <K extends string>(
  defaultSortFields: Array<SortField<K>>,
  multiSort = false
): [Array<SortField<K>>, (field: K, multiSort?: boolean) => () => void] => {
  const [sortFields, setSortFields] = React.useState<Array<SortField<K>>>(defaultSortFields)

  const updateSortField = React.useCallback(
    (field: K) => () => {
      setSortFields((currentSort) => {
        const existingIndex = currentSort.findIndex((sort) => sort.field === field)
        const lastIndex = currentSort.length - 1
        const withoutExisting = currentSort.filter((sort) => sort.field !== field)

        const direction = extractDirection(currentSort, existingIndex, lastIndex)

        return [...(multiSort ? withoutExisting : []), ...(direction ? [{ field, direction }] : [])]
      })
    },
    []
  )

  return [sortFields, updateSortField]
}

export const useTableSort = <K extends string>(
  rows: Array<SortableTableRow<K>>,
  sortKey: string,
  defaultSortFields: Array<SortField<K>> | undefined,
  multiSort = false
) => {
  const [sortFields, updateSortField] = useTableSortState(defaultSortFields ?? [], multiSort)

  const sortedRows = React.useMemo(() => {
    if (!sortFields.length) return rows

    return orderBy(
      rows,
      sortFields.map((sortField) => `${sortField.field}.${sortKey}`),
      sortFields.map((sortField) => sortField.direction)
    )
  }, [rows, sortFields])

  return { sortedRows, sortFields, updateSortField }
}
