import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagFi = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagFI_svg__a)'>
      <path
        fill='#EEE'
        d='M1.065 1h5.741l1.517.718L9.673 1h13.392v9.565l-.97 1.362.97 1.508V23H9.672l-1.375-.85-1.49.85H1.064v-9.565l.95-1.43-.95-1.44V1Z'
      />
      <path fill='#0052B4' d='M6.806 1v9.565h-5.74v2.87h5.74V23h2.866v-9.565h13.393v-2.87H9.672V1H6.806Z' />
    </g>
    <defs>
      <clipPath id='FlagFI_svg__a'>
        <rect width={22} height={22} x={1.065} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagFi.displayName = 'FlagFi'
