import { defineMessage } from '@lingui/macro'
import { isNil } from 'lodash-es'

export interface FromToLike<Typename extends string> {
  __typename: Typename
  from?: number | null | undefined
  to?: number | null | undefined
}

export const activeText = (from: number | null, to: number | null) => {
  const text = [defineMessage({ message: `From ${from ?? 0}` })]
  if (to !== null) return [...text, defineMessage({ message: `to ${to}` })]
  return text
}

export const valueToString = (value: number | null | undefined) => {
  if (isNil(value)) return ''
  return String(value)
}
