import * as React from 'react'
import {
  API_ENV_TO_API_URL,
  ApolloClientProvider as EuropaClientProvider,
  omitTypenameLink,
  useAuthLink,
  useHttpLink
} from '@strise/europa'
import { useErrorLink } from './apolloLinks'
import { apolloClient } from './apolloClient'
import { from, useReactiveVar } from '@apollo/client/index.js'
import { apiEnvState, spoofUser, team } from '@state'
import { setContext } from '@apollo/client/link/context/index.js'
import { v4 as uuidV4 } from 'uuid'

const requestIDLink = setContext((_, { headers }) => {
  const requestId = uuidV4()
  return { headers: { ...(headers as Record<string, string>), 'X-Request-ID': requestId } }
})

const useLink = () => {
  const teamId = useReactiveVar(team)
  const apiEnv = apiEnvState().get()
  const apiUrl =
    apiEnv in API_ENV_TO_API_URL
      ? API_ENV_TO_API_URL[apiEnv as keyof typeof API_ENV_TO_API_URL]
      : // Skaffold API url
        `https://${apiEnv}.dev.graphql.strise.ai/internal/graphql`
  const httpLink = useHttpLink(apiUrl)
  const errorLink = useErrorLink()
  const authLink = useAuthLink({ spoofId: spoofUser(), teamId })

  const link = React.useMemo(
    () => from([omitTypenameLink, errorLink, authLink, requestIDLink, httpLink]),
    [errorLink, authLink, requestIDLink, httpLink]
  )

  return link
}

export const ApolloClientContextProvider = ({ children }: { children: React.ReactNode }) => {
  const link = useLink()

  return (
    <EuropaClientProvider client={apolloClient} link={link}>
      {children}
    </EuropaClientProvider>
  )
}
