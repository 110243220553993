import * as React from 'react'
import { Img, type ImgProps } from '@strise/europa'
import { t } from '@lingui/macro'

export const FavIcon: React.FC<{ publisher: string } & Omit<ImgProps, 'src'>> = ({ publisher, ...props }) => {
  const [display, setDisplay] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    // The api returns a default "globe" favicon
    // if none was found, and that one is 16px
    // so make sure the returned icon is 32px.
    setDisplay(e.currentTarget.naturalWidth === 32)
    setLoading(false)
  }

  if (!loading && !display) return null

  return (
    <Img
      src={`https://s2.googleusercontent.com/s2/favicons?domain=${publisher}&sz=32`}
      onLoad={handleLoad}
      loading='lazy'
      className='max-w-[20px]'
      alt={`${t`Favicon for`} ${publisher}`}
      {...props}
    />
  )
}
