import { cloneDeep, intersection, isArray, isEqual, isObject } from 'lodash-es'

export const findInObject = <T extends unknown>(
  obj: Record<string, unknown>,
  key: string,
  value: T
): Record<string, unknown> | null => {
  const dfs = (subObj: Record<string, unknown>, depth: number = 0): [number, Record<string, unknown> | null] => {
    for (const objKey in subObj) {
      if (objKey === key && subObj[objKey] === value) return [depth, subObj]
    }

    // Find the child with the minimum depth
    let candidate: [number, Record<string, any> | null] = [Number.POSITIVE_INFINITY, null]
    for (const objKey in subObj) {
      if (subObj[objKey] !== null && isObject(subObj[objKey])) {
        const [childDepth, childObject] = dfs(subObj[objKey] as Record<string, unknown>, depth + 1)

        // If the depth of the child we found is better, then it's the best candidate
        if (childDepth < candidate[0]) candidate = [childDepth, childObject]
      }
    }
    return candidate
  }
  return dfs(obj)[1]
}

const hasUpdatesRecursive = (validValues: Record<string, string[]>, obj: Record<string, any>): boolean => {
  const keys = objectKeys(validValues)

  for (const key in obj) {
    const value: unknown = obj[key]

    if (keys.includes(key) && isArray(value)) {
      const newValue = intersection(value, validValues[key])
      if (!isEqual(value, newValue)) {
        obj[key] = newValue
        return true
      }
    } else if (isObject(value)) {
      return hasUpdatesRecursive(validValues, value)
    }
  }

  return false
}

export const validateArraysInObject = <T extends object>(
  validateObj: T,
  validValues: Record<string, string[]>
): T | false => {
  const clonedSettings = cloneDeep(validateObj)
  const hasUpdates = hasUpdatesRecursive(validValues, clonedSettings)

  if (hasUpdates) return clonedSettings
  return false
}

export const objectKeys = <T extends object>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as Array<keyof T>
}

export const objectEntries = <T extends object, K extends keyof T>(obj: T): Array<[keyof T, T[K]]> => {
  return Object.entries(obj) as Array<[keyof T, T[K]]>
}
