import { type BaseEntityLikeFragment } from '@graphqlTypes'
import { ReactRouterLink } from '@strise/europa'
import { toTitleCase } from '@strise/fika'
import { cn, ExternalLink, type LinkProps as UILinkProps, Typography } from '@strise/midgard'
import { eventPath } from '@strise/strise-utils'
import * as React from 'react'
import { type EntityLinkProps } from './EntityLink/entityLinkUtils'
import { FavIcon } from './FavIcon'
import { useActiveContentView, useHomeView } from '@utils/viewsHooks'
import { useLocation } from 'react-router-dom'

export const EventLink: React.FC<
  {
    entityId: string
    eventId: string
    mentions?: BaseEntityLikeFragment[]
  } & EntityLinkProps
> = ({ className, entityId, eventId, mentions, ...props }) => {
  const activeContentView = useActiveContentView()
  const location = useLocation()
  const homeView = useHomeView()

  return (
    <ReactRouterLink
      className={className}
      to={eventPath(
        activeContentView ?? homeView,
        entityId,
        eventId,
        location.search,
        mentions?.map((company) => company.id)
      )}
      {...props}
    />
  )
}

export const PublisherLink = ({ children, className, href, variant, ...props }: UILinkProps) => {
  if (!href) return null
  if (href.includes('paper')) {
    const publisher = href.split('.')[1]
    return (
      <Typography className={className} {...props}>
        {publisher && toTitleCase(publisher)}, print
      </Typography>
    )
  }
  return (
    <ExternalLink className={cn('flex items-center gap-1', className)} variant={variant} href={href} {...props}>
      <FavIcon publisher={href} className='w-4 shrink-0' />
      <span className='block'>{children}</span>
    </ExternalLink>
  )
}
