import { type Reminder, ReminderStatus, TeamActivityKind } from '@strise/types'
import * as React from 'react'
import { isAfter, isFuture, isToday, isValid, isYesterday, parseISO } from 'date-fns'
import { t } from '@lingui/macro'
import {
  type ButtonProps,
  type IconComponent,
  type IconPropsWithRef,
  IconReminderDefault,
  IconReminderOverdue,
  IconReminderToday
} from '@strise/midgard'
import { formatDate, formatRelativeDate, useUserId } from '@strise/europa'
import { isString } from 'lodash-es'

export const useTeamActivityKindPrefix = (kind: TeamActivityKind, timestamp: Date | string, userId?: string) => {
  const currentUserId = useUserId()

  const titlePrefix = React.useMemo(() => {
    const parsedTimestamp = isString(timestamp) ? parseISO(timestamp) : timestamp
    const isInFuture = isValid(parsedTimestamp) && isFuture(parsedTimestamp)

    if (currentUserId !== userId) {
      switch (kind) {
        case TeamActivityKind.Meeting: {
          return isInFuture
            ? t({ id: 'has a (meeting)', message: 'has a' })
            : t({ id: 'had a (meeting)', message: 'had a' })
        }
        case TeamActivityKind.Call: {
          return isInFuture ? t({ id: 'has a (call)', message: 'has a' }) : t({ id: 'had a (call)', message: 'had a' })
        }
        case TeamActivityKind.Note: {
          return t({ id: 'made a (note)', message: 'made a' })
        }
        default: {
          return t({ id: 'made an (activity)', message: 'made an' })
        }
      }
    }

    switch (kind) {
      case TeamActivityKind.Meeting: {
        return isInFuture
          ? t({ id: 'have a (meeting)', message: 'have a' })
          : t({ id: 'had a (meeting)', message: 'had a' })
      }
      case TeamActivityKind.Call: {
        return isInFuture ? t({ id: 'have a (call)', message: 'have a' }) : t({ id: 'had a (call)', message: 'had a' })
      }
      case TeamActivityKind.Note: {
        return t({ id: 'made a (note)', message: 'made a' })
      }
      default: {
        return t({ id: 'made an (activity)', message: 'made an' })
      }
    }
  }, [timestamp, kind])

  return { titlePrefix }
}

interface ReminderStyle {
  ReminderIcon?: IconComponent
  colorClassName: string
  reminderBackgroundColorClass?: string
  reminderIsToday?: boolean
  reminderText: string
}

export const useTeamActivityReminderStyle = (
  timestamp: Date | string,
  reminder?: Pick<Reminder, 'id' | 'time' | 'completedAt' | 'overdue' | 'status'> | null
): ReminderStyle => {
  return React.useMemo(() => {
    const at = t({ id: 'at (time)', message: 'at' })
    const parsedTimestamp = isString(timestamp) ? parseISO(timestamp) : timestamp
    const formattedTimestamp = formatDate(timestamp, {
      time: true,
      format: `d LLL u '${at}' HH:mm`
    })
    const relativeFormattedTimestamp = formatRelativeDate(timestamp)
    const reminderIsToday = isToday(parsedTimestamp)
    const reminderIsYesterday = isYesterday(parsedTimestamp)

    const isTeamActivityCompleted = reminder?.status === ReminderStatus.Inactive

    if (!reminder || isTeamActivityCompleted) {
      return {
        colorClassName: 'text-text-secondary',
        reminderText: formattedTimestamp
      }
    }

    if (reminder.overdue) {
      return {
        ReminderIcon: IconReminderOverdue,
        colorClassName: 'text-semantic-danger-main',
        reminderBackgroundColorClass: 'bg-accent-pink-shade-5',
        reminderText: reminderIsYesterday || reminderIsToday ? relativeFormattedTimestamp : formattedTimestamp
      }
    }

    return {
      reminderIsToday,
      colorClassName: 'text-semantic-info-main',
      reminderBackgroundColorClass: reminderIsToday ? 'text-semantic-info-shade-10' : undefined,
      ReminderIcon: reminderIsToday ? IconReminderToday : undefined,
      reminderText: reminderIsToday ? relativeFormattedTimestamp : formattedTimestamp
    }
  }, [timestamp, reminder])
}

interface TeamActivityFormDate {
  ReminderIcon?: IconComponent
  colorClassName?: string
  iconProps: IconPropsWithRef
  text: string | undefined
}

export const useTeamActivityFormDateStyle = (
  timestamp: string | undefined,
  isActivityCompleted: boolean
): TeamActivityFormDate => {
  return React.useMemo(() => {
    const defaultProps = {
      ReminderIcon: IconReminderDefault,
      iconProps: { size: 'md' } as const,
      text: timestamp
    }

    if (!timestamp) {
      return {
        ...defaultProps,
        ReminderIcon: undefined
      }
    }

    const parsedTimestamp = isString(timestamp) ? parseISO(timestamp) : timestamp

    const isDateValid = isValid(parsedTimestamp)
    const isInToday = isDateValid && isToday(parsedTimestamp)
    const isInFuture = isDateValid && isFuture(parsedTimestamp)
    const isInAfter = isDateValid && isAfter(parsedTimestamp, new Date())
    const isOverdue = (!isInToday && !isInFuture) || !isInAfter

    if (isActivityCompleted) {
      return {
        ...defaultProps,
        ReminderIcon: undefined,
        iconColorClassName: undefined
      }
    }

    return {
      ...defaultProps,
      colorClassName: isOverdue ? 'text-semantic-danger-main' : 'text-semantic-info-main'
    }
  }, [timestamp, isActivityCompleted])
}

interface TeamActivityCompleteButtonStyle extends ButtonProps {
  buttonText: string
  isOverdue?: boolean
  trackId: string
}

const getTeamActivityCompleteButtonStyle = (
  timestamp: Date | string,
  isActivityCompleted: boolean
): TeamActivityCompleteButtonStyle => {
  const parsedTimestamp = isString(timestamp) ? parseISO(timestamp) : timestamp

  const isDateValid = isValid(parsedTimestamp)
  const isInToday = isDateValid && isToday(parsedTimestamp)
  const isInFuture = isDateValid && isFuture(parsedTimestamp)
  const isInAfter = isDateValid && isAfter(parsedTimestamp, new Date())
  const isOverdue = (!isInToday && !isInFuture) || !isInAfter

  if (isActivityCompleted) {
    return {
      isOverdue,
      className:
        'text-semantic-success-shade-100 border-semantic-success-shade-50 bg-semantic-success-shade-5 hover:bg-semantic-success-shade-10 active:bg-semantic-success-shade-20',
      buttonText: t`Completed`,
      trackId: 'Team Activity / Completed'
    }
  }

  return {
    isOverdue,
    palette: 'tertiary',
    className: 'border-white',
    buttonText: t`Mark complete`,
    trackId: 'Team Activity / Mark Completed'
  }
}

export const useTeamActivityFormCompleteButtonStyle = (
  timestamp: Date | string,
  isActivityCompleted: boolean
): TeamActivityCompleteButtonStyle =>
  React.useMemo(
    () => getTeamActivityCompleteButtonStyle(timestamp, isActivityCompleted),
    [timestamp, isActivityCompleted]
  )

export const useTeamActivityCompleteButtonStyle = (
  timestamp: Date | string,
  isActivityCompleted: boolean
): TeamActivityCompleteButtonStyle =>
  React.useMemo(
    () => getTeamActivityCompleteButtonStyle(timestamp, isActivityCompleted),
    [timestamp, isActivityCompleted]
  )
