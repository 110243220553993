import * as React from 'react'
import { FromToFilter } from '@components/FromToFilter'
import { t } from '@lingui/macro'
import { setNestedState, useContext } from '@strise/europa'
import { GrowSettingsSections } from '@views/Grow/components/GrowSettingsPanel'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const GrowInceptionFilter = () => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)

  const inceptionSettings = settings.grow.inception
  const saveInceptionSettings = setNestedState(saveSettings, (s) => s.grow.inception)

  const handleLessThanChange = (value: number | null) => {
    saveInceptionSettings({ ...inceptionSettings, lessThan: value })
  }
  const handleMoreThanChange = (value: number | null) => {
    saveInceptionSettings({ ...inceptionSettings, moreThan: value })
  }

  return (
    <GrowSettingsSections title={t`Inception`}>
      <div className='flex items-center'>
        <FromToFilter
          fromValue={inceptionSettings.moreThan}
          toValue={inceptionSettings.lessThan}
          setFromValue={handleMoreThanChange}
          setToValue={handleLessThanChange}
          fromLabel={t`More than (months)`}
          toLabel={t`Less than (months)`}
        />
      </div>
    </GrowSettingsSections>
  )
}
