import * as React from 'react'
import { Box, type BoxProps } from '@strise/system'
import { keyframes, styled } from 'styled-components'
import { LoaderRound, Logo, LogoStripe } from '@strise/midgard'

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const slideUp100 = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const StriseLine = styled(LogoStripe)`
  animation: ${slideIn} 1s cubic-bezier(0.77, 0, 0.18, 1);
`

const StriseLogo = styled(Logo)`
  overflow: visible;

  & > g > path {
    opacity: 0;
    animation: ${slideUp} 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
  }

  & > g > path:nth-child(2) {
    animation-delay: 0.6s;
  }

  & > g > path:nth-child(3) {
    animation-delay: 0.7s;
  }

  & > g > path:nth-child(4) {
    animation-delay: 0.8s;
  }

  & > g > path:nth-child(1) {
    animation-delay: 0.9s;
  }

  & > g > path:nth-child(5) {
    animation-delay: 1s;
  }

  & > g > path:nth-child(6),
  & > g > path:nth-child(7) {
    animation-delay: 1.1s;
  }
`

interface AnimateUpProps extends BoxProps {
  delay: string
}

const AnimateUp = styled(Box)<AnimateUpProps>`
  animation: ${slideUp100} 1s cubic-bezier(0.77, 0, 0.18, 1);
  animation-delay: ${({ delay }: { delay: string }) => delay};
  animation-fill-mode: forwards;
  opacity: 0;
`

export const LoadingView = () => {
  return (
    <div className='flex h-screen flex-col items-center justify-center text-center'>
      <div className='flex w-full flex-row items-center'>
        <div className='mr-[2%] flex w-[53%] items-center'>
          <StriseLine className='mr-4 h-[6px] grow basis-[100px] bg-[#2d2a2b]' />
          <StriseLogo className='w-32 fill-[#2d2a2b]' />
        </div>
        <AnimateUp delay='1s'>
          <LoaderRound size='md' palette='primary' />
        </AnimateUp>
      </div>
    </div>
  )
}

export const SuspenseLoadingView = ({ children }: { children: React.ReactNode }) => {
  return <React.Suspense fallback={<LoadingView />}>{children}</React.Suspense>
}
