import type React from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { createContext } from '@strise/europa'

interface SidepanelContextProps {
  expanded: boolean
  id: string
  scrollEl: React.RefObject<HTMLElement | null>
  setExpanded: SetStateFn<boolean | null>
  setId: SetStateFn<string | null>
  setShowHeaderEntity: SetStateFn<boolean>
  showHeaderEntity: boolean
}

export const SidepanelContext = createContext<SidepanelContextProps>()
