import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagEs = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagES_svg__a)'>
      <path fill='#D80027' d='M1.164 1h22v5.5l-1.71 5.599 1.71 5.401V23h-22v-5.5l1.624-5.328L1.164 6.5V1Z' />
      <path fill='#FFDA44' d='M1.164 6.5h22v11h-22v-11Z' />
    </g>
    <defs>
      <clipPath id='FlagES_svg__a'>
        <rect width={22} height={22} x={1.164} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagEs.displayName = 'FlagEs'
