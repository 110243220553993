import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagNo = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagNO_svg__a)'>
      <path
        fill='#D80027'
        d='M1 1h4.305l2.84 2.299L11.043 1H23v8.134l-1.964 2.909L23 14.87V23H11.042L8.12 20.822 5.305 23H1v-8.13l2.209-2.943L1 9.134V1Z'
      />
      <path
        fill='#EEE'
        d='M5.305 1v8.134H1v1.435l1.057 1.418L1 13.44v1.435h4.305V23h1.436l1.314-1.13L9.607 23h1.435v-8.13H23v-1.435l-1.057-1.448L23 10.57V9.134H11.042V1H9.607L8.154 2.087 6.741 1H5.305Z'
      />
      <path fill='#0052B4' d='M6.74 1v9.57H1v2.865h5.74V23h2.867v-9.565H23V10.57H9.607V1H6.74Z' />
    </g>
    <defs>
      <clipPath id='FlagNO_svg__a'>
        <rect width={22} height={22} x={1} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagNo.displayName = 'FlagNo'
