import { useIsMobile } from '@utils/hooks'
import { useEffect } from 'react'
import {
  setChildState,
  useContext,
  useRecursiveQueryParamsReactRouter,
  validateBoolean,
  validateEnum,
  validateString
} from '@strise/europa'
import { type SidepanelEntityFragment } from '@graphqlTypes'
import {
  sidepanelEntityIdQueryParamKey,
  sidepanelExpandedQueryParamKey,
  SidepanelTab,
  sidepanelTabQueryParamKey
} from '@strise/strise-utils'
import { RecentlyVisitedEntitiesContext } from '@contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'

type SupportedEntityTypenames = 'Company' | 'Person' | 'GlobalCompany' | 'GlobalPerson' | 'PrivatePerson'

export type SupportedSidepanelEntityFragment = SidepanelEntityFragment & {
  __typename: SupportedEntityTypenames
}

export const extractIsSupportedSidepanelEntity = (
  entity: SidepanelEntityFragment | null | undefined
): entity is SupportedSidepanelEntityFragment | null | undefined => {
  if (!entity) return true

  return ['Company', 'Person', 'GlobalCompany', 'GlobalPerson', 'PrivatePerson'].includes(entity.__typename)
}

interface SidepanelQueryParamsState {
  [sidepanelEntityIdQueryParamKey]: string | null
  [sidepanelExpandedQueryParamKey]: boolean | null
  [sidepanelTabQueryParamKey]: SidepanelTab | null
}

const defaultState = {
  [sidepanelEntityIdQueryParamKey]: null,
  [sidepanelExpandedQueryParamKey]: false,
  [sidepanelTabQueryParamKey]: null
}

const validations = {
  [sidepanelEntityIdQueryParamKey]: validateString,
  [sidepanelExpandedQueryParamKey]: validateBoolean,
  [sidepanelTabQueryParamKey]: validateEnum(SidepanelTab)
}

const serializations = {
  [sidepanelEntityIdQueryParamKey]: (value: SidepanelQueryParamsState[typeof sidepanelEntityIdQueryParamKey]) =>
    value ?? undefined,
  [sidepanelExpandedQueryParamKey]: (value: SidepanelQueryParamsState[typeof sidepanelExpandedQueryParamKey]) =>
    value ? 'true' : undefined,
  [sidepanelTabQueryParamKey]: (value: SidepanelQueryParamsState[typeof sidepanelTabQueryParamKey]) =>
    value ?? undefined
}

export const useSidepanel = () => {
  const { addRecentlyVisitedEntity } = useContext(RecentlyVisitedEntitiesContext)

  const [sidepanelState, setSidepanelState] = useRecursiveQueryParamsReactRouter<SidepanelQueryParamsState>(
    defaultState,
    validations,
    serializations
  )

  const isMobile = useIsMobile()

  const id = sidepanelState[sidepanelEntityIdQueryParamKey]
  const isExpanded = Boolean(sidepanelState[sidepanelExpandedQueryParamKey])
  const sidepanelTab = sidepanelState[sidepanelTabQueryParamKey]

  const setId = setChildState(setSidepanelState, sidepanelEntityIdQueryParamKey)
  const setIsExpanded = setChildState(setSidepanelState, sidepanelExpandedQueryParamKey)
  const setSidepanelTab = setChildState(setSidepanelState, sidepanelTabQueryParamKey)

  useEffect(() => {
    if (!isMobile) return
    setIsExpanded(false)
  }, [isMobile])

  useEffect(() => {
    if (!id) return

    addRecentlyVisitedEntity(id)
  }, [id])

  const reset = () => {
    setId(null)
    setIsExpanded(false)
    setSidepanelTab(null)
  }

  return { id, isExpanded, sidepanelTab, setId, setIsExpanded, setSidepanelTab, close: reset }
}
