import type React from 'react'
import { useCallback, useRef, useState } from 'react'
import { getBrowserGlobals } from '@strise/system'

// TODO - fix better prop namings for this stuff - not important for current release
export interface UseDragScrollOpts {
  scrollXYEl?: HTMLElement | null | undefined
  scrollYEl?: HTMLElement | null | undefined
  sharedXYOnly?: boolean
}

export const useDragScroll = (opts: UseDragScrollOpts) => {
  const [element, setElement] = useState<HTMLElement | null>(null)
  const scrollXYElement = opts.scrollXYEl || element
  const scrollYElement = opts.scrollYEl || element
  const { sharedXYOnly } = opts

  const scrollState = useRef({
    isDragging: false,
    raf: true,
    clientX: 0,
    clientY: 0
  })

  const startDrag = () => {
    scrollState.current.isDragging = true
    if (element) element.style.cursor = 'grabbing'
    if (scrollXYElement) scrollXYElement.style.scrollBehavior = 'auto'
    if (scrollYElement) scrollYElement.style.scrollBehavior = 'auto'
  }

  const stopDrag = () => {
    scrollState.current.isDragging = false
    if (element) element.style.cursor = 'grab'
    if (scrollXYElement) scrollXYElement.style.scrollBehavior = ''
    if (scrollYElement) scrollYElement.style.scrollBehavior = ''
  }

  const handleStopDrag = useCallback(() => {
    stopDrag()
  }, [element, scrollXYElement, scrollYElement])

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      startDrag()
      scrollState.current.clientX = e.clientX
      scrollState.current.clientY = e.clientY
    },
    [element, scrollXYElement, scrollYElement]
  )

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (scrollState.current.isDragging && scrollState.current.raf) {
        scrollState.current.raf = false
        getBrowserGlobals()?.window.requestAnimationFrame(() => {
          scrollState.current.raf = true

          if (scrollXYElement) {
            scrollXYElement.scrollLeft = scrollXYElement.scrollLeft - (e.clientX - scrollState.current.clientX)
            scrollState.current.clientX = e.clientX

            if (sharedXYOnly) {
              // TODO - put X and Y scrolling into smaller util functions
              scrollXYElement.scrollTop = scrollXYElement.scrollTop - (e.clientY - scrollState.current.clientY)
              scrollState.current.clientY = e.clientY
            }
          }

          if (!sharedXYOnly && scrollYElement) {
            scrollYElement.scrollTop = scrollYElement.scrollTop - (e.clientY - scrollState.current.clientY)
            scrollState.current.clientY = e.clientY
          }
        })
      }
    },
    [scrollXYElement, scrollYElement]
  )

  return {
    ref: (node: HTMLElement | null) => {
      setElement(node)
    },
    onMouseDown: handleMouseDown,
    onMouseUp: handleStopDrag,
    onMouseMove: handleMouseMove,
    onMouseLeave: handleStopDrag,
    onDragStart: handleStopDrag,
    sx: {
      cursor: 'grab',
      userSelect: 'none'
    }
  }
}
