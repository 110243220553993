import { css, keyframes, styled } from 'styled-components'
import type * as React from 'react'
import { Toast, type ToastProps } from '@strise/midgard'

const enterAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const exitAnimation = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`

export const SlideToast = styled<React.ComponentType<ToastProps & { visible: boolean }>>(Toast)`
  animation: ${({ visible }) =>
    visible
      ? css`
          ${enterAnimation} 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) forwards
        `
      : css`
          ${exitAnimation} 0.4s cubic-bezier(0.06, 0.71, 0.55, 1) forwards
        `};
`
