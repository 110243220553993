import {
  cn,
  Divider,
  ExternalLink,
  IconButton,
  IconCopy,
  IconSuccessFill,
  IconWarningFill,
  Tooltip,
  Typography
} from '@strise/midgard'
import * as React from 'react'
import { type TransformedApiTableItem } from '@utils/apiTable/apiTableUtils'
import { type DivProps } from '@strise/react-utils'
import { TestIDs } from '@utils/testIDs'

export const TransformedTableItem = ({
  className,
  isLast,
  item,
  ...props
}: { isLast: boolean; item: TransformedApiTableItem } & DivProps) => {
  const primaryLine = item.label || item.content
  const contentWithIcon = item.Icon ? (
    <>
      <item.Icon className='mr-1 size-4' />
      <Typography className='overflow-hidden truncate'>{primaryLine}</Typography>
    </>
  ) : (
    primaryLine
  )

  const content = (
    <div className='flex w-full items-center'>
      {!item.href && contentWithIcon}
      {item.href && (
        <ExternalLink variant='body1' href={item.href} className='text-text-link' {...item.linkProps}>
          {contentWithIcon}
        </ExternalLink>
      )}
    </div>
  )

  const secondaryLine = item.label && item.content ? item.content : null

  const fullContent = secondaryLine ? (
    <div className='flex max-w-full flex-col'>
      {content}
      <span className='text-gray-40'>{secondaryLine}</span>
    </div>
  ) : (
    content
  )

  return (
    <>
      <div
        className={cn(
          'my-2 grid auto-cols-min grid-flow-col grid-cols-1 items-center justify-between rounded-xl',
          { 'bg-semantic-notice-shade-5': item.reviewMeta?.warning },
          item.containerClassName,
          className
        )}
        {...props}
      >
        <Tooltip content={item.tooltipContent} className='max-w-[350px]'>
          {fullContent}
        </Tooltip>
        <div>
          {item.handleCopy && item.dataTrack && (
            <IconButton
              className='-my-1 ml-auto text-text-secondary'
              variant='ghost'
              palette='tertiary'
              onClick={item.handleCopy}
              data-track={item.dataTrack}
              aria-label='Copy'
            >
              <IconCopy />
            </IconButton>
          )}

          {item.reviewMeta && (
            <Tooltip content={item.reviewMeta.reason}>
              <div className='ml-4 flex items-center py-1'>
                {item.reviewMeta.warning ? (
                  <IconWarningFill data-id={TestIDs.Review.Card.warningIcon} className='text-semantic-notice-main' />
                ) : (
                  <IconSuccessFill data-id={TestIDs.Review.Card.successIcon} className='text-semantic-success-main' />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {!isLast && !item.hideBorder && <Divider />}
    </>
  )
}
