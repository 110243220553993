import {
  type BaseEntityLikeFragment,
  type CustomMetaFragment,
  type TableFragment,
  type TableRowFragment,
  type TableRowValueFragment
} from '@graphqlTypes'
import { isInObject } from '@strise/fika'
import { type IconComponent, IconMap, type IconPropsWithRef } from '@strise/midgard'
import { copyString } from '../hooks'
import React from 'react'
import { NationFlag } from '@strise/europa'
import {
  mkTableValueLabel,
  type handleEditRowFn,
  type TransformedApiTable,
  type TransformedApiTableItem,
  type TransformedApiTableRow,
  type TransformedApiTableSection
} from './apiTableUtils'
import { TableRowKind } from '@strise/types'
import { CheckboxColumnSkeleton, RowValueSkeleton, TitleSkeleton } from '@loaders/ReviewCompanyCardLoader'
import { TableLabelContent, TableValueContent } from '@utils/apiTable/TableValueContent'
import { orderBy } from 'lodash-es'
import type { ReviewState } from '@utils/reviewUtils'
import type { SetStateFn } from '@strise/react-utils'

const extractIcon = (iconId: string | null | undefined): IconComponent | undefined => {
  if (!iconId) return

  if (iconId.startsWith('Flag')) {
    return React.forwardRef<SVGSVGElement, IconPropsWithRef>((props, ref) => (
      <NationFlag countryIsoCode={iconId.slice(-2)} ref={ref} {...props} />
    ))
  }

  const iconKey = `Icon${iconId}`

  if (!isInObject(IconMap, iconKey)) return

  return IconMap[iconKey]
}

const extractCustomMeta = (value: TableRowValueFragment): CustomMetaFragment | null | undefined => {
  switch (value.__typename) {
    case 'TableRowValueRoleWithEntity': {
      return orderBy(value.roleMetas, (roleMeta) => roleMeta.customMeta?.lastModifiedAt, 'desc')
        .map((roleMeta) => roleMeta.customMeta)
        .at(0)
    }
    case 'TableRowValueOwnership': {
      return value.customMeta
    }
    case 'TableRowValueRole': {
      return value.roleMeta.customMeta
    }
    default: {
      return null
    }
  }
}

const extractTableValues = (
  rowKey: string,
  entity: BaseEntityLikeFragment,
  values: TableRowValueFragment[],
  sectionEntity?: BaseEntityLikeFragment,
  editMode?: boolean,
  handleEdit?: handleEditRowFn,
  reviewState?: ReviewState,
  setReviewState?: SetStateFn<ReviewState>
): TransformedApiTableItem[] => {
  return values.map((value) => {
    // Make sure this is memoized
    const IconComponent = extractIcon(value.iconId)
    const content = (
      <TableValueContent
        entity={entity}
        value={value}
        sectionEntity={sectionEntity}
        editMode={editMode}
        handleEdit={handleEdit}
        reviewState={reviewState}
        setReviewState={setReviewState}
      />
    )
    const label = mkTableValueLabel(value)

    const isAms = value.__typename === 'TableRowValueEvent'

    return {
      href: value.link,
      content,
      label,
      handleCopy: value.copyValue ? copyString(value.copyValue) : undefined,
      dataTrack: `${entity.__typename} / ${rowKey} copied`,
      Icon: IconComponent,
      tooltipContent: value.tooltip,
      reviewMeta: value.reviewMeta,
      hideBorder: isAms || ('detailedRoles' in value && value.detailedRoles),
      customMeta: extractCustomMeta(value),
      containerClassName: isAms ? '' : 'px-2 py-1'
    }
  })
}

export type TableWithLoaders = TableFragment | { loadingRowCount: number }

const extractCustomCheckboxName = (row: TableRowFragment) => {
  if (row.kind === TableRowKind.ReviewCustomCheckbox && row.label.__typename === 'TableLabelString') {
    return row.label.content
  }
  return undefined
}

const extractTableRows = (
  entity: BaseEntityLikeFragment,
  rows: TableRowFragment[],
  editMode?: boolean,
  handleEdit?: handleEditRowFn,
  reviewState?: ReviewState,
  setReviewState?: SetStateFn<ReviewState>
): TransformedApiTableRow[] => {
  return rows.map((row) => {
    const customCheckboxName = extractCustomCheckboxName(row)

    return {
      customCheckboxName,
      label: <TableLabelContent label={row.label} handleEdit={handleEdit} editMode={editMode} />,
      key: customCheckboxName || row.key,
      kind: row.kind,
      showEmpty: true,
      paginationThreshold: row.paginationThreshold,
      sections: extractTableRowSections(row, entity, editMode, handleEdit, reviewState, setReviewState)
    }
  })
}

const extractTableRowSections = (
  row: TableRowFragment,
  entity: BaseEntityLikeFragment,
  editMode?: boolean,
  handleEdit?: handleEditRowFn,
  reviewState?: ReviewState,
  setReviewState?: SetStateFn<ReviewState>
): TransformedApiTableSection[] => {
  return row.sections.map((rowSection) => {
    const sectionEntity: BaseEntityLikeFragment | undefined =
      rowSection.label?.__typename === 'TableLabelEntity' ? rowSection.label.entity : undefined

    const items = extractTableValues(
      row.key,
      entity,
      rowSection.values,
      sectionEntity,
      editMode,
      handleEdit,
      reviewState,
      setReviewState
    )

    return {
      // Important to do the check here and not inside the component, as we need to know if we should render or not
      label: rowSection.label ? <TableLabelContent label={rowSection.label} /> : null,
      inlineLabel: rowSection.values[0]?.__typename === 'TableRowValueRelatedEntity',
      items,
      paginationThreshold: rowSection.paginationThreshold
    }
  })
}

const extractTransformedTable = (
  entity: BaseEntityLikeFragment,
  table: TableWithLoaders,
  editMode?: boolean,
  handleEdit?: handleEditRowFn,
  reviewState?: ReviewState,
  setReviewState?: SetStateFn<ReviewState>
): TransformedApiTable => {
  if ('loadingRowCount' in table) {
    return {
      title: <TitleSkeleton />,
      description: undefined,
      rows: Array.from({ length: table.loadingRowCount }).map((_, rowIndex) => ({
        label: <CheckboxColumnSkeleton />,
        key: String(rowIndex),
        kind: TableRowKind.Other,
        showEmpty: true,
        paginationThreshold: null,
        sections: [
          {
            label: null,
            items: Array.from({ length: 2 }).map((__, valueIndex) => ({
              content: <RowValueSkeleton key={valueIndex} />
            })),
            paginationThreshold: null
          }
        ],
        disabled: true
      })),
      dataSources: []
    }
  }

  return {
    title: table.title,
    description: table.description,
    rows: extractTableRows(entity, table.rows, editMode, handleEdit, reviewState, setReviewState),
    dataSources: table.dataSources,
    lastModifiedAt: table.lastModifiedAt ? new Date(table.lastModifiedAt) : null
  }
}

export const extractTablesWithLoader = (
  tables: TableFragment[] | null | undefined,
  loading: boolean,
  rowCount: number
): TableWithLoaders[] => {
  if (loading) return [{ loadingRowCount: rowCount }]
  return tables ?? []
}

export const useTransformedTables = (
  entity: BaseEntityLikeFragment,
  tables: TableWithLoaders[],
  reviewState: ReviewState | undefined,
  setReviewState: SetStateFn<ReviewState>
): TransformedApiTable[] => {
  return React.useMemo(() => {
    return tables.map((table) => {
      return extractTransformedTable(entity, table, undefined, undefined, reviewState, setReviewState)
    })
  }, [entity, JSON.stringify(tables)])
}

export const useTransformedTable = (
  entity: BaseEntityLikeFragment,
  table: TableWithLoaders | null | undefined,
  editMode?: boolean,
  handleEdit?: handleEditRowFn
): TransformedApiTable | null => {
  return React.useMemo(() => {
    if (!table) return null

    return extractTransformedTable(entity, table, editMode, handleEdit)
  }, [JSON.stringify(entity), JSON.stringify(table), editMode])
}
