import * as React from 'react'
import { stateStyles, TableHeadCell, type TableHeadCellProps, type TableSize } from '@strise/system'
import { type SortableTableColumn } from './sortableTableUtils'
import { type SortDirection } from '@strise/fika'
import { IconArrowDown, IconArrowDownSmall, IconArrowUp, IconArrowUpSmall } from '@strise/midgard'

interface Sort {
  direction: SortDirection
  field: string
}

export interface SortableTableHeadCellProps<K extends string> extends TableHeadCellProps {
  column: SortableTableColumn<K>
  handleSort: (field: K) => () => void
  sort?: Sort
}

const SortIcon: React.FC<{
  size?: TableSize
  sort?: Sort
}> = ({ size, sort }) => {
  const direction = sort?.direction
  if (!direction) return null

  return (
    <div className='ml-auto flex shrink-0 items-center'>
      {size === 'large' && direction === 'asc' && <IconArrowUp />}
      {size === 'large' && direction === 'desc' && <IconArrowDown />}
      {size !== 'large' && direction === 'asc' && <IconArrowUpSmall />}
      {size !== 'large' && direction === 'desc' && <IconArrowDownSmall />}
    </div>
  )
}

export const SortableTableHeadCell = <K extends string>({
  className,
  column,
  handleSort,
  palette = 'secondary.80',
  size,
  sort,
  ...props
}: SortableTableHeadCellProps<K>) => {
  const styles = palette && stateStyles('contained', palette)

  return (
    <TableHeadCell
      className={className}
      size={size}
      onClick={column.sortable ? handleSort(column.field) : undefined}
      Icon={<SortIcon size={size} sort={sort} />}
      palette={palette}
      sx={{
        ...styles,
        cursor: column.sortable ? 'pointer' : 'auto'
      }}
      {...column.headCellProps}
      {...props}
    >
      {column.title}
    </TableHeadCell>
  )
}
