import { Button, type ButtonProps, cn, Typography, type TypographyProps } from '@strise/midgard'
import * as React from 'react'
import { type DropdownChildren, type DropdownProps } from './Dropdown'
import { OverflowMenu } from './OverflowMenu'

const Actions = ({ className, ...props }: DropdownProps) => (
  <OverflowMenu
    className={className}
    ml='auto'
    menu={false}
    buttonProps={{
      className: 'text-secondary-shade-70',
      palette: 'secondary'
    }}
    popperProps={{
      placement: 'bottom',
      strategy: 'fixed',
      zIndex: 999,
      portal: true
    }}
    backdrop
    {...props}
  />
)

export type MenuItemProps = {
  actions?: DropdownChildren
  activeBorder?: boolean
  isActive?: boolean
  selected?: boolean
  text?: string
  textProps?: TypographyProps
} & ButtonProps

const extractBgColor = (selected: boolean | undefined, isActive: boolean | undefined) => {
  if (selected) return 'bg-secondary-shade-80'
  if (isActive) return 'bg-accent-blue-shade-5'
  return 'bg-transparent'
}

// TODO - migrate this
export const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      actions,
      activeBorder = true,
      children,
      className,
      endIcon,
      isActive,
      palette = 'secondary',
      selected,
      startIcon,
      text,
      textProps,
      ...props
    },
    ref
  ) => {
    const startIconWithPadding =
      startIcon && React.isValidElement(startIcon)
        ? React.cloneElement(startIcon, { className: `${startIcon.props.className} m-2 shrink-0` })
        : startIcon
    const endIconWithPadding =
      endIcon && React.isValidElement(endIcon)
        ? React.cloneElement(endIcon, { className: `${endIcon.props.className} m-2 shrink-0` })
        : endIcon

    return (
      <Button
        className={cn(
          'flex h-12 w-full justify-start border-primary-main',
          palette === 'secondary' && 'bg-secondary-shade-90 hover:bg-secondary-shade-80',
          isActive && activeBorder && 'border-r-4 border-primary-main',
          text && !startIcon ? 'pl-4' : 'pl-2',
          text && !endIcon ? 'pr-4' : 'pr-2',
          extractBgColor(selected, isActive),
          className
        )}
        ref={ref}
        asChild
        startIcon={startIconWithPadding}
        endIcon={actions && selected ? <Actions>{actions}</Actions> : endIconWithPadding}
        variant='contained'
        palette={palette}
        {...props}
      >
        <div>
          <Typography variant='aLabel' {...textProps} className={cn('flex-1 truncate', textProps?.className)}>
            {!children && text}
            {children}
          </Typography>
        </div>
      </Button>
    )
  }
)
