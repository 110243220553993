import * as React from 'react'
import { IconCheckSmall } from '@strise/midgard'
import { LanguageNationFlags } from '@strise/europa'
import { type ContentLanguage } from '@strise/types'

export const FinancialFieldSelectRenderText = (fieldName: string, countries: ContentLanguage[], selected?: boolean) => {
  return (
    <div className='flex items-center'>
      {selected && <IconCheckSmall className='mr-4' />}
      <LanguageNationFlags languages={countries} className='mr-2' />
      {fieldName}
    </div>
  )
}
