import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagUs = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagUS_svg__a)'>
      <path
        fill='#EEE'
        d='M12 1h11v2.75l-1.375 1.375L23 6.5v2.75l-1.375 1.375L23 12v2.75l-1.375 1.375L23 17.5v2.75l-11 1.375L1 20.25V17.5l1.375-1.375L1 14.75V12L12 1Z'
      />
      <path
        fill='#D80027'
        d='M10.625 3.75H23V6.5H10.625V3.75Zm0 5.5H23V12H12l-1.375-2.75ZM1 14.75h22v2.75H1v-2.75Zm0 5.5h22V23H1v-2.75Z'
      />
      <path fill='#0052B4' d='M1 1h11v11H1V1Z' />
      <path
        fill='#EEE'
        d='m9.035 11.441 2.45-1.761H8.476l2.449 1.761-.946-2.879-.945 2.88Zm-3.48 0L8.004 9.68H4.996l2.45 1.761L6.5 8.562l-.945 2.88Zm-3.48 0L4.522 9.68H1.516l2.449 1.761-.945-2.879-.946 2.88Zm6.96-3.48 2.45-1.762H8.476l2.449 1.762-.946-2.879-.945 2.879Zm-3.48 0 2.449-1.762H4.996l2.45 1.762L6.5 5.082l-.945 2.879Zm-3.48 0 2.448-1.762H1.516l2.449 1.762-.945-2.879-.946 2.879Zm6.96-3.524 2.45-1.761H8.476l2.449 1.761L9.98 1.56l-.945 2.878Zm-3.48 0 2.449-1.761H4.996l2.45 1.761L6.5 1.56l-.945 2.878Zm-3.48 0 2.448-1.761H1.516l2.449 1.761L3.02 1.56l-.946 2.878Z'
      />
    </g>
    <defs>
      <clipPath id='FlagUS_svg__a'>
        <rect width={22} height={22} x={1} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagUs.displayName = 'FlagUs'
