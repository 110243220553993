import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagDk = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagDK_svg__a)'>
      <path
        fill='#D80027'
        d='M1.033 1h5.74l1.405.872L9.64 1h13.394v9.565L22.148 12l.885 1.435V23H9.639l-1.362-.877L6.773 23h-5.74v-9.565l1.263-1.418-1.263-1.448V1Z'
      />
      <path fill='#EEE' d='M6.773 1v9.565h-5.74v2.87h5.74V23H9.64v-9.565h13.394v-2.87H9.639V1H6.773Z' />
    </g>
    <defs>
      <clipPath id='FlagDK_svg__a'>
        <rect width={22} height={22} x={1.033} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagDk.displayName = 'FlagDk'
