import * as React from 'react'
import { Card, CardAction, CardActionArea, CardContent, IconArrowDiagonalUp, Typography } from '@strise/midgard'

interface HomeCardActionsProps {
  icon: React.ReactNode
  onClick?: () => void
  title: string
  url?: string
}

export const HomeCardActions = ({ icon, onClick, title, url }: HomeCardActionsProps) => {
  return (
    <Card
      className='h-[56px] w-[352px] flex-row border-transparent bg-blue-5 hover:border-blue-10 hover:bg-blue-10 active:border-blue-20'
      animationHover={true}
      onClick={onClick}
      to={url}
    >
      <CardContent className='w-full flex-row items-center justify-start gap-x-4 rounded-xl bg-white'>
        {icon}
        <Typography variant='subtitle2'>{title}</Typography>
      </CardContent>
      <CardActionArea>
        <CardAction>
          <IconArrowDiagonalUp className='text-blue-40' />
        </CardAction>
      </CardActionArea>
    </Card>
  )
}
