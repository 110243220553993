import * as React from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  IconArrowRight,
  Typography
} from '@strise/midgard'
import { ReactRouterLink } from '@strise/europa'
import { t } from '@lingui/macro'

interface HomeCardProps {
  description: string
  icon: React.ReactNode
  title: string
  url: string
}

export const HomeCard = ({ description, icon, title, url }: HomeCardProps) => {
  return (
    <ReactRouterLink to={url} className='no-underline hover:no-underline'>
      <Card className='group h-[250px] w-[351px]' animationHover={true}>
        <CardHeader>
          <CardTitle>{icon}</CardTitle>
        </CardHeader>
        <CardContent className='flex h-full flex-col justify-between'>
          <Typography variant='h2'>{title}</Typography>
          <CardDescription>
            <Typography variant='body1' className='text-gray-60'>
              {description}
            </Typography>
          </CardDescription>
        </CardContent>
        <CardFooter className='justify-between bg-blue-50 text-text-primaryContrast group-hover:bg-blue-80 group-active:bg-blue-90'>
          <Typography variant='body1'>
            {t`Go to`} {title}
          </Typography>
          <IconArrowRight />
        </CardFooter>
      </Card>
    </ReactRouterLink>
  )
}
