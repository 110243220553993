import * as React from 'react'
import { type DisplayLanguage } from '@strise/types'
import { type LinkProps } from '@strise/midgard'
import { displayLanguage } from './state'
import { type BaseEntityLikeFragment } from './graphqlTypes'
import { createContext } from './utils/contextUtils'

interface EuropaContextProps {
  EntityIdLink: React.FC<{ id: string } & Omit<LinkProps, 'to'>>

  EntityLink: React.FC<
    {
      entity: BaseEntityLikeFragment
      noFlags?: boolean
      noLifeStatus?: boolean
      noTooltip?: boolean
    } & Omit<LinkProps, 'to'>
  >
  EventLink: React.FC<{ entityId: string; eventId: string } & Omit<LinkProps, 'to'>>
  language: DisplayLanguage | undefined
}

export const EuropaContext = createContext<EuropaContextProps>()

export const EuropaContextProvider = ({
  children,
  value
}: {
  children: React.ReactNode
  value: EuropaContextProps
}) => {
  React.useEffect(() => {
    displayLanguage(value.language)
  }, [value.language])

  return <EuropaContext.Provider value={value}>{children}</EuropaContext.Provider>
}
