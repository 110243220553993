import { Typography } from '@strise/midgard'
import { CreditScoreIndicator } from '@components/CreditScore/CreditScoreIndicator'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { CreditReportMonitorButton } from '@components/Sidepanel/SidepanelCards/CreditReport/CreditReportMonitorButton'
import { SidepanelCreditScoreHelpModal } from '@components/Sidepanel/SidepanelCards/CreditReport/SidepanelCreditScoreHelpModal'
import { type BaseEntityLikeFragment, type TableRowValueFragment } from '@graphqlTypes'
import { Trans } from '@lingui/macro'
import { formatNumber } from '@strise/fika'
import React from 'react'
import { useToggle } from 'usehooks-ts'
import { SidepanelTab } from '@strise/strise-utils'

export const CreditScore = ({
  entity,
  value
}: {
  entity: BaseEntityLikeFragment
  value: TableRowValueFragment & { __typename: 'TableRowValueCreditReport' }
}) => {
  const [helpModalOpen, toggleHelpModalOpen] = useToggle(false)
  const hasPaymentRemarks = !!value.creditReport.paymentRemarks.edges.length
  const creditReport = value.creditReport

  return (
    <div className='grid w-full grid-cols-[1.5fr_150px_1fr] items-center justify-between gap-6 py-2'>
      <div>
        <CreditScoreRow
          label={<Trans>Credit limit</Trans>}
          value={`${creditReport.creditLimit ? formatNumber(creditReport.creditLimit) : '-'} ${
            creditReport.currencyCode ?? ''
          }`}
        />
        {!!creditReport.contractLimit && (
          <CreditScoreRow
            label={<Trans>Contract limit</Trans>}
            value={`${formatNumber(creditReport.contractLimit)} ${creditReport.currencyCode ?? ''}`}
          />
        )}
        {!!creditReport.dbt && <CreditScoreRow label={<Trans>DBT</Trans>} value={creditReport.dbt} />}
        {!!creditReport.industryDBT && (
          <CreditScoreRow label={<Trans>Industry DBT</Trans>} value={creditReport.industryDBT} />
        )}
        {creditReport.averageInvoiceValue != null && (
          <CreditScoreRow
            label={<Trans>Average invoice value</Trans>}
            value={`${formatNumber(creditReport.averageInvoiceValue)} ${creditReport.currencyCode ?? ''}`}
          />
        )}
        {creditReport.paymentsOnFile != null && (
          <CreditScoreRow label={<Trans>Invoices available</Trans>} value={creditReport.paymentsOnFile} />
        )}
        {creditReport.paymentsPaid != null && (
          <CreditScoreRow label={<Trans>Invoices paid</Trans>} value={creditReport.paymentsPaid} />
        )}
        {creditReport.paymentsStillOwing != null && (
          <CreditScoreRow label={<Trans>Invoices outstanding</Trans>} value={creditReport.paymentsStillOwing} />
        )}
        <CreditScoreRow
          label={<Trans>Payment remarks</Trans>}
          value={hasPaymentRemarks ? <Trans>Yes</Trans> : <Trans>No</Trans>}
        />
      </div>
      <CreditScoreIndicator
        className='mb-0'
        size='small'
        score={value.creditReport.ratingValue}
        scoreCharacter={value.creditReport.internationalRating}
        toggleHelpModalOpen={toggleHelpModalOpen}
        disableAnimation
        isFetched
      />

      <div className='grid place-items-center gap-2 self-center'>
        <CreditReportMonitorButton companyId={entity.id} context='Review' />
        <EntityLink
          entity={entity}
          sidepanelTab={SidepanelTab.Credit}
          noFlags
          noLifeStatus
          noTooltip
          className='text-center'
        >
          <Typography variant='aLabel'>
            <Trans>See details</Trans>
          </Typography>
        </EntityLink>
      </div>
      {helpModalOpen && <SidepanelCreditScoreHelpModal handleClose={toggleHelpModalOpen} />}
    </div>
  )
}

const CreditScoreRow = ({ label, value }: { label: React.ReactNode; value: React.ReactNode }) => {
  return (
    <div className='mb-2 flex items-center justify-between gap-1'>
      <Typography variant='aLabelSmall'>{label}</Typography>
      <Typography className='text-right' variant='aLabelSmallBold'>
        {value}
      </Typography>
    </div>
  )
}
