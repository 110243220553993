import { EntityLink } from '@components/EntityLink/EntityLink'
import { EventLink } from '@components/EventLink'
import { type TableRowValueFragment } from '@graphqlTypes'
import { AdverseFlag, formatDate } from '@strise/europa'
import { cn, Typography } from '@strise/midgard'
import React from 'react'

export const Flag = ({
  entityId,
  value
}: {
  entityId: string
  value: TableRowValueFragment & { __typename: 'TableRowValueFlag' }
}) => {
  const isNotThisCompany = entityId !== value.flag.entity.id
  return (
    <div className='flex'>
      <AdverseFlag severity={value.flag.severity} size='xl' className='mr-2 mt-1 shrink-0' />
      <div>
        <div className='mb-1 flex items-center'>
          {isNotThisCompany && (
            <EntityLink
              entity={value.flag.entity}
              noFlags
              noLifeStatus
              color='text.primary'
              className='text-text-primary underline'
            />
          )}

          <Typography variant='aLabelSmall' className={cn(isNotThisCompany && 'ml-2')}>
            {formatDate(value.flag.date, { relative: true })}
          </Typography>
        </div>
        {value.flag.event ? (
          <EventLink entityId={value.flag.entity.id} eventId={value.flag.event.id} className='block text-text-link'>
            {value.flag.reason}
          </EventLink>
        ) : (
          <div>
            <Typography>{value.flag.reason}</Typography>
          </div>
        )}
      </div>
    </div>
  )
}
