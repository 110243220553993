import { type ColumnField } from './companiesTableColumns'
import { filterArray, filterFromTo, type FilterSpec } from '@components/Filter/FilterHOC'
import { type UserSettingsFragment } from '@graphqlTypes'
import { AssigneeChip, EnumChip } from '@components/Filter/FilterChips'
import {
  useAssigneeOptions,
  useCompanyStatusOptions,
  useLoadingCountryOptions,
  useLocationOptions,
  useSeverityOptions,
  useTagOptions
} from '@components/Filter/filterHooks'
import { defineMessage } from '@lingui/macro'
import { countryLabels } from '@utils/country'
import { FlagChip, IdNameChip } from '@strise/europa'
import { companyStatusTitles } from '@strise/europa/src/i18n'

export const companiesTableFilters: { [Key in ColumnField]?: FilterSpec<UserSettingsFragment> } = {
  name: filterArray({
    path: 'portfolio.countries',
    ChipComponent: EnumChip,
    titleMap: countryLabels,
    useOptions: useLoadingCountryOptions,
    dataTrack: 'Portfolio / Filter / Country / Changed',
    editLabel: defineMessage({ message: 'Country' })
  }),
  flags: filterArray({
    path: 'portfolio.flagSeverities',
    ChipComponent: FlagChip,
    useOptions: useSeverityOptions,
    dataTrack: 'Portfolio / Filter / Flags / Changed',
    editLabel: defineMessage({ message: 'Flag' })
  }),
  status: filterArray({
    path: 'portfolio.statuses',
    ChipComponent: EnumChip,
    titleMap: companyStatusTitles,
    useOptions: useCompanyStatusOptions,
    dataTrack: 'Portfolio / Filter / Company Status / Changed',
    editLabel: defineMessage({ message: 'Status' })
  }),
  assignees: filterArray({
    path: 'portfolio.assignees',
    ChipComponent: AssigneeChip,
    useOptions: useAssigneeOptions,
    dataTrack: 'Portfolio / Filter / Assignees / Changed',
    editLabel: defineMessage({ message: 'Assignees' }),
    enableSearch: true
  }),
  tags: filterArray({
    path: 'portfolio.tags',
    ChipComponent: IdNameChip,
    useOptions: useTagOptions,
    dataTrack: 'Portfolio / Filter / Tags / Changed',
    editLabel: defineMessage({ message: 'Tags' }),
    enableSearch: true
  }),
  employees: filterFromTo<'EmployeeFilter', UserSettingsFragment>({
    path: 'portfolio.employees',
    dataTrack: 'Portfolio / Filter / Employees / Changed',
    editLabel: defineMessage({ message: 'Employees' }),
    suffix: defineMessage({ message: 'employees' })
  }),
  revenue: filterFromTo<'RevenueFilter', UserSettingsFragment>({
    path: 'portfolio.revenue',
    dataTrack: 'Portfolio / Filter / Revenue / Changed',
    editLabel: defineMessage({ message: 'Revenue' }),
    suffix: defineMessage({ message: 'in revenue' }),
    header: defineMessage({ message: 'Revenue in ‘000' })
  }),
  hq: filterArray({
    path: 'portfolio.locations',
    ChipComponent: IdNameChip,
    useOptions: useLocationOptions,
    dataTrack: 'Portfolio / Filter / HQ / Changed',
    editLabel: defineMessage({ message: 'Location' }),
    enableSearch: true
  })
}
