import { cn, IconDotSmall, type IconProps } from '@strise/midgard'
import { CompanyStatus } from '@strise/types'
import * as React from 'react'

const companyStatusColors: { [key in CompanyStatus]: string | null } = {
  [CompanyStatus.Ignored]: null,
  [CompanyStatus.Account]: 'text-semantic-success-main',
  [CompanyStatus.Prospect]: 'text-accent-pink-main',
  [CompanyStatus.Following]: 'text-semantic-info-main',
  [CompanyStatus.InQualification]: 'text-semantic-warning-main',
  [CompanyStatus.Qualified]: 'text-semantic-notice-main',
  [CompanyStatus.Disqualified]: 'text-semantic-danger-main'
}

export const CompanyStatusDot: React.FC<
  {
    status: CompanyStatus
  } & IconProps &
    React.RefAttributes<SVGSVGElement>
> = ({ className, status, ...props }) => {
  const color = companyStatusColors[status]

  if (!color) return null

  return <IconDotSmall className={cn('shrink-0', color, className)} {...props} />
}
