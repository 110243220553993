import { Dialog } from '@strise/system'
import * as React from 'react'
import { t } from '@lingui/macro'
import { type SupportedSidepanelEntityFragment } from '@components/Sidepanel/utils/sidepanelUtils'
import { Ownerships } from '@components/Ownerships/Ownerships'

interface OwnershipsDialogProps {
  above25: boolean
  entity: SupportedSidepanelEntityFragment
  showOriginal: boolean
  toggleFullScreen: () => void
}

export const OwnershipsModal = ({ above25, entity, showOriginal, toggleFullScreen }: OwnershipsDialogProps) => {
  const [scrollYEl, setScrollYEl] = React.useState<HTMLDivElement | null>(null)
  const content = (
    <div className='flex justify-center'>
      <Ownerships
        className='p-4'
        key={`percentage-${String(above25)}-full-screen`}
        showOriginal={showOriginal}
        editMode={false}
        above25={above25}
        entity={entity}
        noFlags={false}
        noSubsidiaryCount={false}
        noStatus={false}
        isExport={false}
        dragOpts={{ scrollYEl }}
        noLink
      />
    </div>
  )

  return (
    <Dialog
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={true}
      contentMaxWidth='calc(100vw - 128px)'
      ref={setScrollYEl}
      title={t`Ownership (preview only)`}
      isOpen
      onRequestClose={toggleFullScreen}
      onClose={toggleFullScreen}
      body={content}
      headerProps={{ closeButtonProps: { className: 'text-text-primary' } }}
      contentProps={{ width: 'auto', minWidth: 800 }}
      modalContentProps={{ width: 'auto', className: 'overflow-auto' }}
    />
  )
}
