import { formatShare } from '@strise/fika'
import { defineMessage } from '@lingui/macro'
import { BeneficialOwnerRelationshipKind } from '@strise/types'
import { type MessageDescriptor } from '@lingui/core'

const danishOwnershipRanges: Record<number, string> = {
  0: '0-4.9%',
  5: '5-9.9%',
  10: '10-14.9%',
  15: '15-19.9%',
  20: '20-24.9%',
  25: '25-33.2%',
  33.33: '33.3-49.9%',
  50: '50-66.6%',
  66.67: '66.7-89.9%',
  90: '90-99.9%',
  100: '100%'
}

export const getDanishOwnershipRange = (percentage: number) => {
  return danishOwnershipRanges[percentage] || formatShare(percentage, { style: 'percent' }).short
}

export const beneficialOwnerRelationKindToTitle: { [key in BeneficialOwnerRelationshipKind]?: MessageDescriptor } = {
  [BeneficialOwnerRelationshipKind.HasParent]: defineMessage({ message: 'Parent' }),
  [BeneficialOwnerRelationshipKind.HasChild]: defineMessage({ message: 'Child' }),
  [BeneficialOwnerRelationshipKind.HasSpouseOrPartner]: defineMessage({ message: 'Spouse/Partner' }),
  [BeneficialOwnerRelationshipKind.HasChildInLaw]: defineMessage({ message: 'Child in law' }),
  [BeneficialOwnerRelationshipKind.SameAddress]: defineMessage({ message: 'Same address' }),
  [BeneficialOwnerRelationshipKind.SameLastName]: defineMessage({ message: 'Same last name' })
}
export const OWNERSHIP_NODE_WIDTH = {
  default: 200,
  wide: 350
}
export const OWNERSHIP_NODE_HEIGHT = 80

export const OWNERSHIP_CONNECTOR_Z_INDEX = 20
export const OWNERSHIP_NODE_Z_INDEX = 30
