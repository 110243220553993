import { type ButtonProps } from '@strise/midgard'

export enum UserRoleEnum {
  TeamMember = '7bee6f12-fd6c-424d-aae5-fe330c1fca2c',
  Admin = 'ca5b5c64-7e70-4646-b21c-e4000bdec740',
  TeamManager = '1253a99a-7a4d-421c-959c-77fdd119ad4c',
  Supervisor = '910b089e-3780-4ea8-9d49-574866e26353'
}

interface PaletteAndClasses {
  classes?: string
  palette: ButtonProps['palette']
}
export const userRoleToPalette = (role: string): PaletteAndClasses => {
  switch (role as UserRoleEnum) {
    case UserRoleEnum.TeamMember: {
      return { palette: 'primary' }
    }
    case UserRoleEnum.Admin: {
      return { palette: 'secondary' }
    }
    case UserRoleEnum.TeamManager: {
      return { palette: 'tertiary', classes: 'bg-accent-orange-main' }
    }
    case UserRoleEnum.Supervisor: {
      return { palette: 'tertiary', classes: 'bg-accent-purple-main text-white' }
    }
    default: {
      return { palette: 'tertiary' }
    }
  }
}

export const userRoleToAccessLevel = (role: string): number => {
  switch (role as UserRoleEnum) {
    case UserRoleEnum.Admin: {
      return 4
    }
    case UserRoleEnum.Supervisor: {
      return 3
    }
    case UserRoleEnum.TeamManager: {
      return 2
    }
    case UserRoleEnum.TeamMember: {
      return 1
    }
    default: {
      return 0
    }
  }
}

export const userRole = (role: string) => {
  const higherThanOrAdmin = (compareRole: string) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    userRoleToAccessLevel(role) > userRoleToAccessLevel(compareRole) || role === UserRoleEnum.Admin
  const higherThanOrEquals = (compareRole: string) => userRoleToAccessLevel(role) >= userRoleToAccessLevel(compareRole)
  const isAdmin = () => higherThanOrEquals(UserRoleEnum.Admin)
  const isSupervisor = () => higherThanOrEquals(UserRoleEnum.Supervisor)

  return {
    higherThanOrAdmin,
    higherThanOrEquals,
    isAdmin,
    isSupervisor
  }
}
