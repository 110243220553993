import * as React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import { IconCheckSmall, IconChevronDown, IconChevronDownSuperSmall, IconChevronUp } from '../icons/general'
import { Button, type ButtonProps } from './Button'
import { cn } from '../utils/className'
import { Typography } from './Typography'
import { type DataProps, type StyleProps } from '@strise/react-utils'
import { basePopperClasses } from './variants/common'

export interface SelectProps<T extends string = string>
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root>,
    Pick<ButtonProps, 'variant' | 'palette' | 'loading'>,
    DataProps,
    StyleProps {
  /** Aria label for the select, use if there is no visible label in use */
  ariaLabel?: string
  /** Props for the wrapper around the content, see https://www.radix-ui.com/primitives/docs/components/select#content for available props  */
  contentProps?: SelectPrimitive.SelectContentProps & DataProps
  onValueChange?: (value: T) => void
  /** Options can either be supplied by children or by options, a list of SelectItemProps that will each render a selectItem */
  options?: SelectItemProps[]
  /** Content that will be displayed if no selection has been made */
  placeholder?: React.ReactNode
  /** Use if you need a different icon than the default chevron */
  triggerIcon?: React.ReactNode
  /** By default the trigger icon is always displayed. Use this if you want it to be displayed only on hover, or only if the select is open */
  triggerIconVisibility?: 'hover' | 'open'
  /** By default, the trigger will display the children of the selected item,
   * if no item is selected it will display the placeholder.
   * Use this prop if you want to display something else in the trigger instead */
  triggerText?: React.ReactNode
  value?: T
}

export const SelectInner = <T extends string>(
  {
    ariaLabel,
    children,
    className,
    contentProps,
    loading,
    onValueChange,
    options,
    palette = 'secondary',
    placeholder,
    triggerIcon,
    triggerIconVisibility,
    triggerText,
    value,
    variant = 'contained',
    ...props
  }: SelectProps<T>,
  ref: React.ForwardedRef<React.ElementRef<typeof SelectPrimitive.Trigger>>
) => {
  return (
    <SelectPrimitive.Root
      {...props}
      onValueChange={(v) => {
        // This is how we can have a select item reset the select to the placeholder value
        // https://github.com/radix-ui/primitives/pull/2174
        if (v === 'none') {
          onValueChange?.('' as T)
        } else {
          onValueChange?.(v as T)
        }
      }}
      value={value === 'none' ? '' : value}
    >
      <SelectPrimitive.Trigger data-id={props['data-id']} ref={ref} asChild>
        <Button
          variant={variant}
          palette={palette}
          loading={loading}
          endIcon={
            <SelectPrimitive.Icon
              asChild
              className={cn(
                'ml-2 shrink-0',
                triggerIconVisibility === 'hover' && 'opacity-0 group-hover/trigger:opacity-100',
                triggerIconVisibility === 'open' && 'opacity-0 group-data-[state=open]/trigger:opacity-100'
              )}
            >
              {triggerIcon ?? <IconChevronDownSuperSmall className='group-data-[state=open]/trigger:rotate-180' />}
            </SelectPrimitive.Icon>
          }
          // TODO:
          // Setting `focus-visible:ring-0` for now, as the focus always triggers on mouse events at the moment (selecting item or click outside popover).
          // Radix currently has no API to handle this. We can consider adding some functionality for manually blurring the button on state changes.
          // https://github.com/radix-ui/primitives/issues/1803
          className={cn('group/trigger w-fit px-2 focus-visible:ring-0', className)}
          aria-label={ariaLabel}
        >
          {triggerText ?? <SelectPrimitive.Value placeholder={placeholder} />}
        </Button>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          position='popper'
          align='end'
          {...contentProps}
          className={cn(basePopperClasses.content, basePopperClasses.shadow, contentProps?.className)}
        >
          <SelectPrimitive.ScrollUpButton className='flex h-4 cursor-default items-center justify-center'>
            <IconChevronUp />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport>
            {options ? options.map((option) => <SelectItem key={option.value} {...option} />) : children}
          </SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton className='flex h-4 cursor-default items-center justify-center'>
            <IconChevronDown />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  )
}

SelectInner.displayName = 'Select'
export const Select = React.forwardRef(SelectInner) as <T extends string>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<React.ElementRef<typeof SelectPrimitive.Trigger>> }
) => ReturnType<typeof SelectInner>

interface SelectItemProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>, DataProps {}

export const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, SelectItemProps>(
  ({ children, className, disabled, ...props }, ref) => {
    return (
      <SelectPrimitive.Item
        className={cn(basePopperClasses.item, { [basePopperClasses.disabledItem]: disabled }, className)}
        disabled={disabled}
        {...props}
        ref={ref}
      >
        <SelectPrimitive.ItemText asChild>
          <Typography variant='aLabel' className='flex w-full items-center'>
            {children}
          </Typography>
        </SelectPrimitive.ItemText>
        <SelectPrimitive.ItemIndicator>
          <IconCheckSmall className='ml-2' />
        </SelectPrimitive.ItemIndicator>
      </SelectPrimitive.Item>
    )
  }
)

SelectItem.displayName = 'Select'

export const ResetItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  Omit<SelectItemProps, 'value'>
>((props, ref) => <SelectItem {...props} value='none' ref={ref} />)

ResetItem.displayName = 'ResetItem'
