import * as React from 'react'
import { type BigNumber, formatShare } from '@strise/fika'

export const Share: React.FC<{
  asDecimal?: boolean
  percentage: number | BigNumber
}> = ({ asDecimal, percentage }) => {
  const formatted = React.useMemo(
    () => formatShare(percentage, { style: asDecimal ? 'decimal' : 'percent' }),
    [percentage, asDecimal]
  )
  return <span title={formatted.long}>{formatted.short}</span>
}
