import { i18n, type MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import React from 'react'
import { getTitle } from '@utils/enum'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { Chip, cn } from '@strise/midgard'
import { EntityLocationFilterKind } from '@components/Search/searchUtils'

const entityLocationFilterToTitle: {
  [key in EntityLocationFilterKind]: MessageDescriptor
} = {
  [EntityLocationFilterKind.ALL]: defineMessage({ message: 'All' }),
  [EntityLocationFilterKind.NORDICS]: defineMessage({ message: 'Nordics' }),
  [EntityLocationFilterKind.UK]: defineMessage({ message: 'UK' }),
  [EntityLocationFilterKind.GLOBAL]: defineMessage({ message: 'Global' })
}

export const SearchEntityLocationFilter = ({
  setState,
  state
}: {
  setState: SetStateFn<EntityLocationFilterKind>
  state: EntityLocationFilterKind
}) => {
  const features = useCurrentUserFeatures()

  const filteredLocations = Object.values(EntityLocationFilterKind).filter((value) => {
    if (value === EntityLocationFilterKind.GLOBAL) return features.GLOBAL_ENTITIES
    if (value === EntityLocationFilterKind.UK) return features.CONTENT_UNITED_KINGDOM
    if (value === EntityLocationFilterKind.NORDICS) {
      return (
        features.CONTENT_DANISH || features.CONTENT_NORWEGIAN || features.CONTENT_SWEDISH || features.CONTENT_FINNISH
      )
    }
    return true
  })

  // We only want to show location filter if we have all + at least two others
  if (filteredLocations.length < 3) return null

  return (
    <div className='flex p-2 pt-4'>
      {filteredLocations.map((value) => {
        const title = getTitle(entityLocationFilterToTitle[value])
        const isActive = state === value
        return (
          <Chip
            className={cn('mx-2 rounded-[4px]', isActive ? '' : 'bg-secondary-shade-5')}
            key={value}
            variant='contained'
            palette={isActive ? 'primary' : 'tertiary'}
            label={i18n._(title)}
            onClick={() => setState(value)}
          />
        )
      })}
    </div>
  )
}
