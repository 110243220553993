import { Divider, Typography } from '@strise/midgard'
import { type TransformedApiTable } from '@utils/apiTable/apiTableUtils'
import { type BaseCheckboxSectionProps } from '@utils/reviewUtils'
import * as React from 'react'
import { ReviewRow } from './ReviewRow'
import { isString } from 'lodash-es'

export const ReviewTable = ({
  checkedState,
  companyId,
  disabled,
  errors,
  inlineComments,
  loading,
  setInlineComments,
  table,
  teamReviewSettings,
  updateCheckedState
}: BaseCheckboxSectionProps & { table: TransformedApiTable }) => {
  return (
    <>
      {table.title && (
        <Typography
          className='mb-2 mt-4 px-4'
          variant='subtitle1'
          data-id={`Review / ${isString(table.title) ? table.title : ''}`}
        >
          {table.title}
        </Typography>
      )}

      <Divider />
      <div>
        {table.rows.map((row) => {
          return (
            <ReviewRow
              key={row.key}
              row={row}
              checkedState={checkedState}
              companyId={companyId}
              disabled={disabled}
              errors={errors}
              updateCheckedState={updateCheckedState}
              loading={loading}
              teamReviewSettings={teamReviewSettings}
              inlineComments={inlineComments}
              setInlineComments={setInlineComments}
            />
          )
        })}
      </div>
    </>
  )
}
