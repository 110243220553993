import { Button, Divider, LoaderRound, Typography } from '@strise/midgard'
import { useOwners } from '@components/Ownerships/ownershipChartUtils'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useCompanyReviewsQuery, useCreateReviewMutation, useTeamReviewSettingsQuery } from '@graphqlOperations'
import { type BaseReviewCompanyFragment, type TeamReviewSettingsV2Fragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { SidepanelCardLoader } from '@loaders/SidepanelContentLoader'
import { Empty, formatDate, getElementAsEncodedBase64, toast, useContext, useTablePagination } from '@strise/europa'
import { Pagination } from '@strise/europa/src/i18n'
import { type DivProps } from '@strise/react-utils'
import { AuditRisk, ReviewSettingKind, ReviewStatus, TrackedActivityKind } from '@strise/types'
import { track } from '@utils/tracking'
import React from 'react'
import { AuditRiskChip } from '../../AuditRiskChip'
import { AuditRiskSelect } from '../../AuditRiskSelect'
import { DownloadReviewPdf } from '../../Review/DownloadReviewPdf'
import { SidepanelCard } from '../SidepanelCard'
import { SidepanelContext } from '../SidepanelContext/SidepanelContext'

const SelectAuditRisk = ({
  className,
  company,
  fetchHistory,
  ownershipsElement,
  teamReviewSettings,
  ...props
}: {
  company: BaseReviewCompanyFragment
  fetchHistory: () => void
  ownershipsElement: React.MutableRefObject<HTMLElement | null>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
} & DivProps) => {
  const [loading, setLoading] = React.useState(false)
  const features = useCurrentUserFeatures()
  const owners = useOwners(company, 5, false)

  const currentRisk = company.reviews.edges[0]?.node.risk ?? AuditRisk.None
  const [risk, setRisk] = React.useState<AuditRisk>(currentRisk)

  const onCompleted = () => {
    toast.success(features.EMAIL_REVIEW ? t`Review created. The report is sent to your email.` : t`Review created`)
    fetchHistory()
  }
  const [create, { loading: mutationLoading }] = useCreateReviewMutation({ onCompleted })

  React.useEffect(() => {
    if (mutationLoading) return
    setLoading(false)
  }, [mutationLoading])

  const isRiskAssessmentEnabled = teamReviewSettings.settings.find(
    (setting) => setting.kind === ReviewSettingKind.EnableRiskAssessmentValue
  )?.enabled

  const handleSubmit = async () => {
    setLoading(true)
    const auditRisk = isRiskAssessmentEnabled ? risk : AuditRisk.None
    const organizationOverviewImage = await getElementAsEncodedBase64(ownershipsElement.current, {
      name: 'Sidepanel Review ownership',
      id: company.id
    })

    create({
      variables: {
        entity: company.id,
        input: {
          reviewStatus: ReviewStatus.Approved,
          auditRisk,
          organizationOverviewImage
        }
      }
    })
    track(TrackedActivityKind.StriseReviewCreated, {
      companyId: company.id,
      legalForm: company.legalForm,
      auditRisk: AuditRisk.None,
      reviewStatus: ReviewStatus.Approved,
      hasCustomOwnership: owners.hasCustomOwners,
      origin: 'SIDEPANEL_REVIEW_CARD'
    })
  }

  const handleRiskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRisk(event.target.value as AuditRisk)
  }

  return (
    <div className={className} {...props}>
      <div className='flex flex-wrap items-center'>
        {isRiskAssessmentEnabled && (
          <div className='mr-3'>
            <AuditRiskSelect risk={risk} updateRisk={handleRiskChange} />
          </div>
        )}
        <Button
          variant='contained'
          palette='secondary'
          onClick={handleSubmit}
          disabled={loading || mutationLoading}
          data-track='Button / Sidepanel / Review / Save'
        >
          <Trans>Save</Trans>
        </Button>
      </div>
      {(loading || mutationLoading) && (
        <Typography className='mt-2 flex items-center' component='div'>
          <LoaderRound size='sm' className='mr-2 shrink-0' />
          {features.EMAIL_REVIEW ? (
            <Trans>
              This may take some time. You can safely continue using the app, and you will receive an email when the
              report is finished.
            </Trans>
          ) : (
            <Trans>This may take some time. You can safely continue using the app.</Trans>
          )}
        </Typography>
      )}
    </div>
  )
}

const ReviewCardContent = ({
  id,
  ownershipsElement,
  pageSize = 6,
  teamReviewSettings,
  teamSettingsLoading
}: {
  id: string
  ownershipsElement: React.MutableRefObject<HTMLElement | null>
  pageSize?: number
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2'] | null
  teamSettingsLoading: boolean
}) => {
  const { data, loading, refetch } = useCompanyReviewsQuery({ variables: { entity: id } })

  const company = data?.company
  const reviewHistory = company?.reviews.edges ?? []

  const { currentPageRows, pageIndex, setPageIndex } = useTablePagination(reviewHistory, pageSize, [pageSize])

  if (!company || loading || !teamReviewSettings || teamSettingsLoading) return <SidepanelCardLoader />

  const isRiskAssessmentEnabled = teamReviewSettings.settings.find(
    (setting) => setting.kind === ReviewSettingKind.EnableRiskAssessmentValue
  )?.enabled

  return (
    <div className='space-y-4 p-4'>
      <SelectAuditRisk
        company={company}
        fetchHistory={refetch}
        ownershipsElement={ownershipsElement}
        teamReviewSettings={teamReviewSettings}
      />
      <div>
        <Divider />
      </div>
      {isRiskAssessmentEnabled && !reviewHistory.length && (
        <Empty title={t`No risk assessments yet`}>
          <Trans>You can change the risk level above.</Trans>
        </Empty>
      )}

      {!!reviewHistory.length && (
        <Typography component='div' variant='body1'>
          {currentPageRows.map(({ node: review }, index) => {
            const numOfCells = isRiskAssessmentEnabled ? 4 : 3
            const widthCell = `${(1 / numOfCells) * 100}%`

            return (
              <div className='flex items-center' key={index}>
                <div style={{ width: widthCell }}>{formatDate(review.created, { relative: false, time: true })}</div>
                <div style={{ width: widthCell }}>{review.user?.name ?? ''}</div>
                {isRiskAssessmentEnabled && (
                  <div style={{ width: widthCell }}>
                    <AuditRiskChip auditRisk={review.risk} />
                  </div>
                )}

                <div style={{ width: widthCell }}>
                  <DownloadReviewPdf review={review} hideContactSales />
                </div>
              </div>
            )
          })}
        </Typography>
      )}

      {!!reviewHistory.length && (
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalResults={reviewHistory.length}
          totalSeparator={t`of`}
          totalSuffix={t`risk assessments`}
          setPageIndex={setPageIndex}
        />
      )}
    </div>
  )
}

export const SidepanelReviewCard: React.FC<{
  ownershipsElement: React.MutableRefObject<HTMLElement | null>
}> = ({ ownershipsElement }) => {
  const { id } = useContext(SidepanelContext)
  const { data: teamSettingsData, loading: teamSettingsLoading } = useTeamReviewSettingsQuery({ variables: {} })
  const title = t`Risk assessment`

  return (
    <SidepanelCard className='self-start' title={title} data-id='Sidepanel / Risk assessment'>
      <ReviewCardContent
        key={id}
        id={id}
        ownershipsElement={ownershipsElement}
        teamReviewSettings={teamSettingsData?.team.teamSettings.reviewSettingsV2 ?? null}
        teamSettingsLoading={teamSettingsLoading}
      />
    </SidepanelCard>
  )
}
