import { Button, cn, LoaderRound, Typography } from '@strise/midgard'
import { OwnershipsContext } from '@components/Ownerships/OwnershipsContext/OwnershipsContext'
import { useEntityOwnershipsQuery } from '@graphqlOperations'
import {
  type BaseEntityLikeFragment,
  type ShareholderConnectionEdgeFragment,
  type ShareholderFragment
} from '@graphqlTypes'
import { t } from '@lingui/macro'
import { useContext } from '@strise/europa'
import { BigNumber, formatShare, objectEntries } from '@strise/fika'
import { usePalette } from '@strise/system'
import { groupBy, orderBy } from 'lodash-es'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { DataSourceTooltip } from '../DataSourceTooltip'
import { Share } from '../Share'
import { OwnershipEntity } from './OwnershipEntity'
import { getDanishOwnershipRange } from './ownershipUtils'
import { type DivProps } from '@strise/react-utils'

const Line = ({ className, ...props }: DivProps) => (
  <div className={cn('absolute inset-y-0 left-0 z-[1] w-px bg-secondary-shade-40', className)} {...props} />
)

const LineBox: React.FC<{
  children: React.ReactNode
  lastInLevels: boolean[]
  level: number
}> = ({ children, lastInLevels, level, ...props }) => {
  const secondary40 = usePalette('secondary.40')

  return (
    <div className={cn('relative flex items-center pt-4')} style={{ marginLeft: `${level * 48}px` }} {...props}>
      {lastInLevels.slice(0, -1).map((isLast, index) => {
        return !isLast && <Line key={index} style={{ left: -(4 * 12) * (lastInLevels.length - 1 - index) }} />
      })}
      <Line
        style={
          lastInLevels[level - 1]
            ? {
                background: `linear-gradient(${secondary40} calc(100% - 35px), transparent 35px)`
              }
            : undefined
        }
      />

      {children}
    </div>
  )
}

const Subsidiary: React.FC<{
  company: ShareholderConnectionEdgeFragment
  indirectSharePercentage: BigNumber
  lastInLevels: boolean[]
  level: number
  path: string[]
  setLoading?: (n: number) => void
}> = ({ company, indirectSharePercentage, lastInLevels, level, path, setLoading }) => {
  const { danish } = useContext(OwnershipsContext)
  const formattedIndirectOwnership = danish
    ? getDanishOwnershipRange(indirectSharePercentage.toNumber())
    : formatShare(indirectSharePercentage, { style: 'percent' }).short

  return (
    <>
      <LineBox level={level} lastInLevels={lastInLevels}>
        <Typography className='ml-[-71px] mr-2 w-16 pr-1 text-right text-text-secondary' variant='body3'>
          {!danish && <Share percentage={company.sharePercentage} />}
          {danish && getDanishOwnershipRange(company.sharePercentage)}
        </Typography>
        <div className='z-[2] ml-[-6px] size-3 rounded-[2px] border border-solid border-secondary-shade-40 bg-background-paper' />

        <OwnershipEntity
          entity={company.node}
          uncertain={company.isUncertain}
          ownership={formattedIndirectOwnership}
          subsidiary
          isCustom={false}
        />
      </LineBox>

      {/* Prevent infinite loop if a company is already in the tree */}
      {!path.includes(company.node.id) && (
        <OwnershipSubsidiaries
          path={[...path, company.node.id]}
          entity={company.node}
          indirectSharePercentage={indirectSharePercentage}
          level={level + 1}
          lastInLevels={lastInLevels}
          setLoading={setLoading}
          numOwnerships={company.node.numOwnerships}
        />
      )}
    </>
  )
}

enum SubsidiariesGroup {
  STATIC = 'STATIC',
  COLLAPSIBLE = 'COLLAPSIBLE'
}

interface OwnershipSubsidiariesProps {
  entity: BaseEntityLikeFragment | ShareholderFragment
  indirectSharePercentage: BigNumber
  lastInLevels: boolean[]
  level: number
  path: string[]
  setLoading?: (n: number) => void
}

const ExpandButton = ({
  collapsed,
  lastInLevels,
  level,
  numOwnerships,
  toggleCollapsed
}: {
  collapsed: boolean
  lastInLevels: boolean[]
  level: number
  numOwnerships: number | undefined
  toggleCollapsed: () => void
}) => {
  if (!numOwnerships) return null

  return (
    <LineBox level={level} lastInLevels={[...lastInLevels, true]}>
      <DataSourceTooltip content={collapsed ? t`Show more` : t`Show less`}>
        <Button
          variant='contained'
          className='relative z-10 -ml-4 size-8 rounded-full px-0 text-sm'
          palette='tertiary'
          onClick={toggleCollapsed}
          data-track='Sidepanel / Ownerships / Expand subsidiaries'
        >
          {collapsed ? `+${numOwnerships}` : '-'}
        </Button>
      </DataSourceTooltip>
    </LineBox>
  )
}

const OwnershipSubsidiariesContent = React.memo(
  ({
    collapsed,
    entity,
    indirectSharePercentage,
    lastInLevels,
    level,
    path,
    setLoading,
    toggleCollapsed,
    ...props
  }: OwnershipSubsidiariesProps & { collapsed: boolean; toggleCollapsed: () => void }) => {
    const { data, loading } = useEntityOwnershipsQuery({ variables: { id: entity.id } })

    const groupedOwnershipEdges = React.useMemo(() => {
      const edges = data?.entity && 'ownerships' in data.entity ? data.entity.ownerships.edges : []

      const filtered = edges.filter((edge) => {
        // If a company owns itself, don't show it as a direct sub.
        if (level === 1) {
          return edge.node.id !== entity.id
        }
        return true
      })

      const sorted = orderBy(filtered, (a) => -a.sharePercentage)

      const grouped = groupBy(sorted, (company) => {
        const shares = new BigNumber(company.sharePercentage).times(indirectSharePercentage).dividedBy(100)

        if (level === 1 && shares.isGreaterThan(50)) return SubsidiariesGroup.STATIC
        return SubsidiariesGroup.COLLAPSIBLE
      })

      return grouped
    }, [data])

    React.useEffect(() => {
      if (setLoading) {
        setLoading(loading ? 0 : Date.now())
      }
    }, [loading])

    if (loading) {
      return (
        <LineBox level={level} lastInLevels={[...lastInLevels, true]}>
          <div className='z-10 -ml-3 bg-white p-[3px]'>
            <LoaderRound palette='white' size='sm' />
          </div>
        </LineBox>
      )
    }

    if (Object.entries(groupedOwnershipEdges).length === 0) return null

    return (
      <div {...props}>
        {objectEntries(groupedOwnershipEdges).map(([group, groupEdges]) => {
          if (!groupEdges.length) return null

          return (
            <React.Fragment key={group}>
              {group === SubsidiariesGroup.COLLAPSIBLE && (
                <ExpandButton
                  level={level}
                  lastInLevels={lastInLevels}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  numOwnerships={groupEdges.length}
                />
              )}

              {(group === SubsidiariesGroup.STATIC || !collapsed) &&
                groupEdges.map((sortCompany, index) => {
                  const lastSubsidiary = index + 1 === groupEdges.length
                  const lastInLevel =
                    group === SubsidiariesGroup.STATIC
                      ? lastSubsidiary && !groupedOwnershipEdges[SubsidiariesGroup.COLLAPSIBLE]?.length
                      : lastSubsidiary

                  return (
                    <Subsidiary
                      key={sortCompany.node.id}
                      path={path}
                      company={sortCompany}
                      indirectSharePercentage={new BigNumber(sortCompany.sharePercentage)
                        .times(indirectSharePercentage)
                        .dividedBy(100)}
                      level={level}
                      lastInLevels={[...lastInLevels, lastInLevel]}
                    />
                  )
                })}
            </React.Fragment>
          )
        })}
      </div>
    )
  }
)

export const OwnershipSubsidiaries = ({
  entity,
  indirectSharePercentage,
  lastInLevels,
  level,
  numOwnerships,
  path,
  setLoading,
  ...props
}: OwnershipSubsidiariesProps & { numOwnerships: number | undefined }) => {
  const [collapsed, toggleCollapsed] = useToggle(true)

  if (level > 1 && collapsed) {
    return (
      <ExpandButton
        level={level}
        lastInLevels={lastInLevels}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        numOwnerships={numOwnerships}
      />
    )
  }

  return (
    <OwnershipSubsidiariesContent
      collapsed={collapsed}
      toggleCollapsed={toggleCollapsed}
      path={path}
      entity={entity}
      indirectSharePercentage={indirectSharePercentage}
      level={level}
      lastInLevels={lastInLevels}
      setLoading={setLoading}
      {...props}
    />
  )
}
