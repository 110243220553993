import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagGb = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagGB_svg__a)'>
      <path
        fill='#EEE'
        d='m1.033 1 .343.945-.343.989v.988l1.375 2.32-1.375 2.32v1.376L2.408 12l-1.375 2.063v1.374l1.375 2.32-1.375 2.321V23l.945-.344.988.344h.989l2.32-1.375L8.595 23H9.97l2.063-1.375L14.095 23h1.375l2.32-1.375L20.11 23h2.923l-.344-.945.344-.989v-.988l-1.375-2.32 1.375-2.32v-1.376L21.658 12l1.375-2.063V8.563l-1.375-2.32 1.375-2.321V1l-.946.344L21.1 1h-.988l-2.32 1.375L15.47 1h-1.376l-2.062 1.375L9.97 1H8.595l-2.32 1.375L3.955 1H1.033Z'
      />
      <path
        fill='#0052B4'
        d='M15.47 1v4.64L20.11 1h-4.64Zm7.563 2.922-4.64 4.64h4.64v-4.64Zm-22 4.64h4.64l-4.64-4.64v4.64ZM3.955 1l4.64 4.64V1h-4.64Zm4.64 22v-4.64L3.955 23h4.64Zm-7.562-2.922 4.64-4.64h-4.64v4.64Zm22-4.64h-4.64l4.64 4.64v-4.64ZM20.11 23l-4.64-4.64V23h4.64Z'
      />
      <path
        fill='#D80027'
        d='M1.033 1v1.934l5.629 5.628h1.933L1.033 1ZM9.97 1v8.938H1.033v4.124H9.97V23h4.125v-8.938h8.938V9.939h-8.938V1H9.97ZM21.1 1l-5.63 5.629v1.934L23.033 1h-1.934ZM8.594 15.438 1.033 23h1.933l5.63-5.629v-1.933Zm6.875 0L23.033 23v-1.934l-5.63-5.628H15.47Z'
      />
    </g>
    <defs>
      <clipPath id='FlagGB_svg__a'>
        <rect width={22} height={22} x={1.033} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagGb.displayName = 'FlagGb'
