import React from 'react'
import { SidepanelCard } from '../SidepanelCard'
import { type SidepanelGlobalCompanyFragment } from '@graphqlTypes'
import { SidepanelEntityKeyMetaDetails } from '../SidepanelEntityKeyMetaDetails'
import { SidepanelGlobalEntityBanner } from './SidepanelGlobalEntityBanner'
import { SidepanelHasAccessAlert, SidepanelInactiveAlert, SidepanelSanctionsAlert } from '../SidepanelEntityAlerts'
import { useIsMobile } from '@utils/hooks'
import { EntityLink } from '../../EntityLink/EntityLink'
import { SidepanelTab } from '@strise/strise-utils'

interface MetaCardContentProps {
  globalCompany: SidepanelGlobalCompanyFragment
}

const HeaderCardContent: React.FC<MetaCardContentProps> = ({ globalCompany }) => {
  const mobile = useIsMobile()

  return (
    <>
      <SidepanelHasAccessAlert entity={globalCompany} />

      <SidepanelInactiveAlert entity={globalCompany} />

      {!mobile && <SidepanelGlobalEntityBanner />}

      <div className='flex flex-col gap-4 p-4'>
        <div>
          <EntityLink
            variant='h3'
            entity={globalCompany}
            iconWrapperProps={{
              className: 'p-2 pl-0 mr-1'
            }}
            withIcon
            noTooltip
            noLink
            sanctionsLink
          />
        </div>
        <div>
          <SidepanelEntityKeyMetaDetails entity={globalCompany} />
          <SidepanelSanctionsAlert className='mt-2' entityId={globalCompany.id} />
        </div>
      </div>
    </>
  )
}

type MetaCardProps = Omit<MetaCardContentProps, 'globalCompany'> & {
  globalCompany: SidepanelGlobalCompanyFragment
}

export const SidepanelGlobalCompanyHeaderCard = React.forwardRef<HTMLDivElement, MetaCardProps>(
  ({ globalCompany, ...props }, ref) => {
    return (
      <SidepanelCard className='relative' ref={ref} tab={SidepanelTab.Company}>
        <HeaderCardContent globalCompany={globalCompany} {...props} />
      </SidepanelCard>
    )
  }
)
