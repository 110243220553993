import { Typography } from '@strise/midgard'
import { SidepanelTransformedTableRow } from '@components/Sidepanel/SidepanelTransformedTableRow'
import { type BaseEntityLikeFragment, type TableFragment } from '@graphqlTypes'
import { Trans } from '@lingui/macro'
import { useTransformedTable } from '@utils/apiTable/apiTableValueBaseUtils'
import React from 'react'
import { SidepanelCard, type SidepanelCardProps } from './SidepanelCard'
import { DataSources } from '@components/Sidepanel/DataSources'
import { type TransformedApiTableRow } from '@utils/apiTable/apiTableUtils'

interface SidepanelTableCardProps extends SidepanelCardProps {
  additionalRows?: TransformedApiTableRow[]
  entity: BaseEntityLikeFragment
  hideSources?: boolean
  table: TableFragment
  trackId: string
}

export const SidepanelTableCard = React.forwardRef<HTMLDivElement, SidepanelTableCardProps>(
  ({ additionalRows, className, entity, hideSources, table, trackId, ...props }, ref) => {
    const transformedTable = useTransformedTable(entity, table)

    const rows = [...(transformedTable?.rows ?? []), ...(additionalRows ?? [])]

    const hasRows = !!rows.length

    return (
      <SidepanelCard
        className={className}
        title={transformedTable?.title}
        description={transformedTable?.description}
        ref={ref}
        {...props}
      >
        <div className='px-4'>
          {!hasRows && (
            <Typography variant='body1' className='text-secondary-shade-40'>
              <Trans>No data</Trans>
            </Typography>
          )}
          {hasRows &&
            rows.map((row, index) => {
              const isLast = index === rows.length - 1
              return <SidepanelTransformedTableRow key={index} row={row} hideDivider={isLast} context={trackId} />
            })}
        </div>
        {!hideSources && <DataSources dataSources={table.dataSources} />}
      </SidepanelCard>
    )
  }
)
