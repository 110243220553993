import {
  companyInPortfolio,
  type SelectCompaniesStatusProps,
  type SelectCompany,
  updateCompanyStatusCache
} from './companyStatusUtils'
import { toast, useUser } from '@strise/europa'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import React, { useCallback, useRef } from 'react'
import { type TeamCompanyStatusFragment, type UpdateCompanyStatusesMutation } from '@graphqlTypes'
import { lastAddedCompanyState, refreshReviewState } from '@state'
import { useUpdateCompanyStatusesMutation } from '@graphqlOperations'
import {
  type CompanyStatus,
  type PortfolioCompanyConnectionEdge,
  TrackedActivityKind,
  CompanyStatusModifiedByKind
} from '@strise/types'
import { t } from '@lingui/macro'
import { CompaniesStatusSelect } from './CompaniesStatusSelect'

export const useSelectCompaniesStatus = (companies: SelectCompany[], portfolioId?: string, teamId?: string) => {
  const user = useUser()
  const { id: currentTeamId, portfolioId: currentPortfolioId } = useTeam()
  const team = teamId || currentTeamId
  const portfolio = portfolioId || currentPortfolioId

  const companyIds = companies.map((company) => company.id)

  const prevStatus = useRef(companies[0]?.statusV2?.status)

  const handleCompleted = (data: UpdateCompanyStatusesMutation) => {
    refreshReviewState(refreshReviewState() + 1)
    if (companyIds.length === 1) {
      if (!companyInPortfolio(prevStatus.current)) {
        lastAddedCompanyState(companyIds[0])
      }
      prevStatus.current = data.updateCompanyStatuses?.team.portfolio?.companies.edges[0]?.node.statusV2?.status
    }
  }

  const [update, { error: updateError, loading }] = useUpdateCompanyStatusesMutation({
    onCompleted: handleCompleted
  })

  const updateCompanyStatus = useCallback(
    async (status: CompanyStatus | null, previousStatus: CompanyStatus | null, showToast = true) => {
      const removed = !companyInPortfolio(status)
      const modifiedAt = new Date()
      const statusV2 = status
        ? ({
            status,
            previousStatus,
            modifiedAt: modifiedAt.toISOString(),
            modifiedBy: {
              ...user,
              __typename: 'SimpleUser'
            },
            modifiedByKind: CompanyStatusModifiedByKind.User,
            __typename: 'TeamCompanyStatus'
          } satisfies TeamCompanyStatusFragment)
        : null
      const optimisticEdges = removed
        ? []
        : (companyIds.map((id) => ({
            node: { id, statusV2, __typename: 'Company' },
            __typename: 'PortfolioCompanyConnectionEdge'
          })) as PortfolioCompanyConnectionEdge[])

      await update({
        variables: {
          status,
          companies: companyIds,
          portfolio,
          team
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateCompanyStatuses: {
            team: {
              id: team,
              portfolio: {
                id: portfolio,
                companies: {
                  edges: optimisticEdges,
                  __typename: 'PortfolioCompanyConnection'
                },
                __typename: 'Portfolio'
              },
              __typename: 'Team'
            },
            __typename: 'MutationQuery'
          }
        },
        update: (cache, { data }) => {
          const companyEdges = data?.updateCompanyStatuses?.team.portfolio?.companies.edges ?? []

          // The api does not respond any result if status is `ignored` or `null` (removed from portfolio)
          if (companyEdges.length) {
            companyEdges.forEach(({ node: company }) => {
              if (company.statusV2?.status === undefined) return
              cache.modify(
                updateCompanyStatusCache({
                  __typename: 'SimpleCompany',
                  id: company.id,
                  statusV2: { status, previousStatus, modifiedAt }
                })
              )
            })
          } else {
            companyIds.forEach((companyId) => {
              cache.modify(
                updateCompanyStatusCache({
                  __typename: 'Company',
                  id: companyId,
                  statusV2: { status, previousStatus, modifiedAt }
                })
              )
              cache.modify(
                updateCompanyStatusCache({
                  __typename: 'SimpleCompany',
                  id: companyId,
                  statusV2: { status, previousStatus, modifiedAt }
                })
              )
            })
          }
        }
      })

      if (!updateError && showToast) {
        toast.success(t`Company status changed`)
      }
    },
    [JSON.stringify(companyIds)]
  )

  return {
    SelectCompaniesStatus: (
      props: Omit<SelectCompaniesStatusProps, 'companyIds' | 'updateCompanyStatus' | 'loading'>
    ) => (
      <CompaniesStatusSelect
        companyIds={companyIds}
        companyStatus={companies.length === 1 ? companies[0]?.statusV2?.status : null}
        previousCompanyStatus={companies.length === 1 ? companies[0]?.statusV2?.previousStatus : null}
        updateCompanyStatus={updateCompanyStatus}
        data-track={TrackedActivityKind.StriseCompanyStatusChanged}
        data-track-company-id={companyIds[0]}
        loading={loading}
        {...props}
      />
    ),
    updateCompanyStatus,
    loading
  }
}
