import { type OwnerChartNodeFragment } from '@graphqlTypes'
import { Trans, t } from '@lingui/macro'
import { Typography, type TypographyProps } from '@strise/midgard'
import * as React from 'react'

export const ShareClasses = ({
  className,
  shareClasses,
  ...props
}: { shareClasses: OwnerChartNodeFragment['shareClasses'] } & TypographyProps) => {
  if (!shareClasses.length) return null

  const shareClassesString = shareClasses.map((sc) => `${sc.shareClass ?? t`Unknown`} (${sc.share})`).join(', ')

  return (
    <Typography className={className} {...props}>
      <Trans>Share classes</Trans>: {shareClassesString}
    </Typography>
  )
}
