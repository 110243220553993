import * as React from 'react'
import { MenuItem, OverflowMenu } from '@strise/system'
import { IconGarbage, IconPenBox, type IconPropsWithRef } from '@strise/midgard'
import { type TeamActivityFormData } from './teamActivityUtils'
import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { type TeamActivityFragment } from '@graphqlTypes'
import { ReminderStatus } from '@strise/types'

export const TeamActivityMenu: React.FC<{
  iconProps?: IconPropsWithRef
  setDeleteDialogOpen: SetStateFn<false | string>
  setFormData: SetStateFn<TeamActivityFormData>
  teamActivity: TeamActivityFragment
}> = ({ iconProps, setDeleteDialogOpen, setFormData, teamActivity }) => {
  const handleEditClick = () => {
    setFormData({
      id: teamActivity.id,
      kind: teamActivity.kind,
      timestamp: teamActivity.timestamp,
      user: teamActivity.createdBy,
      note: teamActivity.note || '',
      assignee: teamActivity.assignee ?? teamActivity.createdBy ?? null,
      reminderStatus: teamActivity.reminder?.status ?? ReminderStatus.Inactive
    })
  }

  const handleDeleteClick = (): void => setDeleteDialogOpen(teamActivity.id)

  const dropDownItems = [
    {
      title: t`Edit`,
      onClick: handleEditClick,
      startIcon: <IconPenBox />
    },
    {
      title: t`Delete`,
      onClick: handleDeleteClick,
      startIcon: <IconGarbage />
    }
  ]

  return (
    <OverflowMenu
      buttonProps={{
        'data-track': 'Team activities / Open dropdown options',
        palette: 'tertiary',
        iconProps
      }}
    >
      {dropDownItems.map(({ title, ...props }, index) => {
        return (
          <MenuItem key={index} {...props}>
            {title}
          </MenuItem>
        )
      })}
    </OverflowMenu>
  )
}
