import { Button, cn, IconButton, IconFullScreen, Typography } from '@strise/midgard'
import { t, Trans } from '@lingui/macro'
import { DownloadOwnerships } from '@components/Ownerships/DownloadOwnerships'
import { extractIsCompany, formatDate, Img, useContext } from '@strise/europa'
import { ConfirmDialog, Modal, ModalContent, ModalHeader } from '@strise/system'
import { useIsMobile } from '@utils/hooks'
import * as React from 'react'
import { useState } from 'react'
import { useToggle } from 'usehooks-ts'
import { OwnershipAbove25Toggle } from '../../Ownerships/OwnershipAbove25Toggle'
import { Ownerships } from '../../Ownerships/Ownerships'
import { useOwners } from '../../Ownerships/ownershipChartUtils'
import { SidepanelContext } from '../SidepanelContext/SidepanelContext'
import { DataSources } from '@components/Sidepanel/DataSources'
import { type SupportedSidepanelEntityFragment } from '@components/Sidepanel/utils/sidepanelUtils'
import { type SidepanelTab } from '@strise/strise-utils'
import { SidepanelEditableCard } from '@components/Sidepanel/SidepanelCards/SidepanelEditableCard'
import { type BaseEntityLikeFragment } from '@graphqlTypes'
import { useReactiveVar } from '@apollo/client/index.js'
import { refreshReviewCompanyMap } from '@state'
import { useResetEntityOwnersMutation } from '@graphqlOperations'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { OwnershipsModal } from '@components/Ownerships/OwnershipsModal'
import { DataSourceTooltip } from '@components/DataSourceTooltip'
import { TestIDs } from '@utils/testIDs'
import { ConflictAlert } from '@components/Conflicts/ConflictAlert'
import { ConflictAlertType } from '@components/Conflicts/ConflictAlertType'
import { useUnresolvedConflicts } from '@components/Conflicts/useHasUnresolvedConflicts'

const HelpModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal onRequestClose={onClose} isOpen contentMaxWidth={900}>
      <ModalHeader onClose={onClose} />
      <ModalContent>
        <Img
          src='https://files.strise.ai/ownerships-help.png'
          className='mx-auto block max-w-[700px]'
          alt={t`Ownerships help`}
        />
      </ModalContent>
    </Modal>
  )
}

const ResetOwnership = ({ entity }: { entity: BaseEntityLikeFragment }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const refreshPrepareReview = useReactiveVar(refreshReviewCompanyMap)

  const [update, { loading }] = useResetEntityOwnersMutation({
    onCompleted: () => {
      // prepare review again to reset ownerships, peps, sanctions, etc
      refreshReviewCompanyMap({
        ...refreshPrepareReview,
        [entity.id]: (refreshPrepareReview[entity.id] ?? 0) + 1
      })

      setIsOpen(false)
    }
  })

  const handleReset = () => {
    update({ variables: { entity: entity.id, ignoreCustomOwnerships: false } }).then((r) => r)
  }

  return (
    <>
      <div>
        <Button
          type='button'
          variant='ghost'
          palette='danger'
          className='rounded'
          onClick={() => setIsOpen(true)}
          data-track='Edit Ownerships / Open reset ownership dialog'
        >
          <Typography variant='aLabelSmall'>
            <Trans>Reset</Trans>
          </Typography>
        </Button>
      </div>
      <ConfirmDialog
        isOpen={isOpen}
        onConfirm={handleReset}
        danger={true}
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        title={t`Are you sure you want to reset the owner chart to its original form?`}
        confirmText={t`Reset`}
        contentMaxWidth={550}
        cancelButtonProps={{
          'data-track': 'Edit Ownerships / Reset ownership / Cancel'
        }}
        confirmButtonProps={{
          'data-track': 'Edit Ownerships / Reset ownership / Confirm'
        }}
        loading={loading}
        cancelText={t`Cancel`}
      >
        <Typography variant='body2'>
          <Trans>
            The custom relations between the edited entities and all edited changes will be reset. This action can not
            be reversed.
          </Trans>
        </Typography>
      </ConfirmDialog>
    </>
  )
}

const ToggleFullScreen = ({ toggleFullScreen }: { toggleFullScreen: () => void }) => {
  return (
    <DataSourceTooltip content={t`Open fullscreen preview`}>
      <IconButton
        className='size-10 text-gray-60'
        palette='tertiary'
        variant='ghost'
        onClick={toggleFullScreen}
        data-track='Ownerships / Open full screen'
      >
        <IconFullScreen className='size-5' />
      </IconButton>
    </DataSourceTooltip>
  )
}

interface SidepanelOwnershipsCardProps {
  chartRef?: React.ForwardedRef<HTMLDivElement | null>
  entity: SupportedSidepanelEntityFragment
  setShowOriginal: React.Dispatch<React.SetStateAction<boolean>>
  showOriginal: boolean
  sidepanelTab?: SidepanelTab
}

export const SidepanelOwnershipsCard = React.forwardRef<HTMLDivElement, SidepanelOwnershipsCardProps>(
  ({ chartRef, entity, setShowOriginal, showOriginal, sidepanelTab }, ref) => {
    const features = useCurrentUserFeatures()
    const [above25, setAbove25] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [help, toggleHelp] = useToggle(false)
    const [fullScreen, toggleFullScreen] = useToggle(false)
    const { scrollEl } = useContext(SidepanelContext)
    const { lastModifiedAt, loading } = useOwners(entity, above25 ? 25 : 5, showOriginal)
    const { hasCustomOwners } = useOwners(entity, 5, false)
    const { conflictsLoading, hasOwnershipConflicts } = useUnresolvedConflicts(entity)

    const mobile = useIsMobile()

    const isCompany = extractIsCompany(entity)

    return (
      <div data-id={TestIDs.SidePanel.Ownerships.root}>
        {help && <HelpModal onClose={toggleHelp} />}
        {fullScreen && (
          <OwnershipsModal
            above25={above25}
            showOriginal={showOriginal}
            entity={entity}
            toggleFullScreen={toggleFullScreen}
          />
        )}

        <SidepanelEditableCard
          ref={ref}
          editDisabled={conflictsLoading || hasOwnershipConflicts}
          editMode={editMode}
          toggleHelp={toggleHelp}
          setEditMode={setEditMode}
          setShowOriginal={setShowOriginal}
          showOriginal={showOriginal}
          tab={sidepanelTab}
          context='Ownership'
          title={t`Ownership`}
          editTitle={t`Edit ownership`}
          loading={loading}
          hasCustomData={hasCustomOwners}
          lastEditedAt={lastModifiedAt ? new Date(lastModifiedAt) : null}
          hasEditAccess={!!features.EDIT_OWNERS}
          resetContent={<ResetOwnership entity={entity} />}
          toggleEditButtonDataId={TestIDs.SidePanel.EditableCard.toggleEditButton}
          actions={[
            <ToggleFullScreen key='1' toggleFullScreen={toggleFullScreen} />,
            <DownloadOwnerships key='2' entity={entity} />
          ]}
        >
          <ConflictAlert type={ConflictAlertType.OWNERSHIPS} entity={entity} className='-mt-4' />
          {isCompany && (
            <div
              id='ownership'
              className={cn('mx-5 my-2 grid', mobile ? 'grid-cols-[1fr] gap-2' : 'grid-cols-[max-content_1fr]')}
            >
              <div className='flex flex-col'>
                <div className='flex flex-col gap-1'>
                  {lastModifiedAt && (
                    <Typography className='text-text-secondary' variant='aLabelSmall'>
                      <Trans>Last edited at: {formatDate(lastModifiedAt)}</Trans>
                    </Typography>
                  )}
                </div>
              </div>
              <OwnershipAbove25Toggle
                className='justify-end self-end pr-4'
                above25={above25}
                setAbove25={setAbove25}
                edit={editMode}
              />
            </div>
          )}
          <Ownerships
            className='p-4 pl-8'
            key={`percentage-${String(above25)}-${String(showOriginal)}-${String(editMode)}`}
            showOriginal={showOriginal}
            editMode={editMode}
            above25={above25}
            noFlags={false}
            noLink={false}
            noStatus={false}
            noSubsidiaryCount={false}
            isExport={false}
            ref={chartRef}
            entity={entity}
            dragOpts={{ scrollYEl: scrollEl.current }}
          />
          <DataSources dataSources={entity.dataSources.shareholders} />
        </SidepanelEditableCard>
      </div>
    )
  }
)
