import { Button, type ButtonProps, cn, Typography } from '@strise/midgard'
import * as React from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { Trans } from '@lingui/macro'

export interface DragAndDropFileProps extends ButtonProps {
  setFile: SetStateFn<File | null>
  supportedFilesText?: string | React.ReactElement
  uploadIcon?: React.ReactElement
}

export const DragAndDropFile = ({
  className,
  setFile,
  supportedFilesText,
  uploadIcon,
  ...props
}: DragAndDropFileProps) => {
  const [isDraggedOver, setIsDraggedOver] = React.useState(false)
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null)

  const handleClick = () => hiddenFileInput.current?.click()

  const handleDrop = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0] ?? null
    setFile(file)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const file = (event.target.files ?? [])[0] ?? null
    setFile(file)
  }

  const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setIsDraggedOver(true)
  }

  const handleDragExit = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setIsDraggedOver(false)
  }

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragExit={handleDragExit}
        className={cn(
          className,
          `h-52 w-full items-center justify-center gap-4 border-dashed border-primary-main font-medium ${
            isDraggedOver ? 'bg-blue-5' : ''
          }`
        )}
        data-track='Drag and drop file'
        {...props}
      >
        {uploadIcon && <div>{uploadIcon}</div>}
        {isDraggedOver ? (
          <Typography>
            <Trans>Drop the file</Trans>
          </Typography>
        ) : (
          <Typography>
            <span className='text-text-link'>
              <Trans>Browse files</Trans>
            </span>{' '}
            <Trans>or drag and drop</Trans>
          </Typography>
        )}
      </Button>
      <input type='file' ref={hiddenFileInput} onChange={handleFileChange} style={{ display: 'none' }} />
      {supportedFilesText && <Typography className='mt-2 text-text-secondary'>{supportedFilesText}</Typography>}
    </>
  )
}
