import * as Sentry from '@sentry/react'
import { type SeverityLevel } from '@sentry/react'

const captureMessage = (
  icon: string,
  title: string | number,
  message: string | number,
  severity: SeverityLevel = 'error'
) => {
  Sentry.captureMessage([icon, `[${title}]`, message].join(' '), severity)
}

export const trackError = {
  exception: (error: Error) => Sentry.captureException(error),
  network: captureMessage.bind(null, '⚡'),
  auth: captureMessage.bind(null, '🔒'),
  unhandled: captureMessage.bind(null, '💥')
}
