import { useContext, useUserId } from '@strise/europa'
import { type FinancialFieldsQuery } from '@graphqlTypes'
import { financialFieldsMap } from '@state'
import { reduceFlatten } from '@strise/fika'
import {
  CurrentUserSettingsContext,
  useCurrentUserEnabledContentLanguages
} from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { DisplayLanguage } from '@strise/types'
import React from 'react'
import { useFinancialFieldsQuery, useUpdateUserSettingsMutation } from '@graphqlOperations'
import { useDisplayLanguage } from '@contexts/DisplayLanguageContext/displayLanguageContextUtils'

export const useSaveUserTimeZone = () => {
  const userId = useUserId()
  const { settings } = useContext(CurrentUserSettingsContext)
  const [update] = useUpdateUserSettingsMutation()

  React.useEffect(() => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

    if (!timeZone || timeZone === settings.timeZone) return

    update({
      variables: { user: userId, input: { application: { timeZone } } }
    })
  }, [settings.timeZone])
}

export const useFetchUserFinancialFields = () => {
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()
  const [loading, setLoading] = React.useState(true)

  const handleCompleted = (data: FinancialFieldsQuery) => {
    const { financialFields } = data

    if (!financialFields.length) return

    const map = financialFields
      .map((financialField) => ({
        [financialField.id]: financialField
      }))
      .reduce(reduceFlatten, {})

    financialFieldsMap(map)
    setLoading(false)
  }

  useFinancialFieldsQuery({
    variables: { countries: enabledContentLanguages },
    onCompleted: handleCompleted
  })

  return loading
}

export const useHelpCenterLink = () => {
  const displayLanguage = useDisplayLanguage()

  const helpCenterLink =
    displayLanguage === DisplayLanguage.Norwegian ? 'https://help.strise.ai/nb/' : 'https://help.strise.ai/en/'

  return helpCenterLink
}
