import * as React from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { GrowLocationFilter } from '@views/Grow/components/Location/GrowLocationFilter'
import { type RegionFragment } from '@graphqlTypes'
import { IdNameChip } from '@strise/europa'

export const GrowLocationSearch = ({
  locations,
  setLocations
}: {
  locations: RegionFragment[]
  setLocations: SetStateFn<RegionFragment[]>
}) => {
  const handleDelete = (location: RegionFragment) => () => {
    setLocations((oldLocations) => oldLocations.filter((otherLocation) => otherLocation.id !== location.id))
  }

  return (
    <div>
      {!!locations.length && (
        <div className='flex flex-wrap gap-1'>
          {locations.map((location) => (
            <IdNameChip
              variant='outlined'
              palette='secondary'
              key={location.id}
              value={location}
              onDelete={handleDelete(location)}
            />
          ))}
        </div>
      )}

      <GrowLocationFilter locations={locations} setLocations={setLocations} />
    </div>
  )
}
