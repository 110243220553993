import { Chip, Typography } from '@strise/midgard'
import { useReactiveVar } from '@apollo/client/index.js'
import { AssigneeChip, LegalFormChip } from '@components/Filter/FilterChips'
import { type FromToFilterState } from '@components/FromToFilter'
import { t, Trans } from '@lingui/macro'
import { financialFieldsMap } from '@state'
import { useContext } from '@strise/europa'
import * as React from 'react'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

const getValueAndLabel = (fromTo: FromToFilterState | undefined | null, label: string) => {
  if (fromTo?.from && fromTo.to) {
    return t`${fromTo.from} - ${fromTo.to} ${label}`
  }
  if (fromTo?.from) {
    return t`From ${fromTo.from} ${label}`
  }
  return t`0 - ${fromTo?.to} ${label}`
}

const FromToFilterChip = ({ fromTo, label }: { fromTo: FromToFilterState | undefined | null; label: string }) => {
  if (!fromTo?.from && !fromTo?.to) return null

  const valueAndLabel = getValueAndLabel(fromTo, label)

  return <Chip label={valueAndLabel}></Chip>
}

export const ActiveFilters = () => {
  const { settings } = useContext(CurrentUserSettingsContext)
  const growSettings = settings.grow
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)

  return (
    <div className='flex flex-wrap items-center gap-2 px-4'>
      <Typography className='mr-2'>
        <Trans>Active filters:</Trans>
      </Typography>
      {growSettings.assignees.map((value) => (
        <AssigneeChip key={value.id} value={value} />
      ))}
      {growSettings.tags.map((value) => {
        return <Chip key={value.id} label={value.name} />
      })}
      {growSettings.industries.map((value) => {
        return <Chip key={value.id} label={value.name} />
      })}
      {growSettings.legalForms.map((value) => {
        return <LegalFormChip key={value.id} value={value} />
      })}
      {growSettings.flagKinds.map((value) => {
        return <Chip key={value.id} label={value.name} />
      })}
      {Object.entries(growSettings.financials).map(([key, value]) => {
        const selectedFieldInfo = financialsFieldMapState[value.id]

        if (!selectedFieldInfo) return null

        return <FromToFilterChip key={key} label={selectedFieldInfo.name} fromTo={value} />
      })}
      {growSettings.locations.map((value) => {
        return <Chip key={value.id} label={value.name} />
      })}
      {Boolean(growSettings.coordinates.length) && <Chip label={t`By area`} />}
      <FromToFilterChip label='employees' fromTo={growSettings.employees} />
      <FromToFilterChip
        label='months since inception date'
        fromTo={{ from: growSettings.inception.moreThan, to: growSettings.inception.lessThan }}
      />

      {growSettings.requireNoAccountant && <Chip label={t`No accountant`} />}
      {growSettings.collateralCreditors.map((value) => {
        return <Chip key={value.id} label={value.name} />
      })}
      {growSettings.realEstateCollateralCreditors.map((value) => {
        return <Chip key={value.id} label={value.name} />
      })}
      {growSettings.accountants.map((value) => {
        return <Chip key={value.id} label={value.name} />
      })}
    </div>
  )
}
