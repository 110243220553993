import { Chip, cn, Divider, Tooltip, type TooltipProps, Typography } from '@strise/midgard'
import * as React from 'react'
import { EuropaContext } from '../../EuropaContextProvider'
import { formatDate } from '../../utils/date'
import { ellipsis } from '@strise/fika'
import { type AdverseFlagFragment } from '../../graphqlTypes'
import { useContext } from '../../utils/contextUtils'
import { useHoverDelay } from '@strise/react-utils'
import { AdverseFlag } from '../AdverseFlag'

const FlagItem = ({ flag, hideChip }: { flag: AdverseFlagFragment; hideChip: boolean }) => {
  const { EntityLink, EventLink } = useContext(EuropaContext)

  return (
    <div className='flex'>
      <AdverseFlag severity={flag.severity} size='xl' className='mr-2 shrink-0' />
      <div className='mb-1 min-w-0 flex-1'>
        <Typography variant='subtitle2' className='text-sm'>
          {formatDate(flag.date, { relative: false })}
        </Typography>
        <div>
          {flag.event ? (
            <EventLink eventId={flag.event.id} entityId={flag.entity.id} variant='body2'>
              {flag.reason}
            </EventLink>
          ) : (
            <Typography variant='body2'>{flag.reason}</Typography>
          )}
        </div>
        {!hideChip && flag.entity.name && (
          <EntityLink entity={flag.entity} className='mt-1 block no-underline' noFlags noTooltip noLifeStatus>
            <Chip className='w-full cursor-pointer' label={ellipsis(flag.entity.name, 30)} />
          </EntityLink>
        )}
      </div>
    </div>
  )
}

interface FlagsTooltipProps extends TooltipProps {
  children: React.ReactElement<{
    open: boolean
    ref: React.MutableRefObject<object>
  }>
  entityId: string
  flags: AdverseFlagFragment[]
}

export const FlagsTooltip = ({ children, className, entityId, flags, ...props }: FlagsTooltipProps) => {
  const { handleMouseEnter, handleMouseLeave, isHovered } = useHoverDelay()

  // Since Radix UI's Tooltip currently does not support uncontrolled nested Tooltips due to shared `data-state`
  // attribute (https://github.com/radix-ui/primitives/discussions/560), we need to handle the hover state ourselves.
  // Wrapping this in a div so that the hover state handling applies to the entire Tooltip scope (both trigger and portal content)
  // The wrapping logic for this case could be moved into the Tooltip as well, but I feel like it's better to keep it here for now,
  // as it seems like an edge case. Can reconsider later if proven otherwise
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Tooltip
        open={isHovered}
        content={flags.map((flag, index) => (
          <React.Fragment key={index}>
            <FlagItem hideChip={entityId === flag.entity.id} flag={flag} />
            {flags.length - 1 !== index && <Divider className='my-2' />}
          </React.Fragment>
        ))}
        className={cn('max-h-[400px] w-[250px] overflow-auto p-4', className)}
        {...props}
      >
        {children}
      </Tooltip>
    </div>
  )
}
