import * as React from 'react'
import { HomeCard } from '@views/Home/components/HomeCard'
import { IconChat, IconHelp, IconPortfolio, IconReview, IconSettings, IconSpark } from '@strise/midgard'
import { t } from '@lingui/macro'
import { HomeGreetingCard } from '@views/Home/components/HomeGreetingCard'
import { HomeCardActions } from '@views/Home/components/HomeCardActions'
import { useContext, useUser } from '@strise/europa'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { useHelpCenterLink } from '@utils/userHooks'
import { SettingsModalContext } from '@views/Settings/SettingsModalContextProvider'
import { openChat } from '@utils/intercom'

export const HomeView = () => {
  const user = useUser()
  const team = useTeam()
  const helpCenterLink = useHelpCenterLink()
  const { setIsSettingsOpen } = useContext(SettingsModalContext)

  const mainCards = [
    {
      title: t`Review`,
      icon: <IconReview />,
      description: t`Onboard and conduct due diligence on your customers`,
      url: '/review'
    },
    {
      title: t`Portfolio`,
      icon: <IconPortfolio />,
      description: t`Get an overview of all companies in your team portfolio`,
      url: '/portfolio'
    },
    {
      title: t`Grow`,
      icon: <IconSpark />,
      description: t`Target and find new prospects`,
      url: '/grow'
    }
  ]

  const cardActions = [
    {
      title: t`Help Center`,
      icon: <IconHelp />,
      url: helpCenterLink
    },
    {
      title: t`Settings`,
      icon: <IconSettings />,
      onClick: () => {
        setIsSettingsOpen(true)
      }
    },
    {
      title: t`Chat`,
      icon: <IconChat />,
      onClick: () => openChat()
    }
  ]

  const boxWrapper = 'flex flex-col justify-center gap-8 xl:flex-row'

  return (
    <div className='flex items-center justify-center py-16'>
      <div className='flex flex-col items-start gap-8'>
        <HomeGreetingCard team={team.name} userName={user.name} />

        <div className={boxWrapper}>
          {mainCards.map((item, index) => (
            <HomeCard key={index} title={item.title} description={item.description} icon={item.icon} url={item.url} />
          ))}
        </div>

        <div className={boxWrapper}>
          {cardActions.map((item, index) => (
            <HomeCardActions key={index} icon={item.icon} title={item.title} onClick={item.onClick} url={item.url} />
          ))}
        </div>
      </div>
    </div>
  )
}
