import * as React from 'react'
import { useTeamActivityReminderStyle } from './teamActivityHooks'
import { type TeamActivityFragment } from '@graphqlTypes'
import { cn } from '@strise/midgard'
import { UserAvatar } from '@strise/europa'

export const TeamActivityFormReminderBackground: React.FC<{
  teamActivity: TeamActivityFragment
}> = ({ teamActivity }) => {
  const { ReminderIcon, colorClassName, reminderBackgroundColorClass } = useTeamActivityReminderStyle(
    teamActivity.timestamp,
    teamActivity.reminder
  )

  const user = teamActivity.assignee ?? teamActivity.createdBy

  return (
    <div
      className={cn(
        'absolute left-0 top-0 z-[-1] size-[121px] overflow-hidden rounded-md',
        ReminderIcon ? 'pl-5 pt-6' : 'pl-4 pt-4'
      )}
    >
      <div className={cn('absolute left-[-30px] top-[-25px] size-full rounded-full', reminderBackgroundColorClass)} />

      {ReminderIcon && <ReminderIcon className={cn('absolute', colorClassName)} />}
      {!ReminderIcon && user && <UserAvatar user={user} className='size-8 bg-divider' />}
    </div>
  )
}
