import { Chips, cn, LoaderRound, Typography } from '@strise/midgard'
import { useEntityMetaQuery } from '@graphqlOperations'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import { i18n } from '@lingui/core'
import { Assignees, CompanyStatusDot, extractIsActive } from '@strise/europa'
import { companyStatusTitles } from '@strise/europa/src/i18n'
import { useTeamUserStatus } from '@utils/teamUsers'
import * as React from 'react'
import { useSelectCompaniesStatus } from '../CompanyStatus/useSelectCompaniesStatus'
import { EntityLink } from '../EntityLink/EntityLink'
import { EntityMetaItems } from './EntityMetaItems'
import { type DivProps } from '@strise/react-utils'

type EntityMetaSize = 'small' | 'medium' | 'large'

interface EntityMetaProps extends DivProps {
  column?: boolean
  hideStatus?: boolean
  link?: boolean
  noFlags?: boolean
  noTooltip?: boolean
  selectStatusContext?: string
  variant?: EntityMetaSize
}

const CompanyMetaAdditionalInfo = ({
  entity,
  hideStatus,
  selectStatusContext,
  variant
}: {
  entity: EntityLikeMetaFragment & { __typename: 'Company' }
  hideStatus?: boolean
  selectStatusContext?: string
  variant?: EntityMetaSize
}) => {
  const { SelectCompaniesStatus } = useSelectCompaniesStatus([entity])
  const companyStatusTitle = entity.statusV2 ? companyStatusTitles[entity.statusV2.status] : null

  return (
    <div className='flex flex-wrap items-center gap-2'>
      {variant !== 'small' && (
        <Chips
          chips={entity.tags.edges.map((edge) => ({ label: edge.node.name }))}
          maxLabelLengthProps={{
            maxLabelLength: 15,
            maxTotalLabelLength: 60
          }}
        />
      )}
      <div className='flex items-center gap-2'>
        {variant !== 'small' && (
          <Assignees assignees={entity.assignee.edges} useTeamUserStatus={useTeamUserStatus} showName={false} />
        )}
        {entity.statusV2?.status && !selectStatusContext && !hideStatus && (
          <div className={cn('flex', { 'ml-auto': variant === 'small' })}>
            <CompanyStatusDot status={entity.statusV2.status} />
            {variant !== 'small' && companyStatusTitle && (
              <Typography className='whitespace-nowrap'>{i18n._(companyStatusTitle)}</Typography>
            )}
          </div>
        )}
        {entity.statusV2?.status && selectStatusContext && !hideStatus && (
          <SelectCompaniesStatus className='shrink-0' data-track-context={selectStatusContext} />
        )}
      </div>
    </div>
  )
}

const extractEntityMetaTitleSize = (variant?: EntityMetaProps['variant']) => {
  if (variant === 'large') return 'h6'
  if (variant === 'medium') return 'subtitle1'
  return 'aLabel'
}

export const EntityMeta = ({
  className,
  column,
  entity,
  hideStatus,
  link,
  noFlags,
  noTooltip,
  selectStatusContext,
  variant,
  ...props
}: { entity: EntityLikeMetaFragment } & EntityMetaProps) => {
  const titleSize = extractEntityMetaTitleSize(variant)
  const title = (
    <Typography className='mb-1 mt-auto whitespace-nowrap' variant={titleSize} data-id='entity-meta-name'>
      {entity.name}
    </Typography>
  )

  const isActive = extractIsActive(entity)

  return (
    <div
      className={cn(
        'flex flex-wrap items-center justify-between',
        variant === 'small' ? 'w-full' : 'w-auto',
        { 'text-text-secondary': !isActive },
        className
      )}
      {...props}
    >
      <div>
        <EntityLink entity={entity} noLink={!link} noFlags={noFlags} className='text-inherit' noTooltip>
          {title}
        </EntityLink>
        <EntityMetaItems entity={entity} noTooltip={noTooltip} column={column} />
      </div>
      {entity.__typename === 'Company' && (
        <CompanyMetaAdditionalInfo
          entity={entity}
          variant={variant}
          selectStatusContext={selectStatusContext}
          hideStatus={hideStatus}
        />
      )}
    </div>
  )
}

export const EntityIdMeta = ({ className, id, ...props }: { id: string } & EntityMetaProps) => {
  const { data, loading } = useEntityMetaQuery({ variables: { id } })

  if (loading) {
    return (
      <div className='flex items-center justify-center'>
        <LoaderRound palette='white' size='sm' />
      </div>
    )
  }

  if (!data) return null

  return <EntityMeta className={className} entity={data.entity} {...props} />
}
