import { type TimespanFragment } from '@strise/europa/src/graphqlTypes'
import { Tooltip, Typography, type TypographyProps } from '@strise/midgard'
import React from 'react'
import { extractTimespanDuration, extractTimespanPeriod } from './timespanUtils'

export const Timespan = ({
  className,
  isActive,
  timespan,
  ...props
}: { isActive: boolean; timespan: TimespanFragment } & TypographyProps) => {
  const period = extractTimespanPeriod(timespan, isActive)
  const duration = extractTimespanDuration(timespan, isActive)

  if (!period && !duration) return null

  return (
    <Tooltip content={duration && <span>{duration}</span>}>
      <Typography className={className} {...props}>
        {period}
      </Typography>
    </Tooltip>
  )
}
