import { colors } from '@strise/system'
import { creditScoreColorIntervals } from '@utils/creditScore'

export const scoreToColor = (score: number | null | undefined, depth: 50 | 100 | 10) => {
  if (!score) return colors.gray[10]

  const matchingInterval = creditScoreColorIntervals.find((interval) => score >= interval.from && score <= interval.to)

  if (!matchingInterval) return colors.gray[10]

  return matchingInterval.color[depth]
}
