import * as React from 'react'
import { DarkModeContext } from './DarkModeContext'
import { getBrowserGlobals } from '../../globals'

const storageKey = 'system-dark-mode'

export const DarkModeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const darkModeStorage = getBrowserGlobals()?.window.localStorage.getItem(storageKey)
  const value = React.useMemo(
    () => ({
      state: darkModeStorage === 'rgb(0, 0, 0)'
    }),
    []
  )

  return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>
}
