import { Skeleton, Typography } from '@strise/midgard'
import { useScrollContentTo } from '@components/Layout/ContentViewContext'
import { STORAGE_KEYS } from '@constants'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { useCompaniesListViewQuery } from '@graphqlOperations'
import { type CompaniesListViewQuery, type CompaniesListViewQueryVariables } from '@graphqlTypes'
import { newCompaniesState } from '@state'
import {
  type SortField,
  StickyScrollbar,
  usePersistentState,
  useSelectTableRows,
  useTableSortState,
  useContext
} from '@strise/europa'
import { objectKeys, setByPath } from '@strise/fika'
import { Table } from '@strise/system'
import { ListViewField, SortOrdering, type CompanyFilterInput } from '@strise/types'
import { type SetStateFn } from '@strise/react-utils'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { CompaniesBulkActionsHeader } from './components/CompaniesBulkActionHeader'
import { CompaniesHeader } from './components/CompaniesHeader'
import { CompaniesTableBody } from './components/CompaniesTableBody'
import { CompaniesTableHead } from './components/CompaniesTableHead'
import { CompaniesTablePagination } from './components/CompaniesTablePagination'
import { companiesTableFilters } from './utils/companiesFilterUtils'
import {
  type ColumnField,
  columns as allColumns,
  type CompaniesTableColumn,
  type EnabledColumns,
  useColumns
} from './utils/companiesTableColumns'
import { useFetchNewlyAdded } from './utils/portfolioViewHooks'
import { CompaniesFilterEdit } from '@views/Portfolio/components/CompaniesFilterEdit'
import { userPortfolioSettingsToInput } from '@utils/settingsUtils'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const CompaniesContent: React.FC<{
  columns: CompaniesTableColumn[]
  companies: CompaniesListViewQuery['listView']['edges']
  count: number
  currentPage: number
  enabledColumns: EnabledColumns
  loading: boolean
  offset: number
  rowsPerPage: number
  setEnabledColumns: SetStateFn<EnabledColumns>
  setNameFilter: SetStateFn<string>
  setPage: (page: number) => void
  setRowsPerPage: SetStateFn<number>
  setSortField: (field: ColumnField) => () => void
  sort: Array<SortField<ColumnField>>
  variables: CompaniesListViewQueryVariables
}> = ({
  columns,
  companies,
  count,
  currentPage,
  enabledColumns,
  loading,
  offset,
  rowsPerPage,
  setEnabledColumns,
  setNameFilter,
  setPage,
  setRowsPerPage,
  setSortField,
  sort,
  variables
}) => {
  const [filterOpen, toggleFilters] = useToggle(false)

  const currentPageCompanies = React.useMemo(() => {
    const newCompanies = newCompaniesState()

    const firstPage = offset === 0

    const fromIndex = offset + (firstPage ? 0 : newCompanies.length)
    const toIndex = fromIndex + rowsPerPage + (firstPage ? newCompanies.length : 0)
    return companies.slice(fromIndex, toIndex)
  }, [companies])

  const selectRows = React.useMemo(
    () =>
      currentPageCompanies.map(({ node: company }) => ({
        id: { sortValue: company.id }
      })),
    [currentPageCompanies]
  )
  const { handleSelect, selectAll, selected, unselectAll } = useSelectTableRows(selectRows, variables.settings)

  const selectedCompanyIds = objectKeys(selected)
  const selectedLength = selectedCompanyIds.length

  return (
    <div>
      <CompaniesHeader
        setFilterString={setNameFilter}
        columns={columns}
        enabledColumns={enabledColumns}
        setEnabledColumns={setEnabledColumns}
        toggleFilters={toggleFilters}
        filterOpen={filterOpen}
        variables={variables}
        count={count}
      />

      <div>
        <div className='bg-white'>
          <StickyScrollbar
            className='min-h-[600px]'
            scrollbarProps={{
              className: 'bottom-[56px]'
            }}
          >
            <Table className='h-full table-fixed bg-white'>
              <thead>
                {filterOpen && <CompaniesFilterEdit columns={columns} toggleFilters={toggleFilters} />}
                <CompaniesTableHead
                  columns={columns}
                  sortFields={sort}
                  updateSortField={setSortField}
                  selectAll={selectAll}
                  hide={!!selectedLength}
                />

                {!!selectedLength && (
                  <CompaniesBulkActionsHeader
                    selected={selected}
                    companies={companies}
                    onUnselectAll={unselectAll}
                    count={count}
                    filterOpen={filterOpen}
                  />
                )}
              </thead>
              {!loading && (
                <CompaniesTableBody
                  enabledColumns={enabledColumns}
                  columns={columns}
                  companies={currentPageCompanies}
                  selected={selected}
                  onSelect={handleSelect}
                />
              )}
            </Table>

            {!loading && !currentPageCompanies.length && (
              <Typography className='h-[300px] w-full py-10 text-center text-text-secondary' variant='body1'>
                No result
              </Typography>
            )}

            {loading && (
              <div className='flex flex-col'>
                {Array.from({ length: rowsPerPage }, (_, index) => (
                  <div className='flex h-breadcrumb items-center border-b border-solid border-divider px-4' key={index}>
                    <Skeleton className='mr-4 h-6 w-[340px]' variant='rect' />
                    <Skeleton className='mr-[6.5rem] h-6 w-[50px] rounded-[24px]' variant='rect' />
                    <Skeleton className='h-6 flex-1 grow' variant='rect' />
                  </div>
                ))}
              </div>
            )}
          </StickyScrollbar>

          {!!companies.length && (
            <CompaniesTablePagination
              page={currentPage}
              rowsPerPage={rowsPerPage}
              count={count}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
        <div className='h-20 bg-tertiary-shade-5' />
      </div>
    </div>
  )
}

const columnFieldToListViewField: { [key in ColumnField]: ListViewField } = {
  name: ListViewField.Name,
  assignees: ListViewField.Assignee,
  employees: ListViewField.Employees,
  revenue: ListViewField.Revenue,
  status: ListViewField.Status,
  statusModified: ListViewField.StatusModified,
  tags: ListViewField.Tags,
  flags: ListViewField.Flag,
  hq: ListViewField.Location
}

const useApiSort = (sort: Array<SortField<ColumnField>>): Array<{ field: ListViewField; ordering: SortOrdering }> => {
  return React.useMemo(() => {
    return sort
      .map(({ direction, field }) => {
        return {
          field: columnFieldToListViewField[field],
          ordering: direction === 'asc' ? SortOrdering.Ascending : SortOrdering.Descending
        }
      })
      .reverse()
  }, [sort])
}

export const PortfolioView = React.memo(() => {
  const team = useTeam()
  const { enabledColumns, includeVariables, setEnabledColumns } = useColumns()
  const columns = React.useMemo(() => {
    return allColumns.filter(({ field, removable }) => !removable || enabledColumns[field])
  }, [enabledColumns])

  const [nameFilter, setNameFilter] = React.useState('')
  const [sort, setSortField] = useTableSortState<ColumnField>([{ field: 'statusModified', direction: 'desc' }], true)
  const [rowsPerPage, setRowsPerPage] = usePersistentState<number>(STORAGE_KEYS.portfolioRowsPerPage, 25)
  const [offset, setOffset] = React.useState(0)
  // Workaround for fetchMore not updating networkStatus (even with notifyOnNetworkStatusChange: true)
  // https://github.com/apollographql/apollo-client/issues/7607
  const [fetchMoreLoadingList, setFetchMoreLoadingList] = React.useState<number[]>([])
  const currentPage = offset && offset / rowsPerPage

  const apiSort = useApiSort(sort)

  const { settings } = useContext(CurrentUserSettingsContext)

  const settingsInput = userPortfolioSettingsToInput(settings)

  const filterVariables = React.useMemo(() => {
    const enabledColumnsFilter = objectKeys(companiesTableFilters).reduce<CompanyFilterInput>(
      (companiesFilter, columnField) => {
        if (!columns.some(({ field }) => field === columnField)) {
          return setByPath(
            companiesFilter,
            companiesTableFilters[columnField]?.path.split('.').slice(1) ?? [],
            undefined
          )
        }
        return companiesFilter
      },
      { ...settingsInput.listView?.companies }
    )

    const enabledColumnsFilterWithNameFilter = nameFilter
      ? { ...enabledColumnsFilter, name: nameFilter }
      : enabledColumnsFilter

    return enabledColumnsFilterWithNameFilter
  }, [settings, columns, nameFilter])

  const scrollContentTo = useScrollContentTo()

  const variables: CompaniesListViewQueryVariables = {
    portfolio: team.portfolioId,
    team: team.id,
    settings: {
      companies: filterVariables
    },
    page: {
      limit: rowsPerPage * 2,
      offset,
      sort: apiSort
    },
    ...includeVariables
  }

  const queryVariables = React.useMemo(() => variables, [offset])

  useFetchNewlyAdded({ variables, setOffset })
  const { data, fetchMore, loading, refetch } = useCompaniesListViewQuery({
    variables: queryVariables,
    notifyOnNetworkStatusChange: true
  })
  const count = data?.listView.totalCount ?? 0
  const companyEdges = data?.listView.edges ?? []

  const setPage = (newPage: number) => {
    const nextPage = newPage > currentPage
    const newOffset = nextPage ? offset + rowsPerPage : offset - rowsPerPage
    setOffset(newOffset)
    const fetchMoreOffset = newOffset + rowsPerPage

    if (nextPage && fetchMoreOffset >= companyEdges.length) {
      setFetchMoreLoadingList([fetchMoreOffset, ...fetchMoreLoadingList])

      fetchMore({
        variables: {
          page: {
            limit: rowsPerPage,
            offset: fetchMoreOffset,
            sort: apiSort
          }
        }
      }).then(() => {
        setFetchMoreLoadingList((prevState) => prevState.filter((item) => item !== fetchMoreOffset))
      })
    }

    scrollContentTo({ top: 0, behavior: 'smooth' })
  }

  const firstRender = React.useRef(true)
  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    const refetchList = async () => {
      await refetch({
        ...variables,
        page: {
          limit: rowsPerPage * 2,
          offset: 0,
          sort: apiSort
        }
      })
      setOffset(0)
    }
    refetchList()
  }, [filterVariables, apiSort, rowsPerPage])

  const showFetchMoreLoader = fetchMoreLoadingList.includes(offset) && offset >= companyEdges.length
  const showLoader = loading || Boolean(currentPage > 0 && !companyEdges.length) || showFetchMoreLoader

  return (
    <CompaniesContent
      companies={companyEdges}
      loading={showLoader}
      columns={columns}
      enabledColumns={enabledColumns}
      setEnabledColumns={setEnabledColumns}
      currentPage={currentPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      sort={sort}
      setSortField={setSortField}
      setNameFilter={setNameFilter}
      variables={variables}
      count={count}
      offset={offset}
    />
  )
})
