import { Chip, Typography } from '@strise/midgard'
import * as React from 'react'

export const FilterButtonText: React.FC<{
  chipLabel?: string | number
  label: string
  onReset?: () => void
}> = ({ chipLabel, label, onReset }) => {
  return (
    <>
      {Boolean(chipLabel) && (
        <Chip
          className='mr-2 bg-secondary-shade-70'
          variant='contained'
          palette='secondary'
          label={chipLabel}
          onDelete={onReset}
        />
      )}

      <Typography className='whitespace-nowrap'>{label}</Typography>
    </>
  )
}
