import { useEntityQuery } from '@graphqlOperations'
import { type BaseEntityLikeFragment } from '@graphqlTypes'

export const useEntityLikeName = (id: string | null): string | null => {
  const { data } = useEntityQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: id! },
    skip: !id
  })
  const entity = data?.entity
  return entity?.name ?? null
}

export const hasEntityAccess = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  return entity && 'access' in entity ? entity.access.hasAccess : false
}

export const isEntityActive = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  return entity && 'lifeStatus' in entity ? entity.lifeStatus.isActive : true
}

export type EntityIdentity = Pick<BaseEntityLikeFragment, 'id' | '__typename'>
