import { cn, Skeleton, Tooltip, Typography, type TypographyProps } from '@strise/midgard'
import { type TransformedApiTableRow } from '@utils/apiTable/apiTableUtils'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'

export const EntityMetaItem = ({
  className,
  loading,
  noTooltip,
  row,
  textProps,
  ...props
}: {
  loading?: boolean
  noTooltip?: boolean
  row: TransformedApiTableRow | null
  textProps?: TypographyProps
} & DivProps) => {
  const value = row?.sections[0]?.items[0]

  if (!loading && !value?.content) return null

  const content = (
    <div className={cn('flex min-w-0 shrink-0 flex-row items-center', className)} {...props}>
      {value?.Icon && <value.Icon className='size-4 shrink-0 align-text-bottom' />}
      <Typography className='ml-1' variant='body2' {...textProps}>
        {loading ? <Skeleton className='size-3' /> : (value?.content ?? '-')}
      </Typography>
    </div>
  )

  if (noTooltip) return content

  const tooltipContent = (
    <>
      {row?.label && <span className='font-bold'>{row.label}</span>}

      {value?.content && <div>{value.content}</div>}
    </>
  )

  return <Tooltip content={value?.tooltipContent ?? tooltipContent}>{content}</Tooltip>
}
