import { AuditRisk } from '@strise/types'
import * as React from 'react'
import { auditColors, auditRiskTitles } from '@utils/audit'
import { getTitle } from '@utils/enum'
import { cn, Radio, Label } from '@strise/midgard'

const sortedAuditRisks = [AuditRisk.None, AuditRisk.Low, AuditRisk.Medium, AuditRisk.High]

export const AuditRiskSelect = ({
  disabled,
  risk,
  updateRisk
}: {
  disabled?: boolean
  risk: AuditRisk
  updateRisk: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <>
      {sortedAuditRisks.map((auditRisk, index) => {
        return (
          <Label
            key={index}
            className={cn(
              'inline-flex cursor-pointer items-center p-2',
              auditRisk === risk &&
                `rounded font-medium ${auditColors[auditRisk]?.bgColorClass ?? 'bg-secondary-shade-10'}`
            )}
            variant='aLabel'
          >
            <Radio
              className={cn('mr-1', auditColors[auditRisk]?.colorClass)}
              value={auditRisk}
              checked={auditRisk === risk}
              name='audit-risk'
              data-id={`audit-risk-${auditRisk}`}
              onChange={updateRisk}
              disabled={disabled}
            />
            {getTitle(auditRiskTitles[auditRisk])}
          </Label>
        )
      })}
    </>
  )
}
