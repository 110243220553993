import { type CustomMetaFragment } from '@graphqlTypes'
import React from 'react'
import { Chip, IconButton, IconPenBox, Tooltip, Typography } from '@strise/midgard'
import { Trans } from '@lingui/macro'
import { formatDate } from '@strise/europa'

export const CustomMeta = ({
  customMeta,
  editMode,
  handleEdit
}: {
  customMeta?: CustomMetaFragment | null
  editMode?: boolean
  handleEdit?: () => void
} & React.HTMLAttributes<HTMLDivElement>) => {
  if (!editMode && !customMeta) return null

  return editMode || !customMeta ? (
    <IconButton
      className='size-10 justify-self-center text-gray-60'
      variant='ghost'
      onClick={handleEdit}
      data-track='Toggle edit mode'
    >
      <IconPenBox size='md' />
    </IconButton>
  ) : (
    <Tooltip
      arrow
      content={
        <div className='grid place-items-center gap-2 p-1'>
          {customMeta.comment && <Typography variant='aLabelSmallBold'>"{customMeta.comment}"</Typography>}
          <Typography className='text-gray-20' variant='aLabelSmall'>
            <Trans>Edited by</Trans> {customMeta.lastModifiedBy.name},{' '}
            {formatDate(customMeta.lastModifiedAt, { relative: false })}
          </Typography>
        </div>
      }
    >
      <Chip
        className='flex h-0 w-full'
        size='sm'
        label={
          <div className='flex h-6 items-center justify-center rounded-md bg-accent-blue-shade-5 p-2 text-accent-blue-shade-30'>
            <Trans>Edited</Trans>
          </div>
        }
      />
    </Tooltip>
  )
}
