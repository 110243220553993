export const striseTeamId = '826da0af-62b5-440d-820a-435eda1b36c1'
export const STRISE_BASE_URL = 'https://app.strise.ai'

// URL search params
export const sidepanelEntityIdQueryParamKey = 'sidepanel-id'
export const sidepanelExpandedQueryParamKey = 'sidepanel-expanded'
export const eventIdQueryParamKey = 'event-id'
export const eventEntityIdQueryParamKey = 'event-context'
export const eventMentionsQueryParamKey = 'event-mentions'
export const sidepanelTabQueryParamKey = 'sidepanel-tab'
export const settingsTabQueryParamKey = 'settings-tab'
export const conflictIdQueryParamKey = 'conflict-id'

const legacyEventMenuQueryParamKey = 'mentions'
const legacySidepanelTabQueryParamKey = 'sidepanelTab'
const legacySettingsTabQueryParamKey = 'settingsTab'

export const legacyQueryParamsMap = {
  [legacyEventMenuQueryParamKey]: eventMentionsQueryParamKey,
  [legacySidepanelTabQueryParamKey]: sidepanelTabQueryParamKey,
  [legacySettingsTabQueryParamKey]: settingsTabQueryParamKey
}
