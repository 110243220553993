import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagFr = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagFR_svg__a)'>
      <path fill='#EEE' d='M8.307 1h7.648l1.113 10.841L15.955 23H8.307l-1.28-10.888L8.306 1Z' />
      <path fill='#0052B4' d='M1.13 1h7.177v22H1.13V1Z' />
      <path fill='#D80027' d='M15.955 1h7.176v22h-7.176V1Z' />
    </g>
    <defs>
      <clipPath id='FlagFR_svg__a'>
        <rect width={22} height={22} x={1.131} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagFr.displayName = 'FlagFr'
