import { cn, Typography } from '@strise/midgard'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { Editable, type RenderElementProps, type RenderLeafProps, Slate, withReact } from 'slate-react'
import { createEditor, type Descendant, type Element as CustomElement, Range, type Text } from 'slate'
import { RichTextControls } from './RichTextControls'
import { convertFromRawDraftToSlate, type RawDraftContentState } from './richTextUtils'
import { toggleBlock } from './richTextControlUtils'
import { type DivProps } from '@strise/react-utils'

interface RichTextEditorProps {
  autoFocus?: boolean
  children?: React.ReactNode
  displayAllRows?: boolean
  initValue: string | null
  onChange?: (state: string) => void
  placeholder?: string
  readOnly?: boolean
  rows?: number
}

export const RichTextEditor = ({
  autoFocus = false,
  children,
  className,
  displayAllRows = false,
  initValue,
  onChange,
  placeholder,
  readOnly = false,
  rows = 6,
  ...props
}: RichTextEditorProps & DivProps) => {
  const initialValue = getInitialValue(initValue)
  const [isActive, setIsActive] = React.useState(false)

  const renderElement = useCallback((elementProps: RenderElementProps) => <Element {...elementProps} />, [])
  const renderLeaf = useCallback((leafProps: RenderLeafProps) => <Leaf {...leafProps} />, [])
  const editor = useMemo(() => withReact(createEditor()), [])

  const handleChange = (value: Descendant[]) => {
    const isContentChange = editor.operations.some((op) => op.type !== 'set_selection')
    if (isContentChange) {
      const content = JSON.stringify(value)
      onChange?.(content)
    }
  }

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={handleChange}>
      <div
        className={cn(readOnly ? 'bg-transparent' : 'bg-white', className)}
        data-id='rich-text-editor-wrapper'
        {...props}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          autoFocus={autoFocus}
          readOnly={readOnly}
          onFocus={(e) => {
            e.preventDefault()
            setIsActive(true)
          }}
          onBlur={() => setIsActive(false)}
          placeholder={placeholder}
          style={{
            height: displayAllRows ? 'auto' : `${8 + rows * 24}px`,
            padding: '8px',
            boxShadow: isActive ? '0 0 0 1px #161717' : 'none',
            outline: 'none',
            overflow: readOnly ? 'hidden' : 'auto',
            overflowX: 'hidden',
            display: readOnly ? '-webkit-box' : 'block',
            WebkitLineClamp: readOnly && !displayAllRows ? rows : 'initial',
            WebkitBoxOrient: readOnly ? 'vertical' : 'initial'
          }}
          renderPlaceholder={({ attributes, children: placeholderChildren }) => (
            <Typography className='mt-2' component='span' {...attributes}>
              {placeholderChildren}
            </Typography>
          )}
          onKeyDown={(e) => {
            if (e.key === 'Backspace' || e.key === 'Enter') {
              const { selection } = editor
              // Check if we are on a line with no text content
              if (
                selection &&
                selection.focus.offset === 0 &&
                selection.anchor.offset === 0 &&
                selection.anchor.path[0] !== undefined &&
                Range.isCollapsed(selection)
              ) {
                const node = editor.children[selection.anchor.path[0]] as CustomElement
                // Remove bulleted list when backspace is pressed at the beginning of the list
                if (e.key === 'Backspace' && node.type === 'bulleted-list' && node.children.length === 1) {
                  e.preventDefault()
                  toggleBlock(editor, 'bulleted-list')
                }
                // Remove bulleted list when enter is pressed at an empty list item
                if (e.key === 'Enter' && node.type === 'bulleted-list') {
                  e.preventDefault()
                  toggleBlock(editor, 'bulleted-list')
                }
              }
            }
          }}
        />

        {!readOnly && (
          <div className='flex items-center bg-secondary-shade-5 p-2'>
            <RichTextControls />
            {children}
          </div>
        )}
      </div>
    </Slate>
  )
}

const getInitialValue = (initValue: string | null): Descendant[] => {
  try {
    if (!initValue) return [{ type: 'paragraph', children: [{ text: '' }] }]

    // Check if the initValue is in the legacy draftjs format which old activities can be
    if (initValue.startsWith('{"blocks"')) {
      const draftContent = JSON.parse(initValue) as RawDraftContentState
      return convertFromRawDraftToSlate(draftContent)
    }

    return JSON.parse(initValue) as Descendant[]
  } catch (e) {
    return [{ type: 'paragraph', children: [{ text: initValue ?? '' }] }]
  }
}

const Element = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case 'bulleted-list': {
      return <ul {...attributes}>{children}</ul>
    }
    case 'list-item': {
      return <li {...attributes}>{children}</li>
    }
    default: {
      return (
        <Typography component='p' variant='body1' {...attributes}>
          {children}
        </Typography>
      )
    }
  }
}

const leafRules = [
  {
    match: (n: Text) => n.bold === true,
    wrapContent: (children: React.ReactNode) => (
      <Typography className='font-medium' component='span'>
        {children}
      </Typography>
    )
  },
  {
    match: (n: Text) => n.italic === true,
    wrapContent: (children: React.ReactNode) => (
      <Typography className='italic' component='span'>
        {children}
      </Typography>
    )
  },
  {
    match: (n: Text) => n.underline === true,
    wrapContent: (children: React.ReactNode) => (
      <Typography className='underline' component='span'>
        {children}
      </Typography>
    )
  }
]

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const content = leafRules.reduce<React.ReactNode>((acc, rule) => {
    if (rule.match(leaf)) {
      return rule.wrapContent(acc)
    }
    return acc
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }, children)

  return <span {...attributes}>{content}</span>
}
