import { Chip, type ChipProps, cn } from '@strise/midgard'
import { t } from '@lingui/macro'
import * as React from 'react'

export const NewChip = ({ className, ...props }: ChipProps) => {
  return (
    <Chip
      className={cn(
        'my-0 h-auto w-10 rounded-[4px] bg-semantic-success-shade-10 px-0 py-[2px] text-center text-semantic-success-shade-100',
        className
      )}
      size='sm'
      label={t`New`}
      {...props}
    />
  )
}
