import Dagre from '@dagrejs/dagre'
import { type OwnerNodeType } from './OwnerNode'
import { type Edge } from '@xyflow/react'
import { type RenderNodeContent, type OwnershipChart, type RootEntity } from './types'
import { type RootNodeType } from './RootNode'

const nodeHeight = 80
const rootNodeHeight = 50
const nodeWidth = 200
const nodeSeparation = 100

const dagreGraph = new Dagre.graphlib.Graph()
dagreGraph.setGraph({ nodesep: nodeSeparation, ranksep: nodeSeparation })
dagreGraph.setDefaultEdgeLabel(() => ({}))

export const useOwnershipGraph = (
  chart: OwnershipChart,
  rootEntity: RootEntity,
  renderNodeContent?: RenderNodeContent,
  renderRootNodeContent?: RenderNodeContent
) => {
  chart.nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  dagreGraph.setNode(rootEntity.id, { width: nodeWidth, height: rootNodeHeight })

  chart.edges.forEach((edge) => {
    dagreGraph.setEdge(edge.parent, edge.child)
  })

  Dagre.layout(dagreGraph)

  const ownerNodes: OwnerNodeType[] = chart.nodes.map((node) => {
    const { height, width, x, y } = dagreGraph.node(node.id)
    return {
      id: node.id,
      data: { ...node, renderNodeContent },
      position: { x, y },
      style: { width, height },
      type: 'owner',
      connectable: !node.isLeaf
    }
  })

  const rootDagreNode = dagreGraph.node(rootEntity.id)
  const rootNode: RootNodeType = {
    id: rootEntity.id,
    data: { ...rootEntity, renderNodeContent: renderRootNodeContent },
    position: { x: nodeSeparation, y: rootDagreNode.y },
    style: { width: rootDagreNode.width, height: rootNodeHeight },
    type: 'root'
  }

  const nodes = [...ownerNodes, rootNode]

  // Map edges to React Flow edges
  const edges: Edge[] = chart.edges.map((edge) => ({
    id: `${edge.parent}-${edge.child}`,
    source: edge.parent,
    target: edge.child,
    label: edge.share,
    animated: false,
    labelBgStyle: { fill: 'white' },
    labelStyle: { fontSize: '10px' }
  }))
  return { nodes, edges }
}
