import { useLayoutEffect, useRef, useState } from 'react'
import * as jsPlumb from '@jsplumb/browser-ui'

export const usePlumb = (options: jsPlumb.BrowserJsPlumbDefaults) => {
  const plumb = useRef<jsPlumb.BrowserJsPlumbInstance | null>(null)
  const [, setReady] = useState(false)

  useLayoutEffect(() => {
    if (!options.container) return

    jsPlumb.ready(() => {
      if (!plumb.current) {
        plumb.current = jsPlumb.newInstance(options)
        setReady(true)
      }
    })

    return () => {
      if (plumb.current) {
        plumb.current.destroy()
      }
    }
  }, [])

  return plumb.current
}

export const extractPlumbContainer = (plumb: jsPlumb.BrowserJsPlumbInstance): Element | null => {
  return plumb.getContainer() as Element | null
}
