import { cn } from '@strise/midgard'
import * as React from 'react'
import { useDragScroll, type UseDragScrollOpts } from '@strise/europa'
import { Owners } from './Owners'
import { OwnershipSubsidiaries } from './OwnershipSubsidiaries'
import { OwnershipTargetEntity } from './OwnershipTargetEntity'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import { composeRefs, type DivProps } from '@strise/react-utils'
import { ContentLanguage } from '@strise/types'
import { BigNumber } from '@strise/fika'
import { OwnershipsContextProvider } from './OwnershipsContext/OwnershipsContextProvider'
import { useDebounceValue } from 'usehooks-ts'

interface OwnershipsProps extends DivProps {
  above25: boolean
  dragOpts?: UseDragScrollOpts
  editMode: boolean
  entity: EntityLikeMetaFragment
  isExport: boolean
  noFlags: boolean
  noLink: boolean
  noStatus: boolean
  noSubsidiaries?: boolean
  noSubsidiaryCount: boolean
  onLoaded?: () => void
  showOriginal: boolean
}

export const Ownerships = React.forwardRef<HTMLDivElement, OwnershipsProps>(
  (
    {
      above25,
      className,
      dragOpts,
      editMode,
      entity,
      isExport,
      noFlags,
      noLink,
      noStatus,
      noSubsidiaries,
      noSubsidiaryCount,
      onLoaded,
      showOriginal,
      ...props
    },
    ref
  ) => {
    const [containerEl, setContainerEl] = React.useState<HTMLDivElement | null>(null)
    const [targetEl, setTargetEl] = React.useState<HTMLDivElement | null>(null)
    const [scrollRef, setScrollRef] = React.useState<HTMLDivElement | null>(null)

    const isCompany = entity.__typename === 'Company' || entity.__typename === 'GlobalCompany'

    const loaded = React.useRef(false)
    const [ownersLoaded, setOwnersLoaded] = React.useState(false)
    const [subsLoading, setSubsLoading] = React.useState(0)
    const [subsLoaded] = useDebounceValue(subsLoading, 1000)

    const ownersHasLoaded = React.useCallback(() => {
      setOwnersLoaded(true)
    }, [])

    const dragProps = useDragScroll({
      scrollXYEl: scrollRef,
      ...dragOpts
    })

    React.useEffect(() => {
      const ownersLoadedOrIsNotCompany = ownersLoaded || !isCompany
      if (!loaded.current && ownersLoadedOrIsNotCompany && subsLoaded && onLoaded) {
        loaded.current = true
        onLoaded()
      }
    }, [ownersLoaded, subsLoaded])

    const danish =
      (entity.__typename === 'Company' && entity.registerCountry === ContentLanguage.Danish) ||
      (entity.__typename === 'Person' && entity.nationality === ContentLanguage.Danish)

    return (
      <OwnershipsContextProvider
        rootEntity={entity}
        danish={danish}
        noFlags={noFlags}
        noLink={noLink}
        noStatus={noStatus}
        noSubsidiaryCount={noSubsidiaryCount}
        isExport={isExport}
        editMode={editMode}
        above25={above25}
        showOriginal={showOriginal}
      >
        <div className={cn('overflow-auto', className)} ref={setScrollRef} {...props}>
          {/* Need a padding here to make the export pretty and to sure the self ownership is visible */}
          <div
            className={cn('relative py-2', { 'px-8': isExport })}
            ref={composeRefs(ref, setContainerEl)}
            id='ownerships'
          >
            <div {...dragProps}>
              {isCompany && containerEl && targetEl && (
                <Owners entity={entity} containerEl={containerEl} companyEl={targetEl} loaded={ownersHasLoaded} />
              )}

              <OwnershipTargetEntity className='ml-4' ref={setTargetEl} entity={entity} />

              {!noSubsidiaries && (
                // TODO - only hiding in it UI for now, but can probably also change this to not fetching this in the query as well somehow
                <OwnershipSubsidiaries
                  path={[entity.id]}
                  entity={entity}
                  setLoading={setSubsLoading}
                  indirectSharePercentage={new BigNumber(100)}
                  level={1}
                  lastInLevels={[]}
                  numOwnerships={undefined}
                />
              )}
            </div>
          </div>
        </div>
      </OwnershipsContextProvider>
    )
  }
)
