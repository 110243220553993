import * as React from 'react'
import { useNotificationsQuery } from '@graphqlOperations'
import { Notification } from '@components/Notifications/Notification'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { PaginationFooter } from '@components/PaginationFooter'
import { Alert, Skeleton } from '@strise/midgard'
import { Trans } from '@lingui/macro'

const size = 50
export const NotificationsView = () => {
  const [offset, setOffset] = React.useState(0)
  const { data, fetchMore, loading, refetch } = useNotificationsQuery({
    variables: { size, offset: 0 },
    skip: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  const handleNextPage = () => {
    const nextOffset = offset + size
    fetchMore({ variables: { offset: nextOffset, size } })
    setOffset(nextOffset)
  }

  const hasMoreNotifications = data?.notifications.edges.length === size + offset

  const [infiniteScrollItemRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMoreNotifications,
    onLoadMore: handleNextPage,
    disabled: loading,
    rootMargin: '0px 0px 30px 0px'
  })

  const notifications = data?.notifications.edges ?? []

  return (
    <div className='mx-auto mt-16 flex max-w-screen-xl flex-col justify-center'>
      <Alert variant='warning' className='w-full'>
        <Trans>This Notifications Page is in beta and subject to changes.</Trans>
      </Alert>
      {notifications.map(({ node: notification }) => (
        <Notification
          ref={infiniteScrollItemRef}
          key={notification.id}
          notification={notification}
          size={size}
          offset={offset}
          refetchNotifications={refetch}
          theme='light'
        />
      ))}
      {loading &&
        Array.from({ length: 6 }).map((_, index) => {
          return (
            <Skeleton className='mb-1 h-32 w-full animate-pulse bg-tertiary-shade-50' animation='pulse' key={index} />
          )
        })}
      {!!notifications.length && (
        <PaginationFooter
          nextPage={handleNextPage}
          hasMore={hasMoreNotifications}
          hideNoMore
          buttonProps={{
            className: 'w-full bg-secondary-90 text-primary-40 h-12',
            variant: 'ghost'
          }}
        />
      )}
    </div>
  )
}
