import deepmerge from 'deepmerge'
import { isObject } from 'lodash-es'

type ObjectKeys = string | number | symbol
export const isInObject = <Key extends ObjectKeys>(object: Record<Key, any>, testKey?: ObjectKeys): testKey is Key => {
  return !!testKey && testKey in object
}

export const getByPath = <T extends object>(obj: T, path: ReadonlyArray<string | number>): any => {
  if (!path.length) return obj

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!!path.length && (obj === null || !isObject(obj))) return undefined
  const [head] = path
  const childValue = obj[head as keyof T] as any as object
  return getByPath(childValue, path.slice(1))
}

export const setByPath = <T extends object | object[]>(obj: T, path: ReadonlyArray<string | number>, value: any): T => {
  if (!path.length) return value as T

  const [head] = path
  if (head === undefined) return value as T
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const newObj = obj ? obj[head as keyof T] : obj

  const child = setByPath(newObj as T, path.slice(1), value)
  if (Array.isArray(obj)) {
    const numberHead = Number.parseInt(head as string)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return [...obj.slice(0, numberHead), child, ...obj.slice(numberHead + 1)] as T
  }
  return {
    ...obj,
    [head]: child
  }
}

export const swapByPath = <T extends object>(
  obj: T,
  firstPath: ReadonlyArray<string | number>,
  secondPath: ReadonlyArray<string | number>
): T => {
  const firstValue: unknown = getByPath(obj, firstPath)
  const secondValue: unknown = getByPath(obj, secondPath)
  return setByPath(setByPath(obj, firstPath, secondValue), secondPath, firstValue)
}

export const reduceFlatten = <O extends object>(acc: O, cur: O) => ({
  ...acc,
  ...cur
})

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const overwriteMerge = (_: any, sourceArray: any[]) => sourceArray
export const mergeOverwriteArrays = <T1, T2>(a: Partial<T1>, b: Partial<T2>) =>
  deepmerge(a, b, { arrayMerge: overwriteMerge })

export { default as merge } from 'deepmerge'
