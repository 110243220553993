import { Button, cn, IconCheckBoxChecked, IconCheckBoxUnchecked, IconCheckSmall } from '@strise/midgard'
import * as React from 'react'
import {
  useMultipleSelection,
  useSelect,
  type UseSelectGetToggleButtonPropsOptions,
  type UseSelectState,
  type UseSelectStateChangeOptions
} from 'downshift'
import { MenuItem, type MenuItemProps } from '../MenuItem'
import { Popper, type PopperProps } from '../Popper'
import { Box, type BoxProps } from '../Box'
import { getNewSystemChangeEvent } from '../../events'
import { ButtonDropdown, type ButtonDropdownProps } from '../ButtonDropdown'
import { type DivProps } from '@strise/react-utils'

/** @deprecated use `Select` from @strise/midgard */
export interface SelectOption<T> {
  menuItemProps?: MenuItemProps
  renderText?: React.ReactNode
  text: string
  value: T
}

/** @deprecated use `Select` from @strise/midgard */
export interface SelectProps<T>
  extends Omit<BoxProps, 'children' | 'variant' | 'palette'>,
    Pick<ButtonDropdownProps, 'variant' | 'palette'> {
  buttonProps?: ButtonDropdownProps
  buttonText?: React.ReactNode
  children?: ({
    isOpen,
    toggleProps
  }: {
    isOpen: boolean
    toggleProps: () => Omit<UseSelectGetToggleButtonPropsOptions, 'ref' | 'size' | 'type'>
  }) => React.ReactNode
  disabled?: boolean
  emptyText?: React.ReactNode
  indicator?: ButtonDropdownProps['indicator']
  initValue?: T | T[]
  loading?: boolean
  multiple?: boolean
  onChange?: (value: T | T[]) => void
  onSave?: (value: T[]) => void
  options: Array<SelectOption<T>>
  paperHeader?: React.ReactNode
  paperProps?: DivProps
  placeholder?: string
  popperProps?: PopperProps
  required?: boolean
  saveButtonText?: string
  value?: T | T[]
}

const extractSelectedItems = <T extends string | number | null>(
  controlledState: boolean,
  controlledItems: T[],
  value: T[] | T | undefined
) => {
  if (controlledState) return controlledItems
  if (value === undefined) return []
  if (Array.isArray(value)) return value
  return [value]
}
const extractInitValueArr = <T extends string | number | null>(initValue: T[] | T | undefined) => {
  if (initValue === undefined) return []
  if (Array.isArray(initValue)) return initValue
  return [initValue]
}
const extractMenuItemStartIcon = (multiple: boolean | undefined, selected: boolean) => {
  if (!multiple) return
  if (selected) return <IconCheckBoxChecked />
  return <IconCheckBoxUnchecked />
}

/** @deprecated use `Select` from @strise/midgard */
export const Select = <T extends string | number | null>({
  buttonProps,
  buttonText,
  children,
  disabled,
  emptyText,
  indicator = true,
  initValue,
  loading,
  multiple,
  onChange,
  onSave,
  options = [],
  palette,
  paperHeader,
  paperProps,
  placeholder,
  popperProps,
  required,
  saveButtonText,
  value = [],
  variant,
  ...props
}: SelectProps<T>) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const toggleRef = React.useRef(null)

  const controlledState = initValue !== undefined
  const initValueArr = extractInitValueArr(initValue)

  const handleChange = (changeValue: T | T[]) => {
    if (onChange) onChange(changeValue)
    const systemChangeEvent = getNewSystemChangeEvent({
      value: Array.isArray(changeValue) ? changeValue.join(',') : String(changeValue)
    })
    if (ref.current && systemChangeEvent) {
      ref.current.dispatchEvent(systemChangeEvent)
    }
  }

  const {
    addSelectedItem,
    getDropdownProps,
    removeSelectedItem,
    selectedItems: controlledItems,
    setSelectedItems
  } = useMultipleSelection<T>({
    initialSelectedItems: initValueArr,
    onSelectedItemsChange: ({ selectedItems: changedSelectedItems }) => {
      if (changedSelectedItems) {
        handleChange(multiple ? changedSelectedItems : (changedSelectedItems[0] as T))
      }
    }
  })

  const selectedItems = extractSelectedItems(controlledState, controlledItems, value)

  const { closeMenu, getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex, isOpen, selectItem } =
    useSelect<SelectOption<T> | null>({
      stateReducer: (
        _state: UseSelectState<SelectOption<T> | null>,
        actionAndChanges: UseSelectStateChangeOptions<SelectOption<T> | null>
      ) => {
        const { changes, type } = actionAndChanges

        switch (type) {
          case useSelect.stateChangeTypes.ItemClick: {
            return {
              ...changes,
              isOpen: Boolean(multiple)
            }
          }
          default: {
            return changes
          }
        }
      },
      items: options,
      itemToString: (item) => (item ? item.text : ''),
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) return

        if (multiple) {
          if (selectedItems.includes(selectedItem.value)) {
            if (controlledState) removeSelectedItem(selectedItem.value)
            if (!controlledState) handleChange(selectedItems.filter((val) => val !== selectedItem.value))
          } else {
            if (controlledState) addSelectedItem(selectedItem.value)
            if (!controlledState) handleChange([...selectedItems, selectedItem.value])
          }
        } else {
          if (controlledState) setSelectedItems([selectedItem.value])
          if (!controlledState) handleChange(selectedItem.value)
        }

        selectItem(null)
      }
    })

  const handleSave = () => {
    if (onSave) {
      onSave(selectedItems)
      closeMenu()
      const systemChangeEvent = getNewSystemChangeEvent({ value: selectedItems.join(',') })
      if (ref.current && systemChangeEvent) {
        ref.current.dispatchEvent(systemChangeEvent)
      }
    }
  }

  return (
    <Box ref={ref} position='relative' data-system='select' {...props}>
      {children?.({
        toggleProps: () =>
          getToggleButtonProps(
            getDropdownProps({
              ref: toggleRef,
              preventKeyAction: isOpen
            })
          ),
        isOpen
      })}
      {!children && (
        <ButtonDropdown
          asChild
          variant={variant}
          palette={palette}
          indicator={isOpen ? 'open' : indicator}
          loading={loading}
          disabled={disabled}
          {...getToggleButtonProps(
            getDropdownProps({
              ref: toggleRef,
              preventKeyAction: isOpen
            })
          )}
          {...buttonProps}
          className={cn('justify-start', buttonProps?.className)}
        >
          <div>{buttonText || (selectedItems.length ? selectedItems.join(', ') : placeholder)}</div>
        </ButtonDropdown>
      )}

      <div {...getMenuProps()}>
        <Popper anchorEl={toggleRef.current} open={isOpen} {...popperProps}>
          <div {...paperProps} className={cn('bg-secondary-shade-90 text-white shadow-md', paperProps?.className)}>
            {paperHeader}
            {!options.length && emptyText}

            {options.map((item, index) => {
              const selected = selectedItems.includes(item.value)

              return (
                <MenuItem
                  startIcon={extractMenuItemStartIcon(multiple, selected)}
                  endIcon={!multiple && selected ? <IconCheckSmall /> : undefined}
                  key={index}
                  selected={highlightedIndex === index || (!multiple && selected)}
                  text={item.text}
                  {...item.menuItemProps}
                  {...getItemProps({ item, index })}
                >
                  {item.renderText}
                </MenuItem>
              )
            })}

            {onSave && (
              <Button
                className='w-full'
                variant='contained'
                palette='primary'
                onClick={handleSave}
                disabled={required && !selectedItems.length}
              >
                {saveButtonText}
              </Button>
            )}
          </div>
        </Popper>
      </div>
    </Box>
  )
}
