import { ExternalLink, IconButton, IconCrossSmall, IconGlobe, Typography } from '@strise/midgard'
import { Trans } from '@lingui/macro'
import { useDisableTemporarilyState } from '@strise/europa'
import { useHelpCenterLink } from '@utils/userHooks'
import * as React from 'react'

const globalEntityInfoUrl = 'articles/17732-what-is-a-global-entity'
const totalWeeklyHours = 24 * 14

export const SidepanelGlobalEntityBanner = () => {
  const helpCenterLink = useHelpCenterLink()
  const [showBanner, toggleBanner] = useDisableTemporarilyState('global-company-banner', totalWeeklyHours)

  if (!showBanner) return null

  return (
    <div className='flex h-12 items-center justify-between bg-accent-blue-shade-40 p-3'>
      <div className='flex items-center text-common-white'>
        <IconGlobe size='xl' className='text-common-white' />
        <Typography className='mx-2 text-common-white' variant='body1'>
          <Trans>Global entity</Trans>
        </Typography>
        <span>•</span>
        <Typography className='mx-2 text-secondary-shade-10' variant='body1'>
          <Trans>The amount of data may vary.</Trans>
        </Typography>
        <ExternalLink variant='body1' href={`${helpCenterLink}${globalEntityInfoUrl}`}>
          <Trans>What is a global entity?</Trans>
        </ExternalLink>
      </div>
      <IconButton
        className='p-0'
        onClick={toggleBanner}
        data-track='Button / Sidepanel / Global Company / Banner / Close'
      >
        <IconCrossSmall size='xl' className='text-common-white' />
      </IconButton>
    </div>
  )
}
