import { Divider, Typography } from '@strise/midgard'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { Timespan } from '@components/Timespan/Timespan'
import { type TableRowValueFragment } from '@graphqlTypes'
import { Trans } from '@lingui/macro'
import { groupBy } from 'lodash-es'
import React from 'react'

export const RoleWithDetailedEntity = ({
  value
}: {
  value: TableRowValueFragment & { __typename: 'TableRowValueRoleWithDetailedEntity' }
}) => {
  const groupedRoles = groupBy(value.otherRoles.edges, (edge) => edge.node.id)

  return (
    <div className='w-full'>
      <EntityLink entity={value.roleEntity} />

      <Typography className='mt-2 block' variant='aLabelBold'>
        <Trans>Other industries</Trans>
      </Typography>
      {value.otherIndustries.map((industryName, index) => {
        return <Typography key={index}>{industryName}</Typography>
      })}

      <Typography className='mt-2 block' variant='aLabelBold'>
        <Trans>Other roles</Trans>
      </Typography>
      {Object.entries(groupedRoles).map(([, edges], groupIndex) => {
        const isLast = groupIndex === Object.entries(groupedRoles).length - 1
        const company = edges[0]?.node

        if (!company) return null

        return (
          <React.Fragment key={groupIndex}>
            <div className='grid grid-cols-[2fr,3fr] gap-4'>
              <div className='min-h-flags-chip'>
                <EntityLink entity={company} />
              </div>
              <div>
                {edges.map((edge, index) => {
                  return (
                    <div className='flex min-h-flags-chip items-center justify-between' key={index}>
                      <Typography className='w-1/2'>{edge.role.roleTitle}</Typography>
                      <Timespan className='w-1/2' timespan={edge.role.period} isActive={edge.role.isActive} />
                    </div>
                  )
                })}
              </div>
            </div>
            {!isLast && <Divider />}
          </React.Fragment>
        )
      })}
    </div>
  )
}
