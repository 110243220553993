import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagBusinessPerson = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M15.996 7c0 .34-.028.675-.083 1H13.87a4.01 4.01 0 0 0 .126-1h-8c0 1.482.806 2.776 2.004 3.467v2.822A7.003 7.003 0 0 0 2.996 20v1H17.31v2H.996v-3a9.002 9.002 0 0 1 5.357-8.232A5.99 5.99 0 0 1 3.996 7h-3V5h3V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v3h3v2h-3Zm-1.994-2V3.001H5.99V5h8.012Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M8.667 10.667a2 2 0 0 1 2-2h10.666a2 2 0 0 1 2 2v10.666a2 2 0 0 1-2 2H10.667a2 2 0 0 1-2-2V10.667Z'
    />
    <path fill='#FFDE2E' d='M13.333 8.667h5.334v14.666h-5.334V8.667Z' />
    <path fill='#E50726' d='M18.667 8.667h2.666a2 2 0 0 1 2 2v10.666a2 2 0 0 1-2 2h-2.666V8.667Z' />
  </svg>
))
FlagBusinessPerson.displayName = 'FlagBusinessPerson'
