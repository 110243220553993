import { useEffect, useState } from 'react'
import dagre from '@dagrejs/dagre'

export const useGraph = (options: dagre.GraphLabel) => {
  const [graph, setGraph] = useState<dagre.graphlib.Graph | null>(null)

  useEffect(() => {
    if (!graph) {
      const graphInstance = new dagre.graphlib.Graph()
      graphInstance.setGraph(options)
      graphInstance.setDefaultEdgeLabel(() => ({}))
      setGraph(graphInstance)
    }
  }, [])

  return graph
}
