import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagGlobalCompany = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M8.667 10.667a2 2 0 0 1 2-2h10.666a2 2 0 0 1 2 2v10.666a2 2 0 0 1-2 2H10.667a2 2 0 0 1-2-2V10.667Z'
    />
    <path fill='#FFDE2E' d='M13.333 8.667h5.334v14.666h-5.334V8.667Z' />
    <path fill='#E50726' d='M18.667 8.667h2.666a2 2 0 0 1 2 2v10.666a2 2 0 0 1-2 2h-2.666V8.667Z' />
    <path
      fill='currentColor'
      d='M19 5V2c0-.552-.441-1-.993-1H1.997A.998.998 0 0 0 1 2v21h6v-4h1v-2H5v4H3V3h14v5h2V7h2v1h2V5h-4Z'
    />
    <path fill='currentColor' d='M5 7V5h2v2H5ZM5 15v-2h2v2H5ZM5 11V9h2v2H5ZM9 5h2v2H9V5ZM13 5h2v2h-2V5Z' />
  </svg>
))
FlagGlobalCompany.displayName = 'FlagGlobalCompany'
