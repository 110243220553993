import { cn, Typography } from '@strise/midgard'
import { Trans } from '@lingui/macro'
import { formatDate } from '@strise/europa'
import * as React from 'react'
import { type StyleProps } from '@strise/react-utils'

export const LastVisited = ({ className, lastVisited, ...props }: { lastVisited?: number } & StyleProps) => {
  if (!lastVisited) {
    return null
  }
  return (
    <Typography className={cn('text-text-secondary', className)} variant='aLabelSmall' {...props}>
      <Trans>Last visited:</Trans>
      <span className='block'>{formatDate(new Date(lastVisited))}</span>
    </Typography>
  )
}
