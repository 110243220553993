import * as React from 'react'
import { cn } from '../utils/className'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { type ExcludedStyleProps } from '@strise/react-utils'

export interface DividerProps
  extends Omit<React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>, ExcludedStyleProps> {}

export const Divider = React.forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, DividerProps>(
  ({ className, decorative = true, orientation = 'horizontal', ...props }, ref) => (
    <SeparatorPrimitive.Root
      ref={ref}
      className={cn('border-none bg-divider', orientation === 'horizontal' ? 'h-px w-auto' : 'h-auto w-px', className)}
      decorative={decorative}
      orientation={orientation}
      {...props}
    />
  )
)
Divider.displayName = 'Divider'
