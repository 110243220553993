import { cn, Skeleton, Typography } from '@strise/midgard'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'
import { type SidepanelTab } from '@strise/strise-utils'
import { isString } from 'lodash-es'

export interface SidepanelCardProps extends Omit<DivProps, 'title'> {
  children?: React.ReactNode
  description?: React.ReactNode | null
  headerElement?: React.ReactNode
  id?: string
  loading?: boolean
  tab?: SidepanelTab
  title?: React.ReactNode | null
}

export const SidepanelCard = React.forwardRef<HTMLDivElement, SidepanelCardProps>(
  ({ children, className, description, headerElement, id, loading, tab, title, ...props }, ref) => {
    return (
      <>
        {tab && <div ref={ref} data-tab={tab} />}
        <div className={cn('relative mb-2 bg-white', className)} {...props}>
          {(title || description) && (
            <div className='flex flex-col p-4 pb-2'>
              {!isString(title) && title}
              {title && isString(title) && (
                <Typography className='pb-2' variant='subtitle1' id={id}>
                  {title}
                </Typography>
              )}

              {!isString(description) && description}
              {description && isString(description) && (
                <Typography className='text-text-secondary' variant='body2'>
                  {description}
                </Typography>
              )}

              {loading && <Skeleton className='h-4 w-[312px]' />}
            </div>
          )}

          {headerElement}
          {!loading && children}
        </div>
      </>
    )
  }
)
