import { type Auth0Client, type User as Auth0User } from '@auth0/auth0-spa-js'
import { type AuthError } from './authError'
import { type SetStateFn } from '@strise/react-utils'
import { type TeamEdgeFragment } from '../../graphqlTypes'
import { createContext } from '../../utils/contextUtils'

export interface User {
  avatar: string
  email: string
  id: string
  name: string
  pseudonymizedName: string
  teams: TeamEdgeFragment[]
}

export interface AuthUser {
  id: string
  isAdmin: boolean
  isSupervisor: boolean
  teams: TeamEdgeFragment[]
}

interface AuthContextProps {
  auth0: Auth0Client | null
  authenticated: boolean
  onLogout?: (errorCode?: AuthError) => PromiseLike<boolean> | undefined
  setUser: SetStateFn<AuthUser | null>
  tokenMeta: Auth0User | null
  user: AuthUser | null
}

export const AuthContext = createContext<AuthContextProps>()
