import { compose, getContrastRatio, type Theme } from '@mui/system'
import { getByPath } from '@strise/fika'
import { type ThemeColor } from '../theme'
import { paletteStyle } from './paletteStyle'

export interface Colors {
  bgcolor?: ThemeColor
  color?: ThemeColor
  colorContrast?: ThemeColor
}

const CONTRAST_THRESHOLD = 3

const getContrastText = (theme: Theme, colorStr: string) => {
  try {
    return getContrastRatio(colorStr, theme.palette.common.white) >= CONTRAST_THRESHOLD
      ? theme.palette.text.primaryContrast
      : theme.palette.text.primary
  } catch {
    return theme.palette.text.primary
  }
}

const color = paletteStyle({ prop: 'color' })
const bgcolor = paletteStyle({
  prop: 'bgcolor',
  cssProperty: 'backgroundColor'
})
const colorContrast = paletteStyle({
  prop: 'colorContrast',
  cssProperty: 'color',
  transform: (value: string, colorStr: string, theme: Theme) => {
    const contrastText: unknown = getByPath(theme.palette, value.split('.'))?.contrastText
    if (contrastText) return contrastText
    return getContrastText(theme, colorStr)
  }
})

export const colors = compose(color, colorContrast, bgcolor)
