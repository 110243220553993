import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const FlagSe = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#FlagSE_svg__a)'>
      <path
        fill='#0052B4'
        d='M1 1h5.74l1.517.718L9.607 1H23v9.565l-.971 1.362.971 1.508V23H9.607l-1.375-.85L6.74 23H1v-9.565l.95-1.43-.95-1.44V1Z'
      />
      <path fill='#FFDA44' d='M6.74 1v9.565H1v2.87h5.74V23h2.867v-9.565H23v-2.87H9.607V1H6.74Z' />
    </g>
    <defs>
      <clipPath id='FlagSE_svg__a'>
        <rect width={22} height={22} x={1} y={1} fill='#fff' rx={11} />
      </clipPath>
    </defs>
  </svg>
))
FlagSe.displayName = 'FlagSe'
