import { Button, type ButtonProps, cn, Typography } from '@strise/midgard'
import { Trans } from '@lingui/macro'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'

export const PaginationFooter = ({
  buttonProps,
  className,
  hasMore,
  hideNoMore,
  name,
  nextPage,
  ...props
}: {
  buttonProps?: ButtonProps
  hasMore: boolean
  hideNoMore?: boolean
  name?: string
  nextPage: () => void
} & DivProps) => {
  if (hasMore) {
    return (
      <div className={cn('text-center', className)} {...props}>
        <Button variant='contained' palette='secondary' onClick={nextPage} data-track='false' {...buttonProps}>
          <Trans>Load more{name ? ` ${name}` : ''}</Trans>
        </Button>
      </div>
    )
  }

  if (hideNoMore) return null

  return (
    <div className={cn('py-1 text-center', className)} {...props}>
      <Typography component='span' variant='h6'>
        <Trans>No more {name}</Trans>
      </Typography>
    </div>
  )
}
