import * as React from 'react'
import { useApolloClient } from '@apollo/client/index.js'
import { type TeamSimpleUsersFragment, type TeamUsersQuery } from '@graphqlTypes'
import { team } from '@state'
import { useTeamUsersQuery } from '@graphqlOperations'
import {
  type SimpleUserConnection,
  SortOrdering,
  type TeamSimpleUsersArgs,
  UserField,
  type UserPageInfoInput
} from '@strise/types'

interface UseTeamUsersProps {
  notifyOnNetworkStatusChange?: boolean
  onCompleted?: (data: TeamUsersQuery) => void
  variables?: TeamSimpleUsersArgs
}

export const defaultTeamUsersPageVariables: UserPageInfoInput = {
  offset: 0,
  limit: 5000,
  sort: [
    {
      field: UserField.Name,
      ordering: SortOrdering.Ascending
    }
  ]
}

export const useTeamUsers = ({ notifyOnNetworkStatusChange, onCompleted, variables }: UseTeamUsersProps = {}) => {
  const { page, q } = variables ?? {}
  const { data, fetchMore, loading } = useTeamUsersQuery({
    variables: {
      q,
      page: {
        ...defaultTeamUsersPageVariables,
        ...page
      }
    },
    onCompleted,
    notifyOnNetworkStatusChange
  })

  const teamUsers = data?.team.users.edges ?? []
  const totalCount = data?.team.users.totalCount
  const hasNextPage = totalCount ? totalCount > teamUsers.length : false

  return { teamUsers, totalCount, hasNextPage, loading, fetchMore }
}

export const useSetTeamUsers = () => {
  const client = useApolloClient()

  return (edges: TeamSimpleUsersFragment['users']['edges']) => {
    const currentTeamId = team()
    if (!currentTeamId) return

    client.cache.modify({
      id: `Team:${currentTeamId}`,
      fields: {
        // @ts-expect-error
        simpleUsers(curr: SimpleUserConnection | undefined) {
          return {
            ...curr,
            edges
          }
        }
      }
    })
  }
}

const useTeamUserEdge = (userId?: string) => {
  const { teamUsers } = useTeamUsers()

  return React.useMemo(() => {
    if (!userId) return

    return teamUsers.find(({ node }) => node.id === userId)
  }, [userId, teamUsers])
}

export const useTeamUserStatus = (userId?: string) => {
  const teamUserEdge = useTeamUserEdge(userId)
  return teamUserEdge?.status
}
