import { cn, Typography } from '@strise/midgard'
import { OwnershipsContext } from '@components/Ownerships/OwnershipsContext/OwnershipsContext'
import { SanctionIcon } from '@components/SanctionIcon'
import { type BaseEntityLikeFragment, type EntityLikeMetaFragment } from '@graphqlTypes'
import { EntityFlag, extractIsActive, extractIsCompany, useContext } from '@strise/europa'
import * as React from 'react'
import { EntityIcon } from '../EntityIcon'
import { OwnerActionDropdown } from './edit-owner/EditOwner'
import { type DivProps } from '@strise/react-utils'
import { TestIDs } from '@utils/testIDs'

interface OwnershipTargetEntityProps extends DivProps {
  entity: BaseEntityLikeFragment
}

export const OwnershipTargetEntity = React.forwardRef<HTMLDivElement, OwnershipTargetEntityProps>(
  ({ className, entity, ...props }, ref) => {
    const { noFlags } = useContext(OwnershipsContext)
    const isActive = extractIsActive(entity)

    return (
      <div
        className={cn(
          'relative flex w-max items-center border-[3px] border-double border-secondary-shade-30 p-2',
          className
        )}
        ref={ref}
        data-id={TestIDs.SidePanel.Ownerships.targetEntity}
        {...props}
      >
        <EntityIcon className='mr-2' entity={entity} />
        <Typography variant='subtitle1' className={isActive ? undefined : 'text-text-secondary line-through'}>
          {entity.name}
        </Typography>
        {!noFlags && (
          <>
            <EntityFlag className='ml-2' entityOrId={entity} />
            <SanctionIcon className='ml-1' entityId={entity.id} sanctionsLink />
          </>
        )}

        {extractIsCompany(entity) && <OwnerActionDropdown entity={entity as EntityLikeMetaFragment} />}
      </div>
    )
  }
)
