import { type SetStateAction } from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { isFunction } from 'lodash-es'

// From: https://stackoverflow.com/a/47058976
// Modified to accept a TargetType instead of only string
export type PathsToTypedProps<T, TargetType> = T extends TargetType
  ? []
  : { [K in Extract<keyof T, string>]: [K, ...PathsToTypedProps<T[K], TargetType>] }[Extract<keyof T, string>]

export type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
    ? F extends string
      ? F
      : never
    : T extends [infer F, ...infer R]
      ? F extends string
        ? `${F}${D}${Join<Extract<R, string[]>, D>}`
        : never
      : string

// @ts-expect-error - This is a recursive type
export type DotPathToType<T extends object, TargetType> = Join<PathsToTypedProps<T, TargetType>, '.'>

export const getFromPath = <T extends object, TargetType>(
  value: T,
  path: DotPathToType<T, TargetType>
): TargetType | null => {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unnecessary-condition
  return path.split('.').reduce((subValue, key) => (subValue ? subValue[key] : undefined), value)
}

export const setChildStateFromPath = <T extends object, TargetType>(
  setValue: SetStateFn<T>,
  path: DotPathToType<T, TargetType>
): SetStateFn<TargetType> => {
  // @ts-expect-error
  return path.split('.').reduce((subSetValue, p) => setChildState(subSetValue, p), setValue)
}

export const setChildState = <T extends object, K extends keyof T>(
  setValue: SetStateFn<T>,
  key: K
): SetStateFn<T[K]> => {
  return (valueOrFn: SetStateAction<T[K]>) =>
    setValue((prevValue) => {
      return {
        ...prevValue,
        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression,@typescript-eslint/no-unnecessary-condition
        [key]: isFunction(valueOrFn) ? (valueOrFn as (value: T[K]) => void)(prevValue?.[key]) : valueOrFn
      }
    })
}

export const setNestedState =
  <T, K>(setState: SetStateFn<T>, selector: (state: T) => K): SetStateFn<K> =>
  (value: K | ((prevValue: K) => K)) => {
    const updateNestedState = (prevState: T, keys: Array<keyof T>): T => {
      if (keys.length === 1) {
        const lastKey = keys[0]

        if (!lastKey) return prevState

        const previousValue = prevState[lastKey] as K
        const newValue = isFunction(value) ? value(previousValue) : value
        return { ...prevState, [lastKey]: newValue }
      }
      const [firstKey, ...restKeys] = keys

      if (!firstKey) return prevState

      return {
        ...prevState,
        [firstKey]: updateNestedState(prevState[firstKey] as T, restKeys)
      }
    }

    setState((prevValue) => {
      const keys = (selector.toString().match(/(?<=\.)\w+/g) || []) as Array<keyof T>
      return updateNestedState(prevValue, keys)
    })
  }
