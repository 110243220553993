import { SelectCompanyAssignee } from '@components/Assignee/SelectCompanyAssignee'
import { SelectCompaniesStatus } from '@components/CompanyStatus/SelectCompaniesStatus'
import { companyInPortfolio } from '@components/CompanyStatus/companyStatusUtils'
import { EntityFlag } from '@components/EntityFlag'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { InputCompanyTags } from '@components/InputCompanyTags'
import { type ListViewCompanyFragment, type ListViewRevenueCompanyFragment } from '@graphqlTypes'
import { formatDate } from '@strise/europa'
import { cn, Typography } from '@strise/midgard'
import { toReadableFigure } from '@utils/financials'
import * as React from 'react'
import { type ColumnField, type EnabledColumns } from './companiesTableColumns'
import { isEntityActive } from '@utils/entity'

type PlainValue = string | number
export type Row = {
  [key in ColumnField]:
    | {
        plainValue: PlainValue | (() => PlainValue)
        renderValue: React.ReactNode
      }
    | undefined
}

const companyToRevenue = (company: ListViewRevenueCompanyFragment) => {
  const financials = company.groupFinancials ?? company.financials
  return {
    operatingRevenue: financials?.operatingRevenue,
    readableOperatingRevenue: () =>
      financials?.operatingRevenue
        ? `${toReadableFigure(financials.operatingRevenue / 1000, null, true)} M${financials.currency}`
        : '-'
  }
}

export const renderTableRow = (company: ListViewCompanyFragment, enabledColumns: EnabledColumns): Row => {
  const inPortfolio = companyInPortfolio(company.statusV2.status)
  const inactive = !isEntityActive(company)
  const { readableOperatingRevenue } = companyToRevenue(company)

  return {
    name: {
      plainValue: company.name ?? '',
      renderValue: (
        <EntityLink
          entity={company}
          noFlags
          className='hyphens-auto text-text-primary [overflow-wrap:anywhere]'
          noTooltip
        />
      )
    },
    flags: {
      plainValue: company.flags.edges.length,
      renderValue: <EntityFlag entityOrId={company} />
    },
    employees: enabledColumns.employees
      ? {
          plainValue: company.numberOfEmployees ?? '',
          renderValue: (
            <Typography component='span' className={cn(inactive && 'text-text-secondary')}>
              {company.numberOfEmployees ?? '-'}
            </Typography>
          )
        }
      : undefined,
    revenue: enabledColumns.revenue
      ? {
          plainValue: readableOperatingRevenue,
          renderValue: (
            <Typography component='span' className={cn(inactive && 'text-text-secondary')}>
              {readableOperatingRevenue()}
            </Typography>
          )
        }
      : undefined,
    hq: enabledColumns.hq
      ? {
          plainValue: company.headquartersLocation?.name ?? '',
          renderValue: company.headquartersLocation ? company.headquartersLocation.name : '-'
        }
      : undefined,
    statusModified: enabledColumns.statusModified
      ? {
          plainValue: () =>
            company.statusV2.modifiedAt
              ? formatDate(company.statusV2.modifiedAt, {
                  relative: false,
                  time: true
                })
              : '',
          renderValue: company.statusV2.modifiedAt ? formatDate(company.statusV2.modifiedAt) : '-'
        }
      : undefined,
    status: enabledColumns.status
      ? {
          plainValue: company.statusV2.status,
          renderValue: (
            <SelectCompaniesStatus
              companies={[company]}
              triggerIconVisibility='hover'
              className='size-full min-h-[60px] px-4 py-2'
              variant='ghost'
              data-track-context='portfolio'
            />
          )
        }
      : undefined,
    tags: enabledColumns.tags
      ? {
          plainValue: (company.tags?.edges.map(({ node }) => node.name) ?? []).join(', '),
          renderValue: (
            <InputCompanyTags
              tags={inPortfolio ? (company.tags?.edges ?? []) : []}
              disabled={!inPortfolio}
              companyIds={[company.id]}
              cacheKeys={company.tags ? { [company.id]: company.tags.key } : {}}
              className='h-full'
              data-track-context='portfolio'
            />
          )
        }
      : undefined,
    assignees: enabledColumns.assignees
      ? {
          plainValue: company.assignee?.edges.map(({ node }) => node.name).join(', ') ?? '',
          renderValue: (
            <SelectCompanyAssignee
              disabled={!inPortfolio}
              assignees={inPortfolio ? (company.assignee?.edges ?? []) : []}
              companyIds={[company.id]}
              className='h-full'
              data-track-context='portfolio'
            />
          )
        }
      : undefined
  }
}
