import qs from 'qs'
import { filterQueryString, updateQueryString } from '@strise/fika'
import type { LinkProps as UILinkProps } from '@strise/midgard'

export interface LinkProps extends Omit<UILinkProps, 'href' | 'search'> {
  newQueryParams?: qs.ParsedQs
  queryParamsToPreserve?: string[]
  to: string
}

export interface MutableNewUrlProps extends LinkProps {
  existingQueryString: string
}

export const getNewUrl = ({
  existingQueryString,
  newQueryParams,
  queryParamsToPreserve,
  to
}: MutableNewUrlProps): string => {
  const [newPathname, newQueryStringInLink] = to.split('?')
  const newQueryParamsInLink = newQueryStringInLink ? qs.parse(newQueryStringInLink) : {}
  const mergedParams = { ...newQueryParamsInLink, ...newQueryParams }
  const filteredQueryString = queryParamsToPreserve ? filterQueryString(existingQueryString, queryParamsToPreserve) : ''
  const newQueryString = updateQueryString(filteredQueryString, mergedParams)

  return `${newPathname}${newQueryString.length ? `?${newQueryString}` : ''}`
}
