import { type RealEstateFragment, type SidepanelCompanyFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { SidepanelCard } from '@components/Sidepanel/SidepanelCard'
import * as React from 'react'
import { DataSources } from '@components/Sidepanel/DataSources'
import { ContentLanguage } from '@strise/types'
import { Alert, cn, IconInfoBlue, IconWarningYellow, Tooltip, Typography } from '@strise/midgard'
import { formatDate, Empty } from '@strise/europa'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { TruncatedList } from '@components/TruncatedList'
import { formatNumber } from '@strise/fika'

export const SidepanelRealEstateCard = ({ company }: { company: SidepanelCompanyFragment }) => {
  if (!company.realEstates && company.registerCountry !== ContentLanguage.Norwegian) {
    return null
  }

  return (
    <SidepanelCard title={t`Real estate`}>
      <div className='flex flex-col px-4'>
        <SidepanelRealEstateContent realEstates={company.realEstates} />
      </div>
      <DataSources dataSources={company.dataSources.realEstate} />
    </SidepanelCard>
  )
}

const SidepanelRealEstateContent = ({ realEstates }: { realEstates: RealEstateFragment[] | null | undefined }) => {
  if (!realEstates?.length) {
    return (
      <Empty title={t`No real estate found`}>
        <Trans>We couldn't find any real estate on this company</Trans>
      </Empty>
    )
  }

  return (
    <>
      {realEstates.length === 100 && (
        <Alert variant='warning' size='sm'>
          <Trans>We've limited the maximum number of units to 100</Trans>
        </Alert>
      )}
      <TruncatedList items={realEstates} truncateAfter={3} context='Sidepanel real estate'>
        {(realEstate, index, isLast) => {
          return (
            <div key={index} className={cn('flex border-b border-tertiary-main', { 'border-none': isLast })}>
              <div className='w-1/3 py-2'>
                <div className='flex items-center'>
                  <div>
                    {realEstate.info.map((i, infoIndex) => {
                      return <Typography key={infoIndex}>{i}</Typography>
                    })}
                  </div>
                  <div className='ml-2'>
                    {!!realEstate.warnings.length && (
                      <Tooltip
                        content={
                          <div className='flex flex-col gap-2'>
                            {realEstate.warnings.map((warning, warningIndex) => {
                              return (
                                <div key={warningIndex}>
                                  {warning.title}: {warning.description ?? '-'} (
                                  {formatDate(warning.date, { relative: false })})
                                </div>
                              )
                            })}
                          </div>
                        }
                      >
                        <IconWarningYellow />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
              <div className='w-2/3'>
                <TruncatedList items={realEstate.documents} truncateAfter={3} context='Sidepanel real estate documents'>
                  {(document, documentIndex, isLastDocument) => {
                    return (
                      <div
                        key={documentIndex}
                        className={cn('flex items-center border-b border-tertiary-main py-2', {
                          'border-none': isLastDocument
                        })}
                      >
                        <div className='w-1/2 px-2'>
                          <div className='flex items-center'>
                            <div>
                              <Typography className='text-text-secondary' key={documentIndex}>
                                {document.title}
                              </Typography>
                              {document.creditors.map((creditor) => {
                                return <EntityLink key={creditor.id} entity={creditor} />
                              })}
                              {document.otherCreditorNames.map((name, nameIndex) => {
                                return (
                                  <Typography key={nameIndex} className='min-h-flags-chip'>
                                    {name}
                                  </Typography>
                                )
                              })}
                            </div>
                            {document.tooltip && (
                              <div className='ml-6'>
                                <Tooltip content={document.tooltip}>
                                  <IconInfoBlue />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='w-1/2'>
                          <div className='flex items-center justify-end'>
                            <Typography>
                              {document.amount ? formatNumber(document.amount) : '-'} {document.currency}
                            </Typography>
                            <div className='ml-6 w-8'>
                              {document.amountTooltip && (
                                <Tooltip content={document.amountTooltip}>
                                  <IconInfoBlue />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </TruncatedList>
              </div>
            </div>
          )
        }}
      </TruncatedList>
    </>
  )
}
