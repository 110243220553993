import { spoofUser } from '@state'
import { STORAGE_KEYS } from '@constants'
import { getBrowserGlobals } from '@strise/system'

export const spoof = {
  init: () => {
    const spoofQueryParam = new URLSearchParams(getBrowserGlobals()?.window.location.search).get(
      STORAGE_KEYS.spoofUserId
    )

    if (spoofQueryParam) {
      getBrowserGlobals()?.window.sessionStorage.setItem(STORAGE_KEYS.spoofUserId, spoofQueryParam)
    }

    const spoofUserId = getBrowserGlobals()?.window.sessionStorage.getItem(STORAGE_KEYS.spoofUserId)
    if (spoofUserId) {
      spoofUser(spoofUserId)
    }
  },
  start: (userId: string) => {
    getBrowserGlobals()?.window.sessionStorage.setItem(STORAGE_KEYS.spoofUserId, userId)
    getBrowserGlobals()?.window.sessionStorage.setItem(
      STORAGE_KEYS.spoofUserUrl,
      getBrowserGlobals()?.window.location.pathname || ''
    )
    getBrowserGlobals()?.window.location.replace('/')
  },
  stop: () => {
    getBrowserGlobals()?.window.sessionStorage.removeItem(STORAGE_KEYS.spoofUserId)

    const redirectUrl = getBrowserGlobals()?.window.sessionStorage.getItem(STORAGE_KEYS.spoofUserUrl) || '/'
    getBrowserGlobals()?.window.sessionStorage.removeItem(STORAGE_KEYS.spoofUserUrl)

    getBrowserGlobals()?.window.location.replace(redirectUrl)
  }
}
