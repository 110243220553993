import * as React from 'react'
import { cn, Link as UILink } from '@strise/midgard'
import { Link as RDLink, useLocation } from 'react-router-dom'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import qs from 'qs'
import { getNewUrl, type LinkProps } from '../utils/link'

export const ReactRouterLink = React.forwardRef<HTMLElement, LinkProps>(
  ({ className, newQueryParams, queryParamsToPreserve, to, ...props }, ref) => {
    const location = useLocation()
    const existingQueryString = location.search

    const newUrl = getNewUrl({
      queryParamsToPreserve,
      to,
      newQueryParams,
      existingQueryString
    })

    return (
      <UILink
        className={cn('block', className)}
        ref={ref}
        component={RDLink}
        // @ts-expect-error
        to={newUrl}
        {...props}
      />
    )
  }
)

export const NextJsLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, newQueryParams, queryParamsToPreserve, to, ...props }, ref) => {
    const router = useRouter()
    // https://github.com/ljharb/qs?tab=readme-ov-file#rfc-3986-and-rfc-1738-space-encoding
    const existingQueryString = qs.stringify(router.query, { format: 'RFC1738' })

    const newUrl = getNewUrl({
      queryParamsToPreserve,
      to,
      newQueryParams,
      existingQueryString
    })

    return <UILink className={cn('block', className)} ref={ref} component={NextLink} href={newUrl} {...props} />
  }
)
