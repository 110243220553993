import { useCurrentUserEnabledContentLanguages } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { ApplicationSearchReturnType, ContentLanguage } from '@strise/types'
import { useSearchQuery } from '@graphqlOperations'
import { type SearchItemEntity } from '@components/Search/Search'
import { extractEntityContentLanguage, extractIsGlobalEntity, useContext } from '@strise/europa'
import { RecentlyVisitedEntitiesContext } from '@contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'
import * as React from 'react'
import { isError, orderBy, uniq } from 'lodash-es'
import { filterNullishValues } from '@strise/fika'
import ENTITY_META_QUERY from '../../../graphql/entitites/queries/entityMeta.graphql'
import { isApolloError, useApolloClient } from '@apollo/client/index.js'
import { type EntityMetaQuery, type EntityMetaQueryVariables } from '@graphqlTypes'
import { logNetworkError } from '../../apolloClient/apolloLinks'

export const MAX_SEARCH_RESULTS = 60

export enum EntityLocationFilterKind {
  ALL = 'ALL',
  NORDICS = 'NORDICS',
  UK = 'UK',
  GLOBAL = 'GLOBAL'
}

export const reviewableEntityLocationFilters = [EntityLocationFilterKind.NORDICS, EntityLocationFilterKind.UK]

const getEntityLocationFilter = (
  entityLocationFilterState: EntityLocationFilterKind,
  enabledContentLanguages: ContentLanguage[]
) => {
  switch (entityLocationFilterState) {
    case EntityLocationFilterKind.ALL: {
      return enabledContentLanguages
    }
    case EntityLocationFilterKind.NORDICS: {
      return [
        ContentLanguage.Swedish,
        ContentLanguage.Danish,
        ContentLanguage.Norwegian,
        ContentLanguage.Finnish
      ].filter((contentLanguage) => enabledContentLanguages.includes(contentLanguage))
    }
    case EntityLocationFilterKind.UK: {
      return [ContentLanguage.English].filter((contentLanguage) => enabledContentLanguages.includes(contentLanguage))
    }
    case EntityLocationFilterKind.GLOBAL: {
      return []
    }
  }
}

export const useSearch = (
  debouncedInputValue: string | null,
  entityLocationFilterStates: EntityLocationFilterKind[],
  entityKindFilterState: ApplicationSearchReturnType,
  countries?: ContentLanguage[]
) => {
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()

  const getContentLanguageFilter = () => {
    const contentLanguages = entityLocationFilterStates.flatMap((entityLocationFilterState) =>
      getEntityLocationFilter(entityLocationFilterState, enabledContentLanguages)
    )

    return uniq(contentLanguages)
  }

  const withGlobal =
    entityLocationFilterStates.includes(EntityLocationFilterKind.ALL) ||
    entityLocationFilterStates.includes(EntityLocationFilterKind.GLOBAL)

  const { data, loading } = useSearchQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      q: debouncedInputValue!,
      input: {
        first: MAX_SEARCH_RESULTS,
        countries: countries ?? getContentLanguageFilter(),
        withGlobal,
        returnType: entityKindFilterState
      }
    },
    skip: !(debouncedInputValue && debouncedInputValue.length > 1)
  })
  const edges = data?.personsOrCompanies.edges ?? []
  const searchItems = edges.map((edge) => edge.node)

  return { searchItems, loading }
}

const handleQueryError = (error: unknown) => {
  if (!isError(error)) {
    console.error('Unknown EntityMeta error', error)
    return
  }
  if (!isApolloError(error)) {
    console.error('EntityMeta error', error.message)
    return
  }

  // apollo.query doesn't  use error link, so we need to handle it manually
  const statusCode = error.networkError && 'statusCode' in error.networkError && error.networkError.statusCode
  logNetworkError(statusCode, error.message)
}

export const useRecentlyVisitedEntities = (
  entityLocationFilterState: EntityLocationFilterKind,
  entityKindFilterState: ApplicationSearchReturnType,
  maxItems: number
): { recentlyVisitedEntities: SearchItemEntity[]; recentlyVisitedEntitiesLoading: boolean } => {
  const apolloClient = useApolloClient()

  const { recentlyVisitedEntitiesMap } = useContext(RecentlyVisitedEntitiesContext)
  const [recentlyVisitedEntities, setRecentlyVisitedEntities] = React.useState<SearchItemEntity[]>([])
  const [recentlyVisitedEntitiesLoading, setRecentlyVisitedEntitiesLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    const fetchEntities = async () => {
      setRecentlyVisitedEntitiesLoading(true)

      const entries = Object.entries(recentlyVisitedEntitiesMap)
      const sortedEntries = orderBy(entries, ([, value]) => -value.visitedAt)
      const slicedEntries = sortedEntries.slice(0, maxItems)

      const entities = await Promise.all(
        slicedEntries.map(async ([id, recentlyVisited]) => {
          // Using apolloClient.query instead of lazy query to cache bugs related to parallel queries: https://github.com/apollographql/apollo-client/issues/9755
          const result = await apolloClient
            .query<EntityMetaQuery, EntityMetaQueryVariables>({
              query: ENTITY_META_QUERY,
              variables: { id },
              context: { hideErrorToast: true }
            })
            .catch((err: unknown) => handleQueryError(err))

          const entity = result?.data?.entity

          if (!entity?.name) return null

          // Seems to be some kind of issue with Apollo cache on 403, where it returns the wrong entity
          if (id !== entity.id) return null

          return {
            ...entity,
            visitedAt: recentlyVisited.visitedAt
          }
        })
      )

      const entitiesWithoutNullish = filterNullishValues(entities)

      const filtered = entitiesWithoutNullish.filter((entity) => {
        const isGlobalEntityV2 =
          (entity.__typename === 'Company' && entity.isGlobalCompany) ||
          (entity.__typename === 'Person' && entity.isGlobalPerson)

        if (entityKindFilterState === ApplicationSearchReturnType.Company && !entity.__typename.includes('Company')) {
          return false
        }
        if (entityKindFilterState === ApplicationSearchReturnType.Person && !entity.__typename.includes('Person')) {
          return false
        }

        if (
          entityLocationFilterState === EntityLocationFilterKind.NORDICS &&
          (extractIsGlobalEntity(entity) ||
            extractEntityContentLanguage(entity) === ContentLanguage.English ||
            isGlobalEntityV2)
        ) {
          return false
        }
        if (
          entityLocationFilterState === EntityLocationFilterKind.GLOBAL &&
          !extractIsGlobalEntity(entity) &&
          !isGlobalEntityV2
        ) {
          return false
        }
        if (
          entityLocationFilterState === EntityLocationFilterKind.UK &&
          (extractEntityContentLanguage(entity) !== ContentLanguage.English ||
            (extractEntityContentLanguage(entity) === ContentLanguage.English && isGlobalEntityV2))
        ) {
          return false
        }

        return true
      })

      setRecentlyVisitedEntitiesLoading(false)
      setRecentlyVisitedEntities(filtered)
    }

    fetchEntities()
  }, [recentlyVisitedEntitiesMap, entityLocationFilterState, entityKindFilterState, maxItems])

  return { recentlyVisitedEntities, recentlyVisitedEntitiesLoading }
}
