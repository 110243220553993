import { Combobox, type ComboboxItem, type ComboboxProps, LoaderRound } from '@strise/midgard'
import * as React from 'react'
import { t } from '@lingui/macro'
import { TagActions } from '../Tags/TagActions'
import { useExtractTagValue, useTeamTags } from '@components/Tags/tagUtils'
import { type CompanyTagFragment } from '@graphqlTypes'
import { useContext, useDependencyState } from '@strise/europa'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const GrowTagsSelect: React.FC<ComboboxProps<CompanyTagFragment>> = ({ className, ...props }) => {
  const { settings } = useContext(CurrentUserSettingsContext)
  const [selectedTags, setSelectedTags] = useDependencyState<CompanyTagFragment[]>(settings.grow.tags, [
    settings.grow.tags
  ])
  const { loading, tags: tagEdges } = useTeamTags()
  const options = tagEdges.map(({ node }) => {
    const actions = (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => (
      <TagActions tag={node} setOpen={setOpen} />
    )

    return {
      label: node.name,
      id: node.id,
      value: node,
      actions
    }
  })

  const extractTagValue = useExtractTagValue()

  const addHandler = async (newTag: ComboboxItem<CompanyTagFragment>) => {
    const tagValue = await extractTagValue(newTag)

    if (!tagValue) return

    if (!selectedTags.some((item) => item.id === tagValue.id)) {
      const newTags = [...selectedTags, tagValue]
      setSelectedTags(newTags)
    }
  }

  const removeHandler = (item: ComboboxItem<CompanyTagFragment>) => {
    const newTags = selectedTags.filter((tag) => tag.id !== item.id)
    setSelectedTags(newTags)
  }

  if (loading) return <LoaderRound size='sm' />

  return (
    <Combobox
      value={selectedTags.map((tag) => ({ id: tag.id, label: tag.name, value: tag }))}
      items={options}
      inlineSearch
      showItemsAsChips
      loading={loading}
      onAdd={addHandler}
      onRemove={removeHandler}
      className={className}
      data-track='Grow / Tags Select'
      {...props}
    >
      {t`No tags`}
    </Combobox>
  )
}
