import { defineMessage } from '@lingui/macro'
import { useCurrentUserEnabledContentLanguages } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { enumOptions } from './enum'
import { type MessageDescriptor } from '@lingui/core'
import { objectKeys } from '@strise/fika'
import { ContentLanguage, CountryKind } from '@strise/types'

export const countryLabels: { [key in ContentLanguage]?: MessageDescriptor } = {
  [ContentLanguage.Norwegian]: defineMessage({ message: 'Norway' }),
  [ContentLanguage.Swedish]: defineMessage({ message: 'Sweden' }),
  [ContentLanguage.Danish]: defineMessage({ message: 'Denmark' }),
  [ContentLanguage.Finnish]: defineMessage({ message: 'Finland' }),
  [ContentLanguage.English]: defineMessage({ message: 'United Kingdom' }),
  [ContentLanguage.French]: defineMessage({ message: 'France' }),
  [ContentLanguage.German]: defineMessage({ message: 'Germany' }),
  [ContentLanguage.Spanish]: defineMessage({ message: 'Spain' })
}

export const useCountryOptions = () => {
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()

  const filteredLanguages = objectKeys(countryLabels).filter((lang) => enabledContentLanguages.includes(lang))

  return enumOptions(filteredLanguages, countryLabels)
}

// Convert all content languages to country kinds
export const contentLanguageToCountryKind: Record<ContentLanguage, CountryKind> = {
  [ContentLanguage.Norwegian]: CountryKind.No,
  [ContentLanguage.Swedish]: CountryKind.Se,
  [ContentLanguage.Danish]: CountryKind.Dk,
  [ContentLanguage.Finnish]: CountryKind.Fi,
  [ContentLanguage.English]: CountryKind.Gb,
  [ContentLanguage.French]: CountryKind.Fr,
  [ContentLanguage.German]: CountryKind.De,
  [ContentLanguage.Spanish]: CountryKind.Es
}

// Convert all country kinds to content languages
export const countryKindToContentLanguage: Partial<Record<CountryKind, ContentLanguage>> = {
  [CountryKind.No]: ContentLanguage.Norwegian,
  [CountryKind.Se]: ContentLanguage.Swedish,
  [CountryKind.Dk]: ContentLanguage.Danish,
  [CountryKind.Fi]: ContentLanguage.Finnish,
  [CountryKind.Gb]: ContentLanguage.English,
  [CountryKind.Fr]: ContentLanguage.French,
  [CountryKind.De]: ContentLanguage.German,
  [CountryKind.Es]: ContentLanguage.Spanish
}
