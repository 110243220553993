import * as React from 'react'
import {
  Button,
  type ButtonProps,
  cn,
  IconChevronDownSuperSmall,
  IconChevronUpSuperSmall,
  type IconComponent,
  type IconPropsWithRef
} from '@strise/midgard'

export type ButtonDropdownProps = {
  IndicatorIcon?: IconComponent
  iconProps?: IconPropsWithRef
  indicator?: boolean | 'hover' | 'open'
} & ButtonProps

export const ButtonDropdown = React.forwardRef<HTMLButtonElement, ButtonDropdownProps>(
  ({ IndicatorIcon, className, iconProps, indicator = true, palette = 'tertiary', variant, ...props }, ref) => {
    const IndicatorIcon2 = IndicatorIcon || (indicator === 'open' ? IconChevronUpSuperSmall : IconChevronDownSuperSmall)
    const hoverClass = indicator === 'hover' ? 'group-hover/button-dropdown:opacity-100 opacity-0' : ''

    // TODO - create a better palette system in @strise/midgard
    const openClass = variant && palette === 'tertiary' && indicator === 'open' ? 'bg-tertiary-main' : ''

    return (
      <Button
        className={cn('group/button-dropdown px-2', openClass, className)}
        ref={ref}
        endIcon={
          indicator ? (
            <IndicatorIcon2 className={cn('pointer-events-none ml-auto shrink-0', hoverClass)} {...iconProps} />
          ) : undefined
        }
        variant={variant}
        palette={palette}
        {...props}
      />
    )
  }
)
