import * as React from 'react'
import { t, Trans } from '@lingui/macro'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { ContactStriseToEnable } from '../ContactStrise'
import { useDownloadReview, useHasReviewFile } from '@utils/reviewUtils'
import { Button, type ButtonProps, Chip, LoaderRound } from '@strise/midgard'
import { type DivProps } from '@strise/react-utils'
import { type ReviewFragment } from '@graphqlTypes'
import { TestIDs } from '@utils/testIDs'

const DefaultDownloadButton = (props: ButtonProps) => {
  return (
    <Button
      variant='ghost'
      palette='primary'
      data-track='Download Risk assessment report'
      data-id={TestIDs.Review.Card.downloadPdfButton}
      {...props}
    >
      <Trans>View report</Trans>
    </Button>
  )
}

export const DownloadReviewPdf = ({
  DownloadButton = DefaultDownloadButton,
  hideContactSales,
  review,
  ...props
}: {
  DownloadButton?: React.FC<ButtonProps>
  hideContactSales?: boolean
  review: ReviewFragment
} & ButtonProps &
  DivProps) => {
  const features = useCurrentUserFeatures()
  const { hasFile, isOver1HourOld } = useHasReviewFile(review)

  const { downloadReview, loading } = useDownloadReview()

  const handleClick = (event: React.MouseEvent) => {
    if (!hasFile) return

    event.preventDefault()
    downloadReview(review.id)
  }

  if (!features.REVIEW_PDF && !hideContactSales) {
    return <ContactStriseToEnable intention={t`enable the Review PDF feature`} {...props} />
  }

  if (!features.REVIEW_PDF) {
    return null
  }

  if (!hasFile && isOver1HourOld) {
    return (
      <div className='flex h-10 items-center px-5'>
        <Trans>No report</Trans>
      </div>
    )
  }

  if (!hasFile) {
    return (
      <div className='flex h-10 items-center px-5'>
        <Chip
          label={
            <div className='flex items-center gap-x-2'>
              <Trans>Loading</Trans>
              <LoaderRound size='xs' />
            </div>
          }
        />
      </div>
    )
  }

  return <DownloadButton onClick={handleClick} loading={loading} {...props} />
}
