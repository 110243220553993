import { Skeleton } from '@strise/midgard'
import * as React from 'react'

export const TitleSkeleton = () => {
  return <Skeleton className='h-[30px] w-[90px]' />
}

export const CheckboxColumnSkeleton = () => {
  return <Skeleton className='h-flags-chip w-[90px]' />
}

export const RowValueSkeleton = () => {
  return <Skeleton className='h-flags-chip w-full' />
}
