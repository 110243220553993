import * as Sentry from '@sentry/react'
import { getBrowserGlobals } from '@strise/system'

const init = () => {
  Sentry.init({
    dsn: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.SENTRY_DSN,
    environment: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.SENTRY_ENVIRONMENT,
    tunnel: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.SENTRY_TUNNEL,
    denyUrls: [/api=[A-Z]+/],
    ignoreErrors: [
      /ResizeObserver loop limit exceeded/,
      /ResizeObserver loop completed with undelivered notifications/
    ],
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error']
      })
    ]
  })
}

export const sentry = {
  init
}
