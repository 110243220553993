import { Button, IconPlus } from '@strise/midgard'
import * as React from 'react'
import { type User, UserAvatar } from '@strise/europa'
import { Trans } from '@lingui/macro'
import { InviteUsers } from '@components/InviteUsers'
import { useTeam } from '@contexts/TeamContext/TeamContext'

const InviteNewUsersButtonStartIcon: React.FC<{ currentUser: User }> = ({ currentUser }) => {
  return (
    <div className='relative flex items-center'>
      <IconPlus className='absolute left-[12px] mr-3' size='md' />
      <UserAvatar className='mr-3 size-10 rounded-full opacity-10' user={currentUser} />
    </div>
  )
}

export const ActivityInviteNewUsers: React.FC<{ currentUser: User }> = ({ currentUser }) => {
  const team = useTeam()

  const [isInviteUserOpen, setIsInviteUserOpen] = React.useState<boolean>(false)

  const openInviteUserModal = (): void => setIsInviteUserOpen(true)

  return (
    <>
      <Button
        startIcon={<InviteNewUsersButtonStartIcon currentUser={currentUser} />}
        className='pl-0 hover:bg-transparent active:bg-transparent'
        palette='primary'
        variant='ghost'
        data-track='Activity View / Most Active Members / Invite your colleagues'
        onClick={openInviteUserModal}
        data-id='Activity View / Invite your colleagues button'
      >
        <Trans>Invite your colleagues</Trans>
      </Button>

      {isInviteUserOpen && <InviteUsers team={team} setOpen={setIsInviteUserOpen} />}
    </>
  )
}
