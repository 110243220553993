import * as React from 'react'
import { type SetStateFn } from './types'
import { isFunction, isObject } from 'lodash-es'

type Ref<T extends HTMLElement> = Array<
  SetStateFn<T | null> | React.MutableRefObject<T | null> | React.ForwardedRef<T | null>
>

export const composeRefs = <T extends HTMLElement>(...args: Ref<T>) => {
  return (node: T | null) => {
    args.forEach((arg) => {
      if (arg) {
        if (isFunction(arg)) {
          arg(node)
        } else if (isObject(arg)) {
          arg.current = node
        }
      }
    })
  }
}

// https://www.totaltypescript.com/forwardref-with-generic-components
export const genericForwardRef = <T, P>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode
): ((props: P & React.RefAttributes<T>) => React.ReactNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return
  return React.forwardRef(render) as any
}
