import { cn, Typography } from '@strise/midgard'
import { DataSourceTooltip } from '@components/DataSourceTooltip'
import { type ActivityStatFragment } from '@graphqlTypes'
import { FilledSparkLine } from '@strise/europa'
import * as React from 'react'
import { useActivityStatsStyle } from '../utils/activityHooks'
import { type DivProps } from '@strise/react-utils'

export const ActivityUserStats: React.FC<
  {
    activityStat: ActivityStatFragment | undefined
    label: string
  } & DivProps
> = ({ activityStat, className, label, ...props }) => {
  const { ActivityStatsIcon, borderRadius, iconColorClassName, percentage, pr, tooltipContent } = useActivityStatsStyle(
    activityStat?.percentageChange
  )

  const reversedActivityCounts = React.useMemo(
    () => (activityStat?.counts ? [...activityStat.counts].reverse() : []),
    [activityStat?.counts]
  )

  return (
    <div
      className={cn(
        'relative z-[1] flex h-[90px] w-full items-center overflow-hidden px-2 py-1',
        { 'border-b border-b-divider': !activityStat?.percentageChange },
        className
      )}
      {...props}
    >
      <div className='min-w-0'>
        <DataSourceTooltip content={tooltipContent}>
          <Typography className='flex items-center text-[2.625rem] leading-8' variant='h2' component='div'>
            {activityStat?.totalCount || '-'}
            <Typography
              className='ml-3 flex items-center'
              component='div'
              variant='aLabelSmall'
              style={{ paddingRight: pr, borderRadius }}
            >
              <ActivityStatsIcon className={iconColorClassName} />
              {percentage}
            </Typography>
          </Typography>
        </DataSourceTooltip>
        <Typography variant='aLabel'>{label}</Typography>
      </div>
      {activityStat?.percentageChange !== 0 && (
        <FilledSparkLine
          className='absolute bottom-0 left-0 z-[-1] size-full fill-secondary-shade-20'
          values={reversedActivityCounts}
        />
      )}
    </div>
  )
}
