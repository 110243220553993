import { cva } from 'class-variance-authority'
import { baseButtonAndInputClasses } from './common'

export const buttonVariants = cva(
  'relative inline-flex cursor-pointer select-none items-center justify-center align-middle text-text-primary transition duration-200 ease-in-out focus-visible:outline-none focus-visible:ring-2',
  {
    variants: {
      variant: {
        contained: undefined,
        outlined: 'border-2',
        ghost: undefined
      },
      size: {
        md: 'h-10 px-5',
        sm: 'h-8 border px-5'
      },
      palette: baseButtonAndInputClasses.borders,
      disabled: { false: undefined, true: baseButtonAndInputClasses.disabled.base }
    },
    compoundVariants: [
      // Contained
      {
        variant: 'contained',
        palette: 'primary',
        className: baseButtonAndInputClasses.contained.primary
      },
      {
        variant: 'contained',
        palette: 'secondary',
        className: baseButtonAndInputClasses.contained.secondary
      },
      {
        variant: 'contained',
        palette: 'tertiary',
        className: baseButtonAndInputClasses.contained.tertiary
      },
      {
        variant: 'contained',
        palette: 'danger',
        className: baseButtonAndInputClasses.contained.danger
      },
      // Outlined
      {
        variant: 'outlined',
        palette: 'primary',
        className: baseButtonAndInputClasses.outlined.primary
      },
      {
        variant: 'outlined',
        palette: 'secondary',
        className: baseButtonAndInputClasses.outlined.secondary
      },
      {
        variant: 'outlined',
        palette: 'tertiary',
        className: baseButtonAndInputClasses.outlined.tertiary
      },
      {
        variant: 'outlined',
        palette: 'danger',
        className: baseButtonAndInputClasses.outlined.danger
      },
      // Ghost
      {
        variant: 'ghost',
        palette: 'primary',
        className: baseButtonAndInputClasses.ghost.primary
      },
      {
        variant: 'ghost',
        palette: 'secondary',
        className: baseButtonAndInputClasses.ghost.secondary
      },
      {
        variant: 'ghost',
        palette: 'tertiary',
        className: baseButtonAndInputClasses.ghost.tertiary
      },
      {
        variant: 'ghost',
        palette: 'danger',
        className: baseButtonAndInputClasses.ghost.danger
      },
      // Disabled
      {
        variant: 'contained',
        disabled: true,
        className: baseButtonAndInputClasses.disabled.contained
      },
      {
        variant: 'outlined',
        disabled: true,
        className: baseButtonAndInputClasses.disabled.outlined
      },
      {
        variant: 'ghost',
        disabled: true,
        className: baseButtonAndInputClasses.disabled.base
      }
    ],
    defaultVariants: {
      variant: 'ghost',
      size: 'md',
      palette: 'tertiary',
      disabled: false
    }
  }
)
