import { type ButtonProps } from '@strise/midgard'

export const extractRequestButtonProps = (isFetched: boolean): ButtonProps => {
  return isFetched
    ? {
        variant: 'outlined',
        palette: 'tertiary'
      }
    : {
        variant: 'contained',
        palette: 'primary'
      }
}
