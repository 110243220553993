import { Skeleton } from '@strise/midgard'
import * as React from 'react'

const ActivityCard = () => {
  return (
    <div className='mb-4 flex w-full rounded-lg border border-tertiary-shade-5 px-2 py-4'>
      <div className='my-auto flex w-2/12 flex-col items-center justify-start'>
        <Skeleton className='size-5' variant='circle' />
      </div>
      <div className='w-10/12 space-y-3'>
        <Skeleton className='h-5 w-1/5' />
        <Skeleton className='h-6 w-4/5' />
        <Skeleton className='h-7 w-28' />
      </div>
    </div>
  )
}

export const ActivitiesLoader = () => (
  <>
    <div className='mb-3 mt-2 flex items-center'>
      <Skeleton className='h-3 w-14' />
    </div>
    <ActivityCard />
    <ActivityCard />
    <ActivityCard />
  </>
)
