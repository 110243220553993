import { type ChipProps, Typography } from '@strise/midgard'
import { Trans } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import * as React from 'react'
import { type FilterSpec } from './FilterHOC'

export const ActiveFilters = <T,>({
  chipProps,
  filters,
  setValue,
  toggleEdit,
  value
}: {
  chipProps?: Omit<ChipProps, 'value'>
  filters: Array<FilterSpec<T>>
  setValue: SetStateFn<T>
  toggleEdit: () => void
  value: T
}) => {
  const activeChipProps = {
    variant: 'contained' as const,
    palette: 'tertiary' as const,
    className: 'my-2 mr-2',
    onClick: toggleEdit
  }

  return (
    <>
      {filters.map((F, index) => {
        const isLast = index === filters.length - 1
        return (
          <div key={index}>
            <div className='flex min-h-[theme(height.sub-header)] flex-wrap items-center'>
              <Typography className='mx-2 text-2xl font-book text-primary-main'>[</Typography>
              <F.Component value={value} setValue={setValue} {...activeChipProps} {...chipProps} />
              <Typography className='mr-2 text-2xl font-book text-primary-main'>]</Typography>
              {!isLast && (
                <Typography className='mx-0 text-sm text-secondary-main'>
                  <Trans>and</Trans>
                </Typography>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
